import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  CardSubtitle,
  CardTitle, Col,
  FormGroup, Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Row
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { userGet } from "../../store/users/userActions"
import { selectColorStyles } from "../../components/selectColorStyle"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  complaintComment,
  complaintResolve,
  complaintsFind,
  complaintTag
} from "../../store/complaints/complaintsReducer"
import moment from "moment"


export const ComplainFormModal = ({ isOpen, toggle, complaintID, update }) => {

  const dispatch = useDispatch()


  const [messageBox, setMessageBox] = useState(null)

  const [comment, setComment] = useState("")
  const [tagged, set_tagged] = useState(false)
  const [resolve, setResolve] = useState(false)
  const [resolution_comment, set_resolution_comment] = useState("")
  // const [pageComplaint, setPageComplaint] = useState(complaint)

  const { currentUser, users, userLoading, loading, complaint } = useSelector((state) => ({
    currentUser: state.Authentication.user,
    users: state.Users.users,
    userLoading: state.Users.loading,
    loading: state.Complaints.loading,
    complaint: state.Complaints.complaint
  }))


  const reset = () => {
    setComment("")
    set_tagged([])
    update && update()
  }

  useEffect(() => {
    complaint && set_tagged(complaint.assigned_to)
  }, [complaint])

  const addMessage = () => {
    dispatch(complaintComment({ complaint: complaint.id, comment, users: tagged }, reset))
  }

  const onResolve = () => {
    dispatch(complaintResolve({ complaint: complaint.id, resolution_comment }, () => {
      toggle()
      setResolve(false)
    }))
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setComment(value)
      addMessage()
    }
  }


  useEffect(() => {
    // isOpen && dispatch(userGet())
    isOpen && dispatch(complaintsFind(complaintID))
    isOpen && !users?.data && dispatch(userGet())
    if (!isOpen) {
      reset()
    }


  }, [isOpen])

  return <React.Fragment>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <CardTitle>

          {complaint && <>  {complaint.question_type} :<CardSubtitle> {complaint.question}</CardSubtitle></>}

        </CardTitle>
      </ModalHeader>
      <ModalBody>

      <span className="float-end">
        Poor : {complaint && complaint.general_rating} <i className="mdi mdi-star text-danger" />
      </span>
        <dl>
          <dt>Remarks</dt>
          <dd><span>{complaint && complaint.overall_remarks}</span></dd>
        </dl>


        <div>
          <div className="chat-conversation p-3">
            <ul className="list-unstyled">
              <PerfectScrollbar
                style={{ height: "270px" }}
                containerRef={ref => setMessageBox(ref)}
              >
                {((complaint && complaint.rich_comments) || []).map((message) => (
                  <li
                    key={"test_k" + message.id}
                    className={
                      message.sender === currentUser.name
                        ? "right"
                        : ""
                    }
                  >
                    <div className="conversation-list">

                      <div className="ctext-wrap">

                        <div className="conversation-name">
                          {message.user?.name ? message.user?.name : "unknown"}
                        </div>
                        <p>{message.comments}</p>
                        <p className="chat-time mb-0"><i
                          className="bx bx-time-five align-middle me-1"></i> {moment(message.created_at).fromNow()}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </PerfectScrollbar>
            </ul>
          </div>
          {complaint && complaint.status !== "closed" && <>
            <div className="p-3 chat-input-section">
              <Row>
                <Col>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={comment}
                      onKeyPress={onKeyPress}
                      onChange={e => setComment(e.target.value)}
                      className="form-control chat-input"
                      placeholder="Enter Message..."
                    />

                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      addMessage()
                    }

                    }
                    className="btn btn-primary btn-rounded chat-send w-md "
                  >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>{" "}
                    {!loading && <i className="mdi mdi-send" />}
                    {loading && <i className="mdi mdi-spin" />}
                  </Button>
                </Col>
              </Row>
            </div>
          </>}
        </div>
        {complaint && complaint.status === "closed" && <Alert color={"success"}>Complaint has been resolved</Alert>}
        {complaint && complaint.status === "closed" && <Alert color={"info"}><strong>Resolution Comments</strong>
          <hr />
          {complaint.resolution_comment}</Alert>}
        {complaint && complaint.status !== "closed" && <FormGroup>
          <Select
            value={tagged}
            className="m-3 mt-0"
            placeholder="Tag Users"
            loading={userLoading}
            onChange={e => {
              set_tagged(e)
              dispatch(complaintTag({ complaint: complaint.id, user: e }))
            }}
            options={users.data}
            styles={selectColorStyles} />
        </FormGroup>}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} className="btn btn-light">Close</Button>
        {/*<Button onClick={() => onComment(complaint.id, comment, tagged)} className="btn btn-soft-primary">Comment</Button>*/}
        {complaint && complaint.status !== "closed" && <Button onClick={() => setResolve(!resolve)}
                                                               className="btn btn-soft-success">
          Mark As Resolve

        </Button>}

      </ModalFooter>


    </Modal>
    <Modal className="modal modal-dialog-centered" isOpen={resolve} toggle={() => setResolve(!resolve)}>
      {!loading && <>
        <ModalHeader>
          Resolution Comment
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={resolution_comment}
            onChange={e => set_resolution_comment(e.target.value)}
            placeholder="Enter Resolution Comments..."
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setResolve(!resolve)} className="btn btn-light">Close</Button>
          {complaint && complaint.status !== "closed" &&
          <Button onClick={() => onResolve()}
                  className="btn btn-soft-success">
            Resolve
          </Button>}
        </ModalFooter></>}
    </Modal>
  </React.Fragment>

}
