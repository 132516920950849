import React from "react"
import {  useNavigate } from "react-router-dom"

const AuthMiddleware = (props) => {

  const navigate = useNavigate()

  if (!localStorage.getItem("authUser")) {
    navigate("/")
  }
  return (<React.Fragment>

    {props.children}

  </React.Fragment>)
}

export default AuthMiddleware
