import React from "react"
import { Button, Card, CardBody, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useSelector } from "react-redux"


export const TemplatePreview = ({ isOpen, toggle }) => {

  const { loading, template } = useSelector(state => ({
    loading: state.Templates.loading,
    template: state.Templates.template
  }))
  return <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">

    <ModalHeader toggle={toggle}>
      {template.name}
      <div><small>Code</small> : <code>{template.code}</code></div>
    </ModalHeader>
    <ModalBody>
      <Card className="shadow-none border-1">
        <CardBody><CardTitle>Email Template</CardTitle>
          <div dangerouslySetInnerHTML={{ __html: template.email }}></div>
        </CardBody>

      </Card>
      {/*<Card className="shadow-none border-1">*/}
      {/*  <CardBody><CardTitle>SMS Template</CardTitle>*/}
      {/*    <div dangerouslySetInnerHTML={{ __html: template.sms }}></div>*/}
      {/*  </CardBody>*/}

      {/*</Card>*/}
      {/*<Card className="shadow-none border-1">*/}
      {/*  <CardBody><CardTitle>Whatsapp Template</CardTitle>*/}
      {/*    <div dangerouslySetInnerHTML={{ __html: template.whatsapp }}></div>*/}
      {/*  </CardBody>*/}
      {/*</Card>*/}


    </ModalBody>
    <ModalFooter>
      <Button onClick={toggle} className="btn btn-light">Close</Button>
    </ModalFooter>


  </Modal>

}