import React, { useEffect } from "react"
import { Button, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { questionFind } from "../../store/questions/questionActions"
import { QuestionPreview } from "./questionPreview"


export const QuestionModal = ({ questionID, isOpen, toggle }) => {
  const dispatch = useDispatch()
  const { Question } = useSelector(state => state)
  const { question, loading } = Question

  useEffect(() => {
    isOpen === true && dispatch(questionFind(questionID))

  }, [isOpen])



  return <React.Fragment>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <CardTitle>Question Preview</CardTitle>
      </ModalHeader>
      {question && <ModalBody>
      <QuestionPreview question={question} />

      </ModalBody>
      }
      <ModalFooter>
        {/*<Button outline color="danger"><i className="mdi mdi-trash-can-outline"></i></Button>*/}
        {/*<Button outline color="primary"><i className="mdi mdi-book-edit-outline"></i></Button>*/}
        <Button color="dark" onClick={toggle}>Close</Button>
      </ModalFooter>

    </Modal>

  </React.Fragment>


}
