import {SMS_SETTINGS_GET_REQUEST,
  SMS_SETTINGS_GET_SUCCESS,
  SMS_SETTINGS_GET_FAILURE,
  SMS_SETTINGS_FIND_REQUEST,
  SMS_SETTINGS_FIND_SUCCESS,
  SMS_SETTINGS_FIND_FAILURE,
  SMS_SETTINGS_ACTION_REQUEST,
  SMS_SETTINGS_ACTION_SUCCESS,
  SMS_SETTINGS_ACTION_FAILURE,} from './smsSettingTypes'



const init = {
  loading: false,
  sms_settings : [],
  sms_setting: false,
  error : ""
}

const SMSSettings = (state = init, action) => {
  switch(action.type) {
    case SMS_SETTINGS_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case SMS_SETTINGS_GET_SUCCESS : {return {...state, loading: false, sms_settings: action.payload, error: ""}}
    case SMS_SETTINGS_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case SMS_SETTINGS_FIND_REQUEST : {return {...state, loading: true, error: "", sms_setting: false}}
    case SMS_SETTINGS_FIND_SUCCESS : {return {...state, loading: false, sms_setting: action.payload, error: ""}}
    case SMS_SETTINGS_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case SMS_SETTINGS_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case SMS_SETTINGS_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case SMS_SETTINGS_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default SMSSettings;
