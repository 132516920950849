import React from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"


export default class BranchForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen) this.init()
  }

  init = () => {
    const { branch, company } = this.props
    return axios
      .post("app/branches/form", { id:branch, company } )
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, branch, saveChanges } = this.props
    const { loading, fields, form } = this.state
    return <Modal isOpen={isOpen} toggle={toggle}>
      {!loading && form && <>
        <ModalHeader>

          {branch.id && <>Edit : {branch.branch_name}</>}
          {!branch.id && <>Create Branch</>}
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Label htmlFor="form-department-name">Company</Label>
            <select
              value={fields.companies_id}
              onChange={e => this.handleChange("companies_id", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-name"
              placeholder=""
            >
              <option>SELECT COMPANY</option>

              {form.companies && typeof form.companies === "object" && form.companies.map(row => <option
                key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="form-department-country">Country</Label>
            <select
              value={fields.country}
              onChange={e => this.handleChange("country", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-country"
              placeholder=""
            >
              <option>Select Country</option>

              {form.countries && typeof form.countries === "object" && form.countries.map(row => <option
                key={`country-${row.id}`} value={row.id}>{row.country_name}</option>)}

            </select>
          </div>

          <div className="mb-3">
            <Label htmlFor="form-branch-name">Branch Name</Label>
            <Input
              value={fields.branch_name}
              onChange={e => this.handleChange("branch_name", e.target.value)}
              type="text"
              name={"name"}
              className="form-control"
              id="form-branch-name"
              placeholder=""
            />
          </div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-branch-name">Branch Email</Label>
                <Input
                  value={fields.branch_email}
                  onChange={e => this.handleChange("branch_email", e.target.value)}
                  name="email"
                  type="text"
                  className="form-control"
                  id="form-branch-name"
                  placeholder=""
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-branch-name">Branch Phone</Label>
                <Input
                  value={fields.branch_phone}
                  onChange={e => this.handleChange("branch_phone", e.target.value)}
                  name="phone"
                  type="text"
                  className="form-control"
                  id="form-branch-name"
                  placeholder=""
                />
              </div>
            </Col>
          </Row>


          <div className="mb-3">
            <Label htmlFor="form-branch-name">Branch Location</Label>
            <Input
              value={fields.location}
              onChange={e => this.handleChange("location", e.target.value)}
              name="location"
              type="text"
              className="form-control"
              id="form-branch-name"
              placeholder=""
            />
          </div>
          {/*<div className="mb-3">*/}
          {/*  <Label htmlFor="form-branch-name">Negative Review Level</Label>*/}
          {/*  <Input*/}
          {/*    value={fields.negative_review_level}*/}
          {/*    onChange={e => this.handleChange("negative_review_le", e.target.value)}*/}

          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    id="form-branch-name"*/}
          {/*    placeholder=""*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<Pre data={branch} />*/}
        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </>}
    </Modal>
  }


}
