import {ESCALATION_LEVEL_GET_REQUEST,
  ESCALATION_LEVEL_GET_SUCCESS,
  ESCALATION_LEVEL_GET_FAILURE,
  ESCALATION_LEVEL_FIND_REQUEST,
  ESCALATION_LEVEL_FIND_SUCCESS,
  ESCALATION_LEVEL_FIND_FAILURE,
  ESCALATION_LEVEL_ACTION_REQUEST,
  ESCALATION_LEVEL_ACTION_SUCCESS,
  ESCALATION_LEVEL_ACTION_FAILURE,} from './escalationLevelTypes'


import axios from "axios"


export const escalationLevelGet = () => dispatch => {
  dispatch({ type: ESCALATION_LEVEL_GET_REQUEST })
  axios.get("/app/escalation/level/get")
    .then(({ data }) => dispatch({ type: ESCALATION_LEVEL_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ESCALATION_LEVEL_GET_FAILURE, payload: message }))
}

export const escalationLevelFind = (id) => dispatch => {
  dispatch({ type: ESCALATION_LEVEL_FIND_REQUEST })
  axios.get(`/app/escalation/level/get/${id}`)
    .then(({ data }) => dispatch({ type: ESCALATION_LEVEL_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ESCALATION_LEVEL_FIND_FAILURE, payload: message }))
}

export const escalationLevelDelete = (id, callBack) => dispatch => {
  dispatch({ type: ESCALATION_LEVEL_ACTION_REQUEST })
  axios.post(`/app/escalation/level/delete`, {id})
    .then(({ data }) => {
      dispatch({ type: ESCALATION_LEVEL_ACTION_SUCCESS, payload: data })
      dispatch(escalationLevelGet())

    })
    .catch(({ message }) => dispatch({ type: ESCALATION_LEVEL_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const escalationLevelPost = (data, callBack) => dispatch => {
  dispatch({ type: ESCALATION_LEVEL_ACTION_REQUEST })
  axios.post(`/app/escalation/level/submit`, data)
    .then(({ data }) => {
      dispatch({ type: ESCALATION_LEVEL_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(escalationLevelGet())
        dispatch(escalationLevelFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: ESCALATION_LEVEL_ACTION_FAILURE, payload: message }))
}
