import axios from "axios"

const CONTACT_GET_REQUEST = 'CONTACT_GET_REQUEST';
const CONTACT_GET_SUCCESS = 'CONTACT_GET_SUCCESS';
const CONTACT_GET_FAILURE = 'CONTACT_GET_FAILURE';

const CONTACT_FIND_REQUEST = 'CONTACT_FIND_REQUEST';
const CONTACT_FIND_SUCCESS = 'CONTACT_FIND_SUCCESS';
const CONTACT_FIND_FAILURE = 'CONTACT_FIND_FAILURE';

const CONTACT_ACTION_REQUEST = 'CONTACT_ACTION_REQUEST';
const CONTACT_ACTION_SUCCESS = 'CONTACT_ACTION_SUCCESS';
const CONTACT_ACTION_FAILURE = 'CONTACT_ACTION_FAILURE';


const init = {
  contacts: [],
  contact: false,
  loading: false,
  errors: false
}


const Contact = (state = init, action) => {
  switch (action.type){
    case CONTACT_GET_REQUEST : { return  {...state, loading: true, errors: false}}
    case CONTACT_GET_SUCCESS : { return  {...state, loading: false, messages: action.payload}}
    case CONTACT_GET_FAILURE : { return  {...state, loading: false, errors: action.payload}}

    case CONTACT_FIND_REQUEST : { return  {...state, loading: true, errors: false}}
    case CONTACT_FIND_SUCCESS : { return  {...state, loading: false, message: action.payload}}
    case CONTACT_FIND_FAILURE : { return  {...state, loading: false, errors: action.payload}}

    case CONTACT_ACTION_REQUEST : { return  {...state, loading: true, errors: false}}
    case CONTACT_ACTION_SUCCESS : { return  {...state, loading: false}}
    case CONTACT_ACTION_FAILURE : { return  {...state, loading: false, errors: action.payload}}

    default : { return {...state}}
  }

}

export default Contact;


export const getContact = () => dispatch => {
  dispatch({type: CONTACT_GET_REQUEST})
  axios.get('app/contacts/get')
    .then(({data}) => dispatch({type: CONTACT_GET_SUCCESS, payload: data}))
    .catch(e => dispatch({type: CONTACT_GET_FAILURE, payload: e.message}))
}


export const findContact = (id) => dispatch => {
  dispatch({type: CONTACT_FIND_REQUEST})
  axios.get(  `app/contacts/get/${id}`)
    .then(({data}) => dispatch({type: CONTACT_FIND_SUCCESS, payload: data}))
    .catch(e => dispatch({type: CONTACT_FIND_FAILURE, payload: e.message}))
}

export const resendContact = (id) => dispatch => {
  dispatch({type: CONTACT_ACTION_REQUEST})
  axios.post(  `app/contacts/resend/`, {id})
    .then(({data}) => dispatch({type: CONTACT_ACTION_SUCCESS, payload: data}))
    .catch(e => dispatch({type: CONTACT_ACTION_FAILURE, payload: e.message}))
}

export const deleteContact = (id) => dispatch => {
  dispatch({type: CONTACT_ACTION_REQUEST})
  axios.post(  `app/contacts/delete/`, {id})
    .then(({data}) => dispatch({type: CONTACT_ACTION_SUCCESS, payload: data}))
    .catch(e => dispatch({type: CONTACT_ACTION_FAILURE, payload: e.message}))
}

export const broadcastContact = (data, callBack) => dispatch => {
  dispatch({type: CONTACT_ACTION_REQUEST})
  axios.post(  `app/contacts/broadcast`, data)
    .then(({data}) => dispatch({type: CONTACT_ACTION_SUCCESS, payload: data}))
    .catch(e => dispatch({type: CONTACT_ACTION_FAILURE, payload: e.message}))
    .finally(() => callBack && callBack())
}

