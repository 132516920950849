import React, { useState } from "react"
import { Button, ButtonGroup, Card, CardBody, CardTitle, Col } from "reactstrap"
import ReviewsReport from "./reviewsReport"
import WeeklyComplaintsChart from "./weeklyComplaintsChart"


export const ReviewsAndComplaintsWeeklyChart = () => {

  const [active, setActive ] = useState('complaints');


  return <React.Fragment>

    <Card>
      <CardBody>
        <ButtonGroup className="float-end">
          <Button className={`btn btn-soft-primary`} onClick={() => setActive("reviews")}>
            {active === 'reviews' && <i className="mdi mdi-check" />}

            {" "}Reviews</Button>
          <Button className={`btn btn-soft-primary`} onClick={() => setActive("complaints")}>
            {active === 'complaints' && <i className="mdi mdi-check" />}

              {" "}Complaints</Button>
        </ButtonGroup>
        <CardTitle>{active === 'reviews' ? "Weekly Reviews" : "Weekly Complaints"}</CardTitle>
        {active === 'reviews' && <ReviewsReport dataColors='["--bs-success"]' />}
        {active === 'complaints' && <WeeklyComplaintsChart  />}

      </CardBody>
    </Card>

  </React.Fragment>

}
