import React from "react"
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { selectColorStyles } from "../../components/selectColorStyle"
import makeAnimated from "react-select/animated"

const animatedComponents = makeAnimated()

export default class RoleUsersForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      form: false,
      fields: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) this.init()
  }

  init = () => {
    if(!this.props.isOpen) return false;
    this.setState({loading: true})
    axios.post('app/roles/assign/user/form', {id: this.props.role})
      .then(({data}) => this.setState({form: data, fields: data.fields}))
      .finally(() =>     this.setState({loading: false}))
      // .catch(e)
    const fields = { id: 0, name: "" }
    this.setState({ form: fields, fields })
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, saveChanges } = this.props
    const { loading, fields, form } = this.state

    return <Modal isOpen={isOpen} toggle={toggle}>
      {form && <Form method="post" onSubmit={e => {
        e.preventDefault()
        saveChanges(fields)
      }}>
        <ModalHeader>
          Assign Users to Role <span className="text-primary">{form.role?.name}{loading && loading}</span>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Label htmlFor="form-role-name"> Select Users</Label>
            <Select
              isMulti
              components={animatedComponents}
              closeMenuOnSelect={false}
              isLoading={loading}
              value={fields.users}
              onChange={e => this.handleChange('users',e)}
              placeholder="Select Users"
              options={form.users}
              styles={selectColorStyles}
            />
          </div>


        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </Form>}
    </Modal>
  }


}
