import axios from "axios"
import toastr from "toastr"

import { handleError } from "../../helpers/handleError"

const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST'
const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS'
const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE'

const SETTINGS_GET_REQUEST = 'SETTINGS_GET_REQUEST'
const SETTINGS_GET_SUCCESS = 'SETTINGS_GET_SUCCESS'
const SETTINGS_GET_FAILURE = 'SETTINGS_GET_FAILURE'


const NOTIFICATIONS_GET_REQUEST = 'NOTIFICATIONS_GET_REQUEST'
const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS'
const NOTIFICATIONS_GET_FAILURE = 'NOTIFICATIONS_GET_FAILURE'

const PROFILE_ACTION_REQUEST = 'PROFILE_ACTION_REQUEST'
const PROFILE_ACTION_SUCCESS = 'PROFILE_ACTION_SUCCESS'
const PROFILE_ACTION_FAILURE = 'PROFILE_ACTION_FAILURE'



const init = {
  loading: false,
  info: false,
  notifications : false,
  settings: false,
  errors: false
}

const ProfileReducer = (state = init, action ) => {

  switch (action.type){
    case PROFILE_GET_REQUEST : {return {...state, loading: true, errors: false}}
    case PROFILE_GET_SUCCESS : {return {...state, loading: false, info: action.payload}}
    case PROFILE_GET_FAILURE : {return {...state, loading: false, errors: action.payload}}

    case SETTINGS_GET_REQUEST : {return {...state, loading: true, errors: false}}
    case SETTINGS_GET_SUCCESS : {return {...state, loading: false, settings: action.payload}}
    case SETTINGS_GET_FAILURE : {return {...state, loading: false, info: action.payload}}

    case NOTIFICATIONS_GET_REQUEST : {return {...state, loading: true, errors: false}}
    case NOTIFICATIONS_GET_SUCCESS : {return {...state, loading: false, notifications: action.payload}}
    case NOTIFICATIONS_GET_FAILURE : {return {...state, loading: false, errors: action.payload}}

    case PROFILE_ACTION_REQUEST : {return {...state, loading: true, errors: false}}
    case PROFILE_ACTION_SUCCESS : {return {...state, loading: false, notifications: action.payload}}
    case PROFILE_ACTION_FAILURE : {return {...state, loading: false, errors: action.payload}}

    default : {return {...state}}

  }

}

export default ProfileReducer

export const profileGet = () => dispatch => {
  dispatch({ type: PROFILE_GET_REQUEST })
  axios.get('app/profile/info')
    .then(({data}) => dispatch({type: PROFILE_GET_SUCCESS, payload: data}))
    .catch(e => handleError(e , dispatch({ type: PROFILE_GET_FAILURE })))
}

export const settingsGet = () => dispatch => {
  dispatch({ type: SETTINGS_GET_REQUEST })
  axios.get('app/profile/settings')
    .then(({data}) => dispatch({type: SETTINGS_GET_SUCCESS, payload: data}))
    .catch(e => handleError(e , dispatch({ type: SETTINGS_GET_FAILURE })))
}

export const notificationsGet = () => dispatch => {
  dispatch({ type: NOTIFICATIONS_GET_REQUEST })
  axios.get('app/profile/notifications')
    .then(({data}) => dispatch({type: NOTIFICATIONS_GET_SUCCESS, payload: data}))
    .catch(e => handleError(e , dispatch({ type: NOTIFICATIONS_GET_FAILURE })))
}

export const profileSubmit = (data, action) => dispatch => {
  dispatch({ type: PROFILE_ACTION_REQUEST })
  axios.post(`app/profile/submit`, {data, action})
    .then(({data}) => {
      dispatch({ type: PROFILE_ACTION_SUCCESS, payload: data })
      toastr.success('Changes Saved')
      if(action === 'info') dispatch(profileGet());
    })
    .catch(e => handleError(e , dispatch({ type: PROFILE_ACTION_FAILURE })))
}






