export const ROLE_GET_REQUEST = 'ROLE_GET_REQUEST';
export const ROLE_GET_SUCCESS = 'ROLE_GET_SUCCESS';
export const ROLE_GET_FAILURE = 'ROLE_GET_FAILURE';

export const ROLE_FIND_REQUEST = 'ROLE_FIND_REQUEST';
export const ROLE_FIND_SUCCESS = 'ROLE_FIND_SUCCESS';
export const ROLE_FIND_FAILURE = 'ROLE_FIND_FAILURE';

export const ROLE_POST_REQUEST = 'ROLE_POST_REQUEST';
export const ROLE_POST_SUCCESS = 'ROLE_POST_SUCCESS';
export const ROLE_POST_FAILURE = 'ROLE_POST_FAILURE';

export const ROLE_DELETE_REQUEST = 'ROLE_DELETE_REQUEST';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_FAILURE = 'ROLE_DELETE_FAILURE';

export const ROLE_ACTION_REQUEST = 'ROLE_ACTION_REQUEST';
export const ROLE_ACTION_SUCCESS = 'ROLE_ACTION_SUCCESS';
export const ROLE_ACTION_FAILURE = 'ROLE_ACTION_FAILURE';

export const ROLE_ASSIGN_USER_REQUEST = 'ROLE_ASSIGN_USER_REQUEST';
export const ROLE_ASSIGN_USER_SUCCESS = 'ROLE_ASSIGN_USER_SUCCESS';
export const ROLE_ASSIGN_USER_FAILURE = 'ROLE_ASSIGN_USER_FAILURE';

export const ROLE_ASSIGN_PERMISSION_REQUEST = 'ROLE_ASSIGN_PERMISSION_REQUEST';
export const ROLE_ASSIGN_PERMISSION_SUCCESS = 'ROLE_ASSIGN_PERMISSION_SUCCESS';
export const ROLE_ASSIGN_PERMISSION_FAILURE = 'ROLE_ASSIGN_PERMISSION_FAILURE';



