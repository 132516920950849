import React, { useEffect, useState } from "react"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Button, ButtonGroup, Card, CardBody, CardTitle, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { templateDelete, templateFind, templateGet, templateSubmit } from "../../store/template/templateReducer"
import { Pre } from "../../helpers/pre"
import { Link } from "react-router-dom"
import Datatable from "react-data-table-component"
import moment from "moment"
import TemplateForm from "./templateForm"
import { TemplatePreview } from "./templatePreview"


const Templates = () => {

  const dispatch = useDispatch()
  const { loading, templates, template } = useSelector(state => ({
    loading: state.Templates.loading,
    templates: state.Templates.templates,
    template: state.Templates.template
  }))

  const [show, setShow] = useState(false)
  const [form, setForm] = useState(false)
  const [templateID, setTemplateID] = useState(0);

  const get = () => dispatch(templateGet())

  const toggle = () => setShow(!show)

  useEffect(() => {
    get()
  }, [])

  const onDelete = (item) =>  dispatch(templateDelete(item.id))

  const onEdit = (item) =>  {
    setTemplateID(item.id)
    setForm(true)
  }

  const onCreate = () => {
    setTemplateID(0)
    setForm(true)
  }

  const columns = [
    {
      name: "Name", sortable: true, selector: ({ id, name }) => <Link onClick={() => {
        toggle()
        dispatch(templateFind(id))
      }}>{name}</Link>
    },
    {name: "Code", selector: ({code}) => <code>{code}</code>},
    {name: "Created", right:true, selector: ({created_at}) => moment(created_at).format("Do MMM YYYY HH:mm:ss")},
    {name: "", right:true, selector: (row) => <ButtonGroup>
        <Button className="btn-soft-danger" onClick={() => onDelete(row)}><i className="mdi mdi-trash-can-outline"></i></Button>
        <Button className="btn-soft-primary" onClick={() => onEdit(row)}><i className="mdi mdi-pencil-outline"></i> Edit</Button>
      </ButtonGroup>},

  ]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Templates"}
          breadcrumbItem="Templates"
        />
        <Card>
          <CardBody>
            <ButtonGroup className="float-end">
              <Button className="btn btn-soft-primary" onClick={() => get()}>Reload</Button>
              <Button className="btn btn-soft-primary" onClick={() => onCreate()}>Create</Button>
            </ButtonGroup>
            <CardTitle>Messages Templates</CardTitle>
          </CardBody>
          <Datatable columns={columns} data={(templates || [])} />
        </Card>
        {form && <TemplateForm isOpen={form} toggle={() => setForm(!form)} id={templateID} saveChanges={(data) => {
          dispatch(templateSubmit(data))
          setForm(!form)
        }} />}
        {show && <TemplatePreview isOpen={show} toggle={toggle} />}



      </Container>
    </div>


  </React.Fragment>
}


export default Templates