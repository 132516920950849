export const QUESTION_GET_REQUEST = "QUESTION_GET_REQUEST";
export const QUESTION_GET_SUCCESS = "QUESTION_GET_SUCCESS";
export const QUESTION_GET_FAILURE = "QUESTION_GET_FAILURE";


export const QUESTION_FIND_REQUEST = "QUESTION_FIND_REQUEST";
export const QUESTION_FIND_SUCCESS = "QUESTION_FIND_SUCCESS";
export const QUESTION_FIND_FAILURE = "QUESTION_FIND_FAILURE";


export const QUESTION_POST_REQUEST = "QUESTION_POST_REQUEST";
export const QUESTION_POST_SUCCESS = "QUESTION_POST_SUCCESS";
export const QUESTION_POST_FAILURE = "QUESTION_GET_FAILURE";


export const QUESTION_DELETE_REQUEST = "QUESTION_DELETE_REQUEST";
export const QUESTION_DELETE_SUCCESS = "QUESTION_DELETE_SUCCESS";
export const QUESTION_DELETE_FAILURE = "QUESTION_DELETE_FAILURE";



export const QUESTION_ACTION_REQUEST = "QUESTION_ACTION_REQUEST";
export const QUESTION_ACTION_SUCCESS = "QUESTION_ACTION_SUCCESS";
export const QUESTION_ACTION_FAILURE = "QUESTION_ACTION_FAILURE";


