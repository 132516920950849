import React, { useEffect } from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import Doughnut from "../../pages/AllCharts/echart/doughnutchart"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
import ReactEcharts from "echarts-for-react"
import { useDispatch, useSelector } from "react-redux"
import { getSentiment } from "../../store/reports/reportReducer"
import { Pre } from "../../helpers/pre"


const OverallSentiment = () => {

  const dispatch = useDispatch()

  const dataColors = "[\"--bs-success\",\"--bs-warning\", \"--bs-danger\",\"--bs-info\", \"--bs-success\"]"
  const doughnutEChartColors = getChartColorsArray(dataColors)

  const get = () => dispatch(getSentiment())

  useEffect(() => {
    get()
  }, [])

  const { sentiment, loading } = useSelector(state => ({
    sentiment: state.Reports.sentiment,
    loading: state.Reports.loading
  }))


  const options = {
    toolbox: {
      show: false
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c} "
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Poor", "Good", "Neutral"],
      textStyle: {
        color: ["#8791af"]
      }
    },
    color: doughnutEChartColors,
    series: [
      {
        name: "",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center"
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20"
              // fontWeight: "bold"
            }
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: sentiment?.data
      }
    ]
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>Overall Sentiment</CardTitle>
        <div id="doughnut-chart" className="e-chart"><ReactEcharts style={{ height: "350px" }} option={options} /></div>
      </CardBody>
    </Card>
  )
}
export default OverallSentiment

