import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ratingsGet } from "../../store/ratings/ratingReducer"
import moment from "moment"
import { DataTableList } from "../../components/tables/dataTable"
import { Card, CardBody, CardTitle } from "reactstrap"


export const LatestReviews = props => {
  const dispatch = useDispatch()
  const { ratings, loading } = useSelector(state => ({
    ratings: state.CustomerRatings.ratings,
    loading: state.CustomerRatings.loading
  }))

  const get = () => dispatch(ratingsGet(1))


  useEffect(get, [])


  const columns = [
    { name: "#", maxWidth: "10px", selector: (row, index) => row.id },
    {
      name: "Customer", selector: row => row.customer_name, cell: row => <div>
        <div><strong>{row.customer_name}</strong></div>
        <div>
          <span><i className="mdi mdi-email-outline" /> {row.customer_email}</span>
          <span><i className="mdi mdi-phone-outline" /> {row.customer_mobileno}</span>
        </div>
      </div>
    },
    {
      name: "Average Ratings", right: true, cell: row => {

        if (!row.avg_rating) return <span className="me-1  p-1 ">N/A</span>
        return row.avg_rating < 3 ? <span color="danger" className="me-1 p-1 ">POOR | {row.avg_rating} <i
          className="mdi mdi-star font-size-20 text-danger" /></span> : (row.avg_rating === 3 ?
            <span color="warning" className="me-1 p-1 ">NEUTRAL | {row.avg_rating} <i
              className="mdi mdi-star font-size-20 text-warning" /></span>
            : <span color="success" className="me-1 p-1 ">GOOD | {row.avg_rating} <i
              className="mdi mdi-star font-size-20 text-success" /></span>
        )

        return row.avg_rating ? row.avg_rating : "N/A"


      }
    },
    { name: "Time", right: true, cell: row => moment(row.created_at).format("Do MMM YYYY HH:mm") }
  ]

  return <Card className="bg-transparent">
    <CardBody><CardTitle>Latest Reviews</CardTitle></CardBody>
    <DataTableList

      // hideHeader
      loading={loading}
      hidePagination
      setCurrentPage={() => {
      }}
      get={get}
      data={ratings.filter((row, index) => index < 5)}
      // onRowClicked
      columns={columns}
      className={`project-list-table table-nowrap align-middle table-borderless table-hover `}

    />
  </Card>


}





