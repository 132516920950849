import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { allRatings } from "../../store/ratings/ratingReducer"
import { Badge, Card, CardBody, CardTitle, Container } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Datatable from "react-data-table-component"
import { ReviewFilters } from "../reports/filter"
import { DropDownButtonDownload } from "../reports/dropdownButton"
import { DownloadDocumentReport } from "../../download"

export const NPSReviews = props => {

  const { CustomerRatings } = useSelector(state => state)
  const { all_ratings } = CustomerRatings
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({})

  useEffect(() => {
    dispatch(allRatings("NPS", filters))
  }, [filters])

  const columns = [
    { name: "#", sortable: true, maxWidth: "10px", style: { maxWidth: "20px" }, selector: (row, index) => (index + 1) },
    {
      name: "Customer", width: "280px", selector: row => row.customer_name, cell: row => <div>
        <div><strong>{row.customer_name}</strong></div>
        <div>
          {row.customer_email && <div><span><i className="mdi mdi-email-outline" /> {row.customer_email}</span></div>}
          {row.customer_mobileno &&
            <div><span><i className="mdi mdi-phone-outline" /> {row.customer_mobileno}</span></div>}
        </div>
      </div>
    },
    {
      name: "Company / Branch", sortable: true, selector: row => <div>
        {row.company_name} {row.branch_name && <>{row.branch_name}</>}
      </div>
    },
    { name: "Question", sortable: true, wrap: true, selector: row => row.question },
    {
      name: "Rating",
      sortable: true,
      wrap: true,
      selector: row => <><Badge
        className={`font-size-12 ${row.rating < 7 ? "bg-danger" : (Math.round(row.rating) > 8 ? "bg-success" : "bg-warning")}`}>
        {Math.round(row.rating)}

      </Badge> <span
        className={`font-size-12 ${row.rating < 7 ? "text-danger" : (Math.round(row.rating) > 8 ? "text-success" : "text-warning")}`}>{row.rating < 7 ? "POOR" : (row.rating > 8 ? "GOOD" : "NEUTRAL")}</span> </>
    },
    { name: "Response", sortable: true, wrap: true, selector: row => row.comments },
    { name: "Date", sortable: true, wrap: true, selector: ({ date_time }) => date_time }
  ]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="NPS"
        />
        <Card>
          <CardBody>

            <DownloadDocumentReport className="float-end" url={`/app/reviews/download/nps`} filters={filters}
                                    title={"NPS Reviews"} />
            <CardTitle>NPS</CardTitle>
          </CardBody>
          <CardBody>
            <ReviewFilters onChange={setFilters} sentiment branch department date size={3} search />
          </CardBody>
          <Datatable striped columns={columns} data={all_ratings} pagination />
        </Card>
      </Container>

    </div>

  </React.Fragment>


}
