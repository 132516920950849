import React, { useEffect } from "react"
import { Container } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { ReviewFilters } from "./filter"
import { getAspectReport } from "../../store/reports/reportReducer"
import { useDispatch } from "react-redux"


const AspectReport = (props) => {
  const dispatch = useDispatch()

  const [filters, setFilters] = React.useState({})

  const get = () => dispatch(getAspectReport())

  useEffect(() => {
    get()
  }, [filters])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="Aspect Report"
        />

        <ReviewFilters size={3} branch department date search onChange={setFilters} />

      </Container>
    </div>
  </React.Fragment>
}


export default AspectReport
