import React, { useState } from "react"
import { Spinner } from "reactstrap"


export const Avatar = ({ user, className, loading }) => {

  if (!user || loading) return  <div className={`avatar-xs d-inline-block ${className}`}><span
    className={
      "avatar-title rounded-circle bg-gradient  text-white  "
    }
  >
          <Spinner size="sm" />
  </span></div>

  const [show, setShow] = useState(true)

  const getLetters = () => {
    const array = user.name.split(" ")
    let string = ""
    array.map((name, index) => {
      if (name.replace(" ").length == 0) return ""
      string += (index < 2) ? name.charAt(0).toUpperCase() : ""
    })
    return string
  }
  const status = {
    "inactive": { color: "warning" },
    "blocked": { color: "danger" },
    "active": { color: "success" }
  }

  const bgColor = (typeof status[user.status] !== "undefined") ? status[user.status].color : "dark"


  return show ? <div className={`avatar-xs d-inline-block ${className}`}>
          <span
            className={
              "avatar-title rounded-circle bg-gradient  bg-" + bgColor +
              " text-white" +
              // typeof status[user.status] !== 'undefined' && status[user.status] +
              " font-size-14 "
            }
          >
            {getLetters()}
            {/*{user.name.charAt(0)}*/}
          </span>
  </div> : <>
    <img
      className="rounded-circle header-profile-user"
      src={user?.avatar}
      alt={user?.avatar}
      // onError={() => setLetters(true)}
    /></>
}

