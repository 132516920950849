import React from "react"
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"


export default class EmailSettingsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    if (!this.props.isOpen) return false

    const { email_settings } = this.props
    axios
      .post("app/settings/email/form/", { id: email_settings })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, email_settings, saveChanges } = this.props
    const { loading, fields, form } = this.state


    return <Modal isOpen={isOpen} toggle={toggle}>
      <Form method="post" onSubmit={e => {
        e.preventDefault()
        saveChanges(fields)
      }}>
        <ModalHeader>
          {loading && "Loading...."}

          {email_settings.id && <>Edit : {email_settings.email_settings}</>}
          {!email_settings.id && <>Create Escalation Level</>}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-department-name">Company</Label>
                <select
                  value={fields.company}
                  onChange={e => this.handleChange("company", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-department-name"
                  placeholder=""
                >
                  <option value={0}>Select Company</option>

                  {form.companies && typeof form.companies === "object" && form.companies.map(row => <option
                    key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

                </select>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-email_settings-name">Sender Name</Label>
                <Input
                  value={fields.sender_name}
                  onChange={e => this.handleChange("sender_name", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-sender_name-name"
                  placeholder="e.g. Company Name"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-email_settings-name">Mail Server</Label>
                <Input
                  value={fields.mail_server}
                  onChange={e => this.handleChange("mail_server", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-mail_server-name"
                  placeholder=""
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-email_settings-name">Outport</Label>
                <Input
                  value={fields.outport}
                  onChange={e => this.handleChange("outport", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-outport-name"
                  placeholder="e.g. 925"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-email_settings-name">Username</Label>
                <Input
                  value={fields.username}
                  onChange={e => this.handleChange("username", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-username-name"
                  placeholder="email address"
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-email_settings-name">Password</Label>
                <Input

                  value={fields.password}
                  onChange={e => this.handleChange("password", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-password-name"
                  placeholder="****************"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-email_settings-name">Driver</Label>
                <Input
                  value={fields.driver}
                  onChange={e => this.handleChange("driver", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-driver-name"
                  placeholder="e.g. SMTP"
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-email_settings-name">Encryption</Label>
                <Input
                  value={fields.encryption}
                  onChange={e => this.handleChange("encryption", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-encryption-name"
                  placeholder="e.g. SSL"
                />
              </div>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" type="submit">
            Save Changes</Button>
        </ModalFooter>
      </Form>
    </Modal>
  }


}
