import React from "react"
import axios from "axios"
import { Button, Spinner, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import toastr from "toastr"

export const downloadDocument = ({ id, title, url, onStart, onFinish, onError, params }) => {
  // toastr.clear()
  toastr.success(`${title}`, "Downloading")
  onStart && onStart()

  axios({
    url: url ? url : "app/files/download",
    method: "GET",
    params: params ? params : { id },
    // headers: headers,
    responseType: "blob" // important
  }).then(response => {
    toastr.clear()
    toastr.success(`${title}`, "Downloaded")

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute(
      "download",
      `${title}`
    )
    document.body.appendChild(link)
    link.click()
    link.remove()
    onFinish && onFinish()

  })
    .catch(() => toastr.error(title, "Download Failed"))
    .finally(() => onFinish && onFinish())
}

export const DownloadDocumentReport = ({ filters, url, title, className }) => {
  return <Button
    onClick={() => {
      downloadDocument({ url, params: filters, title: `${title}.xlsx` })
    }} className={`btn btn-soft-primary ${className}`}>Download Report</Button>

}


export default class DisplayDownloadDocument extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  fetchFile = (id) => {
    return downloadDocument({
      id,
      url: "app/files/download",
      onStart: () => this.setState({ loading: true }),
      title: this.props.title,
      onFinish: () => this.setState({ loading: false })
    })
    // if (this.state.loading) return toastr.warn("Already Downloading the document, please wait")
    this.setState({ loading: true })

  }

  render() {
    const { id, title, filesize, size, ext } = this.props
    const { loading } = this.state
    if (!id) return false
    const elementId = `file-tooltip-${id}`
    const max = 13

    if (this.props.downloading && loading) return this.props.downloading()

    if (this.props.children) return <Link onClick={() => this.fetchFile(id)}>
      {this.props.children}
    </Link>


    return <><a
      key={id}
      id={elementId}
      className="cursor-pointer"
      onClick={() => this.fetchFile(id)}
    >
      {title && title.length > max ? (title.substr(0, max)
        + "..." + title.substr(title.length - 5, title.length)) : title} {formatSize(filesize, 0)}
      {loading ? <Spinner size={"sm"} /> : <i className="mdi mdi-cloud-download-outline" />}
    </a>
      <UncontrolledTooltip
        placement="top"
        target={elementId}
      >
        {title}
      </UncontrolledTooltip>
    </>
  }
}
