export const selectColorStyles = {
  menuList: styles => ({
    ...styles,
    background: 'white'
  }),
  option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    // background: isFocused
    //   ? 'hsla(291, 64%, 42%, 0.5)'
    //   : isSelected
    //     ? 'hsla(291, 64%, 42%, 1)'
    //     : undefined,
    // zIndex: 1
  }),
  menu: base => ({
    ...base,
    // zIndex: 100
  })
}
