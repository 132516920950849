import {EMAIL_SETTINGS_GET_REQUEST,
  EMAIL_SETTINGS_GET_SUCCESS,
  EMAIL_SETTINGS_GET_FAILURE,
  EMAIL_SETTINGS_FIND_REQUEST,
  EMAIL_SETTINGS_FIND_SUCCESS,
  EMAIL_SETTINGS_FIND_FAILURE,
  EMAIL_SETTINGS_ACTION_REQUEST,
  EMAIL_SETTINGS_ACTION_SUCCESS,
  EMAIL_SETTINGS_ACTION_FAILURE,} from './emailSettingTypes'



const init = {
  loading: false,
  email_settings : [],
  email_setting: false,
  error : ""
}

const EmailSettings = (state = init, action) => {
  switch(action.type) {
    case EMAIL_SETTINGS_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case EMAIL_SETTINGS_GET_SUCCESS : {return {...state, loading: false, email_settings: action.payload, error: ""}}
    case EMAIL_SETTINGS_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case EMAIL_SETTINGS_FIND_REQUEST : {return {...state, loading: true, error: "", email_setting: false}}
    case EMAIL_SETTINGS_FIND_SUCCESS : {return {...state, loading: false, email_setting: action.payload, error: ""}}
    case EMAIL_SETTINGS_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case EMAIL_SETTINGS_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case EMAIL_SETTINGS_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case EMAIL_SETTINGS_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default EmailSettings;
