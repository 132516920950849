import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-data-table-component"
import { ReviewFilters } from "./filter"
import { getNPSReport } from "../../store/reports/reportReducer"
import { DownloadDocumentReport } from "../../download"
import NPSReportChart from "./charts/npsReports"

export const NPSReport = (props) => {

  const dispatch = useDispatch()

  const [filters, setFilters] = useState()

  const get = () => {
    dispatch(getNPSReport(filters))
    // dispatch(getNPSReportData(filters))
  }

  const { loading, data } = useSelector((state) => ({
    loading: state.Company.loading,
    data: state.Reports.nps

  }))


  useEffect(() => {
    get()
  }, [filters])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Card>
          <CardBody>
            <DownloadDocumentReport className="float-end" url={`/app/reports/download/nps`} filters={filters}
                                    title={"NPS Report"} />

            <CardTitle>NPS Report</CardTitle>
            <hr />


            <ReviewFilters size={4} branch date department onChange={setFilters} />
            <NPSReportChart dataTitle={"Net Promoter Score"} title={""} data={data}
                            dataColors='["--bs-success","--bs-dark","--bs-danger"]' />
          </CardBody>

          <Datatable progressPending={loading}
                     columns={[
                       { name: "Department", selector: ({ name }) => name, sortable: true, wrap: true },
                       { name: "NPS", center: true, selector: ({ NPS }) => NPS, sortable: true },
                       { name: "Promoters", center: true, selector: ({ promoters }) => promoters, sortable: true },
                       { name: "Neutral", center: true, selector: ({ neutral }) => neutral, sortable: true },
                       { name: "Detractors", center: true, selector: ({ detractors }) => detractors, sortable: true },
                       {
                         name: "Total", right: true, selector: ({ promoters, detractors, neutral }) => (
                           parseInt(promoters ? promoters : 0) +
                           parseInt(detractors ? detractors : 0) +
                           parseInt(neutral ? neutral : 0)), sortable: true
                       }
                     ]} data={data} />
        </Card>
      </Container>
    </div>
  </React.Fragment>
}



