import React, { useEffect, useState } from "react"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Button, ButtonGroup, Card, CardBody, CardTitle, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Pre } from "../../helpers/pre"
import { broadcastBulk, getBulk } from "../../store/bulk/BulkReducer"
import Datatable from "react-data-table-component"
import moment from "moment"
import { BulkNotificationModal } from "./BulkNotificationModal"
import BulkEmailNotificationForm from "./BulkEmailNotificationForm"


const BulkNotifications = (props) => {
  const dispatch = useDispatch()
  const { loading, messages } = useSelector(state => ({
    loading: state.Bulk.loading,
    messages: state.Bulk.messages
  }))

  const [preview, setPreview] = useState(false)
  const [message, setMessage] = useState(false)
  const [form, setForm] = useState(false)
  useEffect(() => {
    dispatch(getBulk())
  }, [dispatch])

  const columns = [
    { selector: ({ subject }) => subject, style: { fontWeight: "bold" }, name: "Subject" },
    {
      selector: ({ emails }) => (emails || []).length, name: "Sent To", center: true
    },
    { selector: ({ created_at }) => moment(created_at).format("Do MMM YYYY HH:mm"), right: true, name: "Timestamp" }
  ]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumb
          title={loading ? "Loading" : "Bulk Messages"}
          breadcrumbItem="Bulk "
        />

        <Card>
          <CardBody>
            <CardTitle>
              Bulk Messages
            </CardTitle>
            <ButtonGroup className="float-end">
              <Button className="btn btn-soft-primary" onClick={() => dispatch(getBulk())}>Reload</Button>
              <Button className="btn btn-soft-secondary" onClick={() => setForm(true)}><i className="mdi mdi-email-multiple-outline" /> New Bulk Email</Button>
              <Button className="btn btn-soft-primary"><i className="mdi mdi-message-outline" /> New Bulk SMS</Button>
              <Button className="btn btn-soft-success"><i className="mdi mdi-whatsapp" /> New Bulk Whatsapp</Button>
            </ButtonGroup>

          </CardBody>
          <Datatable
            onRowClicked={(row) => {
              setPreview(true)
              setMessage(row)
            }}
            striped
            highlightOnHover
            pointerOnHover
            columns={columns}
            data={messages}
            pagination
            progressPending={loading && messages.length === 0} />

        </Card>



        {/*<Pre data={messages} />*/}

        {message && <BulkNotificationModal isOpen={preview} toggle={() => setPreview(!preview)} {...message} />}

        {form && <BulkEmailNotificationForm show={form} onSubmit={data => {
          dispatch(broadcastBulk(data, () => setForm(!form)))
        }} isOpen={form} toggle={() => setForm(!form)} />}

      </Container>
    </div>
  </React.Fragment>

}

export default BulkNotifications