import React, { useEffect, useState } from "react"
import {
  Badge, Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row, Table
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"

import Datatable from "react-data-table-component"

import { departmentGet } from "../../store/departments/depatmentActions"
import Flatpickr from "react-flatpickr"
import { ratingsGet } from "../../store/ratings/ratingReducer"
import { Pre } from "../../helpers/pre"
import moment from "moment"
import { ReviewFilters } from "../reports/filter"
import { DropDownButtonDownload } from "../reports/dropdownButton"
import { DownloadDocumentReport } from "../../download"


export const Overview = () => {
  const dispatch = useDispatch()
  const { Company, Branch, Department, CustomerRatings } = useSelector(state => state)


  const { ratings } = CustomerRatings


  const [filters, setFilters] = useState(false)

  useEffect(() => {
    dispatch(ratingsGet(1, filters))
    // dispatch()
  }, [filters])

  const columns = [
    { name: "#", maxWidth: "10px", selector: (row, index) => row.id },
    {
      name: "Customer", selector: row => row.customer_name, cell: row => <div>
        <div><strong>{row.customer_name}</strong></div>
        <div>
          {row.customer_email && <div><span><i className="mdi mdi-email-outline" /> {row.customer_email}</span></div>}
          {row.customer_mobileno && <div><span><i className="mdi mdi-phone-outline" /> {row.customer_mobileno}</span></div>}
        </div>
      </div>
    },
    {
      name: "Average Ratings", right: true, selector: row => {

        if (!row.avg_rating) return <span className="me-1  p-1 ">N/A</span>
        return row.avg_rating < 3 ? <span color="danger" className="me-1 p-1 ">POOR | {row.avg_rating} <i
          className="mdi mdi-star font-size-20 text-danger" /></span> : (row.avg_rating > 2.5 && row.avg_rating < 3.5 ?
            <span color="warning" className="me-1 p-1 ">NEUTRAL | {row.avg_rating} <i
              className="mdi mdi-star font-size-20 text-warning" /></span>
            : <span color="success" className="me-1 p-1 ">GOOD | {row.avg_rating} <i
              className="mdi mdi-star font-size-20 text-success" /></span>
        )

        return row.avg_rating ? row.avg_rating : "N/A"


      }
    },
    { name: "Timestamp", right: true, selector: row => moment(row.created_at).format("Do MMM YYYY HH:mm") }
  ]

  const ExpandedComponent = ({ data }) =>   <Table style={{marginLeft:'80px', marginRight: '80px'}}>
    <thead>
    <tr>
      <th>Question Type</th>
      <th>Question</th>
      <th>Rating</th>
      <th>Response</th>
      <th>Comments</th>
    </tr>
    </thead>
    <tbody>
    {(data.ratings || []).map(record => <tr key={record.id}>
      <td>{record.question_type}</td>
      <td>{record.question}</td>
      <td>{record.rating}</td>
      <td>{record.comments}</td>
      <td>{record.response}</td>
    </tr>)}
    </tbody>
  </Table>;

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="Overall Reviews"
        />
        <Card>
          <CardBody>
            <span className="float-end">
                          <DownloadDocumentReport url={`/app/reviews/download/overall`} filters={filters} title={"Overall Reviews"} />

              {/*<DropDownButtonDownload filename="overview" data={ratings} title={"overview"} />*/}
            </span>
            <CardTitle>Overall Reviews</CardTitle>
          </CardBody>
          <CardBody>
            <ReviewFilters size={3} branch search department date onChange={(data) => setFilters(data)} />
          </CardBody>
          <Datatable data={ratings} columns={columns} pagination={10} expandableRows
                     expandableRowsComponent={ExpandedComponent}

                     // expandableRowsComponent={({ ratings }) => <div>
                     //




    />

        </Card>
      </Container>
    </div>

  </React.Fragment>
}
