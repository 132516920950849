import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Container, Row, Col, Card, Progress } from "reactstrap"

//Import Images
import error from "../../assets/images/error-img.png"
import { useSelector } from "react-redux"
import { Pre } from "../../helpers/pre"

const Countdown = () => {
  const Ref = useRef(null)

  // The state for our timer
  const [timer, setTimer] = useState(0)

  const getTimeRemaining = (e) => {
    const total =
      Date.parse(e) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor(
      (total / 1000 / 60) % 60
    )
    const hours = Math.floor(
      (total / 1000 / 60 / 60) % 24
    )
    return {
      total,
      hours,
      minutes,
      seconds
    }
  }

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } =
      getTimeRemaining(e)
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(seconds
      )
    }
  }

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer(10)

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current)
    const id = setInterval(() => {
      startTimer(e)
    }, 100)
    Ref.current = id
  }

  const getDeadTime = () => {
    let deadline = new Date()

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 10)
    return deadline
  }

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime())
  }, [])

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime())
  }
  return (<Progress max={10} min={0} value={(timer - 10) * -1} />)
}


const PageAuth = () => {
  //meta title
  document.title = "Authenticating .... please wait"
  const navigate = useNavigate();

  const { Authentication } = useSelector(state => state)
  const { loading, loggedIn, error, checking } = Authentication

  useEffect(() => {
    if (loggedIn === true) navigate('/')
    if(!loading && !loggedIn && !checking) navigate('/login')

  }, [loggedIn, loading, checking])
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12" className="align-content-center align-items-center justify-content-center">

              <div className="text-center align-self-center w-25  mb-5">
                <Card>
                  <h1 className="display-2 fw-medium">
                    <i className="bx bx-user-circle text-primary display-3" />
                  </h1>
                  <h4 className="text-uppercase">Authenticating <i className="bx bx-cog bx-spin  " /></h4>
                  <p>Checking account details</p>
                  <Countdown />
                </Card>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PageAuth
