import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { escalationGroupFind, escalationGroupPost } from "../../store/escalationGroups/escalationGroupActions"
import { Alert, Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import { Pre } from "../../helpers/pre"
import classnames from "classnames"
import toastr from 'toastr'
import { UserChip, UserChips } from "../../components/Common/userChip"
import EscalationGroupForm from "./form/escalationGroupForm"

export const EscalationGroupModal = props => {
  const { isOpen, id, toggle } = props

  const [form, setForm] = useState(false)
  const [active, toggleActive] = useState(0)

  const dispatch = useDispatch()
  const { escalation_group, loading } = useSelector(state => ({
    escalation_group: state.EscalationGroup.escalation_group,
    loading: state.EscalationGroup.loading
  }))

  const get = () => dispatch(escalationGroupFind(id))

  useEffect(() => {
    if (isOpen) get()
  }, [id, isOpen])


  return <React.Fragment>

    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        {loading && "loading"}
        {escalation_group && escalation_group.group_name}
      </ModalHeader>
      <ModalBody>
        <Table>
          <tbody>
          {escalation_group && <tr>
            <th>Company</th>
            <td>{escalation_group.company_name}</td>
          </tr>}
          {escalation_group?.branches_id !== 0 && <tr>
            <th>Branch</th>
            <td>{escalation_group.branch_name}</td>
          </tr>}
          {escalation_group?.departments_id !== 0 && <tr>
            <th>Department</th>
            <td>{escalation_group.department_name}</td>
          </tr>}
          </tbody>
        </Table>

        <div
          className="accordion "
          id="accordionFlushExample"
        >

          {(escalation_group.escalation_levels || []).map((row, index) => <div key={index}
                                                                               className="accordion-item">
              <h2 className="accordion-header" id="headingFlushOne">
                <button
                  className={classnames(
                    "accordion-button",
                    "fw-medium",
                    { collapsed: (index === active) }
                  )}
                  type="button"
                  onClick={() => toggleActive(index)}
                  style={{ cursor: "pointer" }}
                >
                  {(index !== escalation_group.escalation_levels.length - 1) &&
                  <span className="float-end">TAT : {row.tat} Hrs {" - "} </span>}
                  {row.escalation_level_name}
                </button>
              </h2>

              <Collapse
                isOpen={active === index}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  {((escalation_group.escalation_group_users || [])
                    .filter(record => record.escalation_group_level === row.escalation_level))
                    .map(item => <UserChip key={item.id} user={item.user} />)}

                </div>
              </Collapse>
            </div>
          )}

        </div>
        <EscalationGroupForm
          isOpen={form}
          toggle={() => setForm(!form)}
          escalation_group={escalation_group.id}
          saveChanges={data => {
            dispatch(escalationGroupPost(data, get))
            // toggleForm()

          }} />
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-soft-dark" onClick={() => toastr.warning("Still work in pregress")}>Edit</Button>
        <Button className="btn btn-soft-dark" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>


  </React.Fragment>


}

