export const FEEDBACK_GET_REQUEST = "FEEDBACK_GET_REQUEST";
export const FEEDBACK_GET_SUCCESS = "FEEDBACK_GET_SUCCESS";
export const FEEDBACK_GET_FAILURE = "FEEDBACK_GET_FAILURE";


export const FEEDBACK_FIND_REQUEST = "FEEDBACK_FIND_REQUEST";
export const FEEDBACK_FIND_SUCCESS = "FEEDBACK_FIND_SUCCESS";
export const FEEDBACK_FIND_FAILURE = "FEEDBACK_FIND_FAILURE";


export const FEEDBACK_POST_REQUEST = "FEEDBACK_POST_REQUEST";
export const FEEDBACK_POST_SUCCESS = "FEEDBACK_POST_SUCCESS";
export const FEEDBACK_POST_FAILURE = "FEEDBACK_GET_FAILURE";


export const FEEDBACK_DELETE_REQUEST = "FEEDBACK_DELETE_REQUEST";
export const FEEDBACK_DELETE_SUCCESS = "FEEDBACK_DELETE_SUCCESS";
export const FEEDBACK_DELETE_FAILURE = "FEEDBACK_DELETE_FAILURE";



export const FEEDBACK_ACTION_REQUEST = "FEEDBACK_ACTION_REQUEST";
export const FEEDBACK_ACTION_SUCCESS = "FEEDBACK_ACTION_SUCCESS";
export const FEEDBACK_ACTION_FAILURE = "FEEDBACK_ACTION_FAILURE";


export const FEEDBACK_FORM_REQUEST = "FEEDBACK_FORM_REQUEST";
export const FEEDBACK_FORM_SUCCESS = "FEEDBACK_FORM_SUCCESS";
export const FEEDBACK_FORM_FAILURE = "FEEDBACK_FORM_FAILURE";


