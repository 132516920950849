import React from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"
import { UploadDropZone, UploadFile } from "../../component/upload"


export default class CompanyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false,
      selectedFile: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    const { company } = this.props
    axios
      .post("app/companies/form", { id: company })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, company, saveChanges } = this.props
    const { loading, fields, form, selectedFile } = this.state
    return <Modal isOpen={isOpen} toggle={toggle}>
      {!loading && <>
        <ModalHeader>

          {company.id && <>Edit : {company.company_name}</>}
          {!company.id && <>Create Company</>}
        </ModalHeader>
        <ModalBody>

          <div className="mb-3">
            <Label htmlFor="form-company-name">Company Name</Label>
            <Input
              value={fields.company_name}
              onChange={e => this.handleChange("company_name", e.target.value)}
              type="text"
              className="form-control"
              id="form-company-name"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="form-department-country">Country</Label>
            <select
              value={fields.country}
              onChange={e => this.handleChange("country", e.target.value)}
              type="text"
              className="form-control"
              id="form-department-country"
              placeholder=""
            >
              <option>Select Country</option>

              {form.countries && typeof form.countries === "object" && form.countries.map(row => <option
                key={`country-${row.id}`} value={row.id}>{row.country_name}</option>)}

            </select>
          </div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-company-name">Company Email</Label>
                <Input
                  value={fields.company_email}
                  onChange={e => this.handleChange("company_email", e.target.value)}

                  type="text"
                  className="form-control"
                  id="form-company-name"
                  placeholder=""
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-company-name">Company Phone</Label>
                <Input
                  value={fields.company_phone}
                  onChange={e => this.handleChange("company_phone", e.target.value)}

                  type="text"
                  className="form-control"
                  id="form-company-name"
                  placeholder=""
                />
              </div>
            </Col>
          </Row>


          <div className="mb-3">
            <Label htmlFor="form-company-name">Company Location</Label>
            <Input
              value={fields.company_location}
              onChange={e => this.handleChange("company_location", e.target.value)}

              type="text"
              className="form-control"
              id="form-company-name"
              placeholder=""
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="form-company-url">Company URL</Label>
            <Input
              value={fields.url}
              onChange={e => this.handleChange("url", e.target.value)}

              type="text"
              className="form-control"
              id="form-company-url"
              placeholder="e.g. https://brscustomerfeedback.qsys-ea.com/"
            />
          </div>
          {/*<div className="mb-3">*/}
          {/*  <Label htmlFor="form-company-name">Negative Review Level</Label>*/}
          {/*  <Input*/}
          {/*    value={fields.negative_review_level}*/}
          {/*    onChange={e => this.handleChange("negative_review_le", e.target.value)}*/}

          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    id="form-company-name"*/}
          {/*    placeholder=""*/}
          {/*  />*/}
          {/*</div>*/}
          <Row>
            <Col><img src={fields.company_logo} alt={fields.company_logo} className="img-fluid img-thumbnail" /></Col>
            <Col>
              <div>
                <UploadDropZone message={"Upload Company Logo"} callBack={console.log} onUpload={e => this.handleChange('company_logo', e.url)} />
              </div>
            </Col>
          </Row>


          {/*<Pre data={this.state.fields} />*/}
        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </>}
    </Modal>
  }


}
