import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"

import { Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import classnames from "classnames"
import { Link, useNavigate, useParams } from "react-router-dom"
import avatar2 from "../../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"
import avatar6 from "../../../assets/images/users/avatar-6.jpg"
import {
  messageImportant,
  messagesFind,
  messagesGet,
  messageStar,
  messageTrash
} from "../../../store/messages/messageActions"
import EmailToolbar from "../../../pages/Email/email-toolbar"
import moment from "moment"


const MessagesInbox = props => {

  document.title = "Inbox | QSYS Feedback"

  const dispatch = useDispatch()

  const { id } = useParams()
  const navigate = useNavigate()
  const {
    messages, message, loading, selectedmails
  } = useSelector(state => ({
    loading: state.Messages.loading,
    message: state.Messages.message,
    messages: state.Messages.messages,
    selectedmails: state.Messages.selectedmails
  }))

  const [activeTab, setactiveTab] = useState()
  const [page, setPage] = useState(1)
  // const [message, setMesssage]
  const get = () => dispatch(messagesGet(page, activeTab))

  const find = (id) => dispatch(messagesFind(id))

  const handleSelect = (selectedItems) => {
    // dispatch(onGetSelectedMails(selectedItems));
  }

  const handleActive = (active) => {
    if (id) navigate("/profile/messages")
    setactiveTab(active)
  }


  const hasStarred = (mail) => dispatch(messageStar(mail))

  const handleTrash = (mail) => dispatch(messageTrash(mail))

  const handleImportant = (mail) => dispatch(messageImportant(mail))


  useEffect(() => id ? find(id) : get(), [page, id, activeTab])


  return (<React.Fragment>
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Email" breadcrumbItem="Inbox" />
        <Row>
          <Col xs="12">
            {/* Render Email SideBar */}
            <Card className="email-leftbar">
              <div className="mail-list mt-4">
                <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === ""
                      })}
                      onClick={() => handleActive("")}
                    >
                      <i className="mdi mdi-email-outline me-2"></i> Inbox{" "}
                      <span className="ml-1 float-end">{messages && messages.unread && `(${messages.unread})`}</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "starred"
                      })}
                      onClick={() => handleActive("starred")}
                    >
                      <i className="mdi mdi-star-outline me-2"></i>Starred
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "important"
                      })}
                      onClick={() => handleActive("important")}

                    >
                      <i className="mdi mdi-diamond-stone me-2"></i>Important
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "trash"
                      })}
                      onClick={() => handleActive("trash")}
                    >
                      <i className="mdi mdi-trash-can-outline me-2"></i>Trash
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <h6 className="mt-4">Chat</h6>
              <div className="mt-2">
                <Link to="#" className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      className="d-flex me-3 rounded-circle"
                      src={avatar2}
                      alt="skote"
                      height="36"
                    />
                  </div>
                  <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">Scott Median</p>
                    <p className="text-muted">Hello</p>
                  </div>
                </Link>

                <Link to="#" className="d-flex">
                  <img
                    className="d-flex me-3 rounded-circle"
                    src={avatar3}
                    alt="skote"
                    height="36"
                  />
                  <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">Julian Rosa</p>
                    <p className="text-muted">What about our next..</p>
                  </div>
                </Link>

                <Link to="#" className="d-flex">
                  <img
                    className="d-flex me-3 rounded-circle"
                    src={avatar4}
                    alt="skote"
                    height="36"
                  />
                  <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">David Medina</p>
                    <p className="text-muted">Yeah everything is fine</p>
                  </div>
                </Link>

                <Link to="#" className="d-flex">
                  <img
                    className="d-flex me-3 rounded-circle"
                    src={avatar6}
                    alt="skote"
                    height="36"
                  />
                  <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">Jay Baker</p>
                    <p className="text-muted">Wow that&apos;s great</p>
                  </div>
                </Link>
              </div>
            </Card>


            <div className="email-rightbar mb-3">
              {!id && !loading && <>
                <Card>
                  {((messages && messages.data) || []).length > 0 ? <>
                    {/* Render Email Top Tool Bar */}
                    <EmailToolbar selectedmails={selectedmails} activeTab={activeTab} />
                    <ul className="message-list">
                      {((messages && messages.data) || []).map((mail, key) => (
                        <li key={key} className={mail.unread ? "" : "unread"}>
                          <Link to={`/profile/messages/${mail.id}`}>
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input type="checkbox" value={mail.id} id={mail.id} name="emailcheckbox"
                                       onChange={(e) => e.target.value}
                                       onClick={(e) => handleSelect(e.target.value)}
                                       checked={selectedmails.includes(mail.id)} />
                                <Label htmlFor={mail.id} className="toggle" />
                              </div>
                              <span className="title">
                                  {mail.from_user ? mail.from_user.name : "Qsys Feedack"}
                                </span>
                              {mail.star ?
                                <span className="star-toggle fas fa-star text-warning" style={{ cursor: "pointer" }}
                                      onClick={() => hasStarred(mail)} /> :
                                <span className="star-toggle far fa-star" style={{ cursor: "pointer" }}
                                      onClick={() => hasStarred(mail)} />}
                            </div>
                            <div className="col-mail col-mail-2">
                              {mail.subject ? <div
                                dangerouslySetInnerHTML={{
                                  __html: mail?.subject.substring(0, 50) + (mail?.subject.length > 50 ? "..." : "")
                                }}
                              ></div> : <div>No Subject</div>}
                              <div
                                className="date">{moment(mail.created_at).isSame(moment(), "day") ? moment(mail.created_at).format("HH:mm") : moment(mail.created_at).format("Do MMM YYYY")}</div>
                            </div>
                          </Link>
                        </li>))}
                    </ul>
                  </> : <div className="align-items-center text-center p-4"><i
                    className="mdi mdi-email-outline me-2 display-5"></i> <h4> No Recored Found </h4>
                  </div>}
                </Card>
                {((messages && messages.data) || []).length > 0 && <Row>
                  <Col xs="7">Showing 1 - {messages.total < 20 ? messages.total : 20} of {messages.total}</Col>
                  <Col xs="5">
                    <div className="btn-group float-end">
                      {page > 1 && <Button
                        type="button"
                        color="success"
                        size="sm"
                        onClick={() => setPage(page + 1)}

                      >
                        <i className="fa fa-chevron-left" />
                      </Button>}
                      <Button
                        type="button"
                        color="success"
                        size="sm"
                        onClick={() => setPage(page + 1)}

                      >
                        <i className="fa fa-chevron-right" />
                      </Button>
                    </div>
                  </Col>
                </Row>}</>}
              {message && id && !loading && <>
                <Card>
                  <CardBody>
                    <div className="d-flex mb-4">
                      <img
                        className="d-flex me-3 rounded-circle avatar-sm"
                        src={avatar2}
                        alt="skote"
                      />
                      <div className="flex-grow-1">
                        <h5 className="font-size-14 mt-1">
                          {message.from_user?.name}
                        </h5>
                        <small className="text-muted">{message.from_user?.email}</small>
                      </div>
                    </div>

                    <h4 className="mt-0 font-size-16">
                      {message.subject}
                    </h4>
                    <div dangerouslySetInnerHTML={{ __html: message.message_html }}></div>
                    <br />
                    {message.message_sms && <CardBody className="bg-colored text-white">
                      <CardTitle>SMS</CardTitle>
                      <div dangerouslySetInnerHTML={{ __html: message.message_sms }}></div>

                    </CardBody>}
                  </CardBody>
                </Card>
              </>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>)
}

export default MessagesInbox
