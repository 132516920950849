import React from 'react'
import Breadcrumbs from "../components/Common/Breadcrumb"
import { Col, Container, Row } from "reactstrap"
import maintenance from "../assets/images/maintenance.svg"
import { Pre } from "../components/pre"


export const Blank = (props) => {
  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.title ? props.title : "Coming Soon"}
          breadcrumbItem={"under construction"}
        />

        <Row>
          <Col className="col-12 text-center">
            <div className="home-wrapper">


              <Row className="justify-content-center">
                <Col sm={4}>
                  <div className="maintenance-img">
                    <img src={maintenance} alt="" className="img-fluid mx-auto d-block" />
                  </div>
                </Col>
              </Row>
              <h3 className="mt-5">Coming Soon</h3>
              <p>Please check back in sometime.</p>

            </div>
          </Col>
        </Row>
        <Pre data={props} />

      </Container>
    </div>
  </React.Fragment>
}
