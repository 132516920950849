import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import moment from "moment"
import {

  Card,
  CardBody,
  CardTitle,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { DataTableList } from "../../components/tables/dataTable"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { feedbackComplaintsGet } from "../../store/complaints/feedbackComplaintsReducer"
import { FeedbackComplainFormModal } from "./feedbackComplainModal"
import { Pre } from "../../helpers/pre"
import { ReviewFilters } from "../reports/filter"
import { StatusBadge } from "../../components/general"


export const FeedbackComplaints = props => {

  document.title = "Feedback Complaints | QSYS Feedback"


  const dispatch = useDispatch()

  const {
    loading,
    feedback_complaints
  } = useSelector(state => (
    {
      feedback_complaints: state.FeedbackComplaints.feedback_complaints,
      loading: state.Complaints.loading
    }
  ))

  const [customer_feedback, set_customer_feedback] = useState(false)


  const [form, setForm] = useState(false)

  const [tickets, setTickets] = useState("Open")

  const [filter, setFilter] = useState({})

  const toggle = () => setForm(!form)


  const get = () => dispatch(feedbackComplaintsGet(filter))


  useEffect(() => {
    get()
  }, [filter])



  const columns = [
    { name: "Form", cell: ({ form_name }) => <strong>{form_name}</strong> },
    { name: "Complaints", center: true, cell: ({ complaints}) => <><strong>{complaints.length}</strong> </>},
    {
      name: "Customer", selector: row => row.name, cell: row => <div>
        <div><strong>{row.customer_name}</strong></div>
        <div><small>{row.customer_email}</small></div>
        {/*<div><strong>{row.customer_phone}</strong></div>*/}
      </div>
    },
    {
      name: "AVG Rating", selector: ({ avg_rating }) => avg_rating, cell: ({ avg_rating }) => <span>
        Poor : {avg_rating} <i className="mdi mdi-star text-danger" />
      </span>
    },
    { name: "Date", cell: ({ created_at }) => created_at && moment(created_at).format("Do MMM YYYY HH:mm") },
    { name: "Status", cell: ({ complaints_status }) => <StatusBadge status={complaints_status} /> },

    // { name: "Progress", cell: ({ complaints }) => <div>
    //     {complaints.length > 0 && <>
    //     {Math.round((complaints.filter(row => row.status === "closed").length / complaints.length) * 100)} %
    //     </>}
    //
    //   </div> },

  ]

  return <React.Fragment>

    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Questions"}
          breadcrumbItem="Feedback Complaints"
        />
        <Card>
          <CardBody>
            <CardTitle>Feedback Complaints</CardTitle>
            <hr className="m-3" />
            <ReviewFilters size={3} onChange={setFilter} branch department search year  />
          </CardBody>

        </Card>
        {(feedback_complaints || []).length > 0 && <DataTableList
          columns={columns}
          onRowClicked={(row) => {
            // console.log(row)
            set_customer_feedback(row)
            toggle()
          }}
          get={get}
          className={`project-list-table table-nowrap align-middle table-borderless table-hover `}
          // current_page={1}
          // last_page={2}
          hidePagination
          data={feedback_complaints}
          callBack={get}
          loading={loading}
          setCurrentPage={() => {
          }}

        />}

      </Container>
    </div>

    <FeedbackComplainFormModal
      get={get}
      isOpen={form} toggle={() => setForm(!form)} customer_feedback={customer_feedback} />


  </React.Fragment>
}
