import {
  EMAIL_SETTINGS_GET_REQUEST,
  EMAIL_SETTINGS_GET_SUCCESS,
  EMAIL_SETTINGS_GET_FAILURE,
  EMAIL_SETTINGS_FIND_REQUEST,
  EMAIL_SETTINGS_FIND_SUCCESS,
  EMAIL_SETTINGS_FIND_FAILURE,
  EMAIL_SETTINGS_ACTION_REQUEST,
  EMAIL_SETTINGS_ACTION_SUCCESS,
  EMAIL_SETTINGS_ACTION_FAILURE,} from './emailSettingTypes'


import axios from "axios"


export const emailSettingsGet = () => dispatch => {
  dispatch({ type: EMAIL_SETTINGS_GET_REQUEST })
  axios.get("/app/settings/email/get")
    .then(({ data }) => dispatch({ type: EMAIL_SETTINGS_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: EMAIL_SETTINGS_GET_FAILURE, payload: message }))
}

export const emailSettingsFind = (id) => dispatch => {
  dispatch({ type: EMAIL_SETTINGS_FIND_REQUEST })
  axios.get(`/app/settings/email/get/${id}`)
    .then(({ data }) => dispatch({ type: EMAIL_SETTINGS_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: EMAIL_SETTINGS_FIND_FAILURE, payload: message }))
}

export const emailSettingsDelete = (id, callBack) => dispatch => {
  dispatch({ type: EMAIL_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/email/delete/`, {id})
    .then(({ data }) => {
      dispatch({ type: EMAIL_SETTINGS_ACTION_SUCCESS, payload: data })
      dispatch(emailSettingsGet())

    })
    .catch(({ message }) => dispatch({ type: EMAIL_SETTINGS_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const emailSettingsPost = (data, callBack) => dispatch => {
  dispatch({ type: EMAIL_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/email/submit`, data)
    .then(({ data }) => {
      dispatch({ type: EMAIL_SETTINGS_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(emailSettingsGet())
        dispatch(emailSettingsFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: EMAIL_SETTINGS_ACTION_FAILURE, payload: message }))
}

export const emailSettingsEnable = (data, callBack) => dispatch => {
  dispatch({ type: EMAIL_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/email/enable`, data)
    .then(({ data }) => {
      dispatch({ type: EMAIL_SETTINGS_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(emailSettingsGet())
        dispatch(emailSettingsFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: EMAIL_SETTINGS_ACTION_FAILURE, payload: message }))
}

export const emailSettingsDisable = (data, callBack) => dispatch => {
  dispatch({ type: EMAIL_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/email/disable`, data)
    .then(({ data }) => {
      dispatch({ type: EMAIL_SETTINGS_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(emailSettingsGet())
        dispatch(emailSettingsFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: EMAIL_SETTINGS_ACTION_FAILURE, payload: message }))
}
