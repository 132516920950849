import React, { useEffect, useState } from "react"
import {
  ButtonGroup,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Card, Container
} from "reactstrap"
import { roleDelete, roleFind, togglePermission } from "../../store/roles/roleActions"
import { useDispatch, useSelector } from "react-redux"

const CheckBox = ({ permission, assignPermissions , role , loading, reload}) => {
  const [checked, setChecked] = useState(permission.checked)

  useEffect(() => {setChecked(permission.checked)}, [role])


  const dispatch = useDispatch()
  return <Button disabled={!assignPermissions} className={`btn btn-${!checked ? 'light' : 'soft-success'}`}
                 onClick={() => {
                   setChecked(!checked)
                   dispatch(togglePermission(permission.id, role, reload))
                 }}>

    {checked !== permission.checked && loading ? <i className="mdi mdi-spin mdi-loading" /> :
      checked && <i className="mdi mdi-check" />
    } {permission.name}

  </Button>
  //
  // return <div className="form-check form-switch mb-3">
  //   <Input
  //     className="form-check-input"
  //     checked={checked}
  //     onChange={() => console.log("CHANGE")}
  //     id={`permission-${permission.id}`}
  //     type="checkbox" />
  //   <Label htmlFor={`permission-${permission.id}`}
  //          style={{ marginLeft: 5 }}>{permission.name} {checked ? "true" : "false"}</Label>
  // </div>


}


export const RoleDetails = (props) => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => ({
    data: state.Roles.role,
    loading: state.Roles.loading
  }))
  const { id } = props

  const find = () => dispatch(roleFind(id))

  const [role, setRole] = useState(false)

  useEffect(() => setRole(data), [data])


  useEffect(() => find(), [id])

  const handleClick = (index, permission) => {
    console.log(permission)

    let record = role
    record.allPermissions[index].checked = !permission.checked
    setRole(record)
  }

  return role && <>
    <Card>
      <CardBody>
        <div>
          {role?.modules.map((module, index) => <div key={`role-module-${index}`}>

            <CardTitle>{module.module}</CardTitle>
            <>

              <Row className="mb-4">
                {(role.allPermissions || [])
                  .filter((row, index) => row.module === module.module)
                  .map((permission, index) => <Col
                    className="mb-1"
                    sm={3}
                    key={`key-permission-${permission.id}-${index}`}>
                    <CheckBox {...props} reload={find} loading={loading} permission={permission} role={role.id} />
                  </Col>)}
              </Row>
            </>


          </div>)}

        </div>

      </CardBody>
    </Card>

    {loading  && <div className="rightbar-overlay"></div>}

  </>

}
