import {
  BRANCH_GET_REQUEST,
  BRANCH_GET_SUCCESS,
  BRANCH_GET_FAILURE,
  BRANCH_FIND_REQUEST,
  BRANCH_FIND_SUCCESS,
  BRANCH_FIND_FAILURE,
  BRANCH_POST_REQUEST,
  BRANCH_POST_SUCCESS,
  BRANCH_POST_FAILURE,
  BRANCH_ACTION_REQUEST,
  BRANCH_ACTION_SUCCESS,
  BRANCH_ACTION_FAILURE

} from "./branchTypes"


const init = {
  loading: false,
  branches : false,
  branch: false,
  error : ""
}

const Branch = (state = init, action) => {
  switch(action.type) {
    case BRANCH_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case BRANCH_GET_SUCCESS : {return {...state, loading: false, branches: action.payload, error: ""}}
    case BRANCH_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case BRANCH_FIND_REQUEST : {return {...state, loading: true, error: "", branch: false}}
    case BRANCH_FIND_SUCCESS : {return {...state, loading: false, branch: action.payload, error: ""}}
    case BRANCH_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case BRANCH_POST_REQUEST : {return {...state, loading: true, error: ""}}
    case BRANCH_POST_SUCCESS : {return {...state, loading: false, error: ""}}
    case BRANCH_POST_FAILURE : {return {...state, loading: false, error: action.payload}}

    case BRANCH_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case BRANCH_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case BRANCH_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default Branch;
