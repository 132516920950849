import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import axios from "axios"

const StatWidgets = () => {
  const [chartsData, setData] = useState([])

  const get = () => {
    axios.get("app/stats").then(({ data }) => setData(data))

  }

  useEffect(() => get(), [])


  return (<React.Fragment>
      <Row>
        {(chartsData || []).map((item, key) => (<Col lg={key == 0 ? 4 : 2} key={key}>
            <Card className="mini-stats-wid" onClick={get}>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">{item.title}</p>
                    <h4 className="mb-0">{item.display_value}</h4>
                  </div>

                  <div className="flex-shrink-0 align-self-center">
                    {/*<JobWidgetCharts dataColors={item.color} series={item.seriesData} dir="ltr" />*/}
                    <h1 className="text-center m-0 p-3">   {(item.last_month < item.total) ?
                      <i className="text-success mdi mdi-chevron-triple-up" /> :
                      <i className="text-danger mdi mdi-chevron-triple-down" />}</h1>
                    {/*<p>Promoters</p>*/}
                  </div>
                </div>
              </CardBody>
              {(item.last_month > item.total) ? <div className="card-body border-top py-3">
                <p className="mb-0"> <span className={"badge badge-soft-danger me-2"}>
                                    <i
                                      className="bx bx-trending-down align-bottom me-1" /> {(item.last_month - item.total).toFixed(2)}{" "}</span>
                  Decrease from last month</p>
              </div> : <div className="card-body border-top py-3">
                <p className="mb-0"> <span className={"badge badge-soft-success me-2"}>
                                        <i
                                          className="bx bx-trending-up align-bottom me-1"></i> {((item.total - item.last_month).toFixed(2))}{" "}</span>
                  Increase from last month</p>
              </div>}

            </Card>
          </Col>))}
      </Row>
    </React.Fragment>)
}

export default StatWidgets
