import {
  DEPARTMENT_GET_REQUEST,
  DEPARTMENT_GET_SUCCESS,
  DEPARTMENT_GET_FAILURE,
  DEPARTMENT_FIND_REQUEST,
  DEPARTMENT_FIND_SUCCESS,
  DEPARTMENT_FIND_FAILURE,
  DEPARTMENT_POST_REQUEST,
  DEPARTMENT_POST_SUCCESS,
  DEPARTMENT_POST_FAILURE,
  DEPARTMENT_ACTION_REQUEST,
  DEPARTMENT_ACTION_SUCCESS,
  DEPARTMENT_ACTION_FAILURE

} from "./departmentTypes"
import axios from "axios"


export const departmentGet = (params) => dispatch => {
  dispatch({ type: DEPARTMENT_GET_REQUEST })
  axios.get("/app/departments/get", {params})
    .then(({ data }) => dispatch({ type: DEPARTMENT_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: DEPARTMENT_GET_FAILURE, payload: message }))
}

export const departmentFind = (id) => dispatch => {
  dispatch({ type: DEPARTMENT_FIND_REQUEST })
  axios.get(`/app/departments/get/${id}`)
    .then(({ data }) => dispatch({ type: DEPARTMENT_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: DEPARTMENT_FIND_FAILURE, payload: message }))
}

export const departmentDelete = (id, callBack) => dispatch => {
  dispatch({ type: DEPARTMENT_ACTION_REQUEST })
  axios.post(`/app/departments/delete`, {id})
    .then(({ data }) => {
      dispatch({ type: DEPARTMENT_ACTION_SUCCESS, payload: data })
      dispatch(departmentGet())

    })
    .catch(({ message }) => dispatch({ type: DEPARTMENT_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const departmentPost = (data, callBack) => dispatch => {
  dispatch({ type: DEPARTMENT_POST_REQUEST })
  axios.post(`/app/departments/submit`, data)
    .then(({ data }) => {
      dispatch({ type: DEPARTMENT_POST_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(departmentGet())
        dispatch(departmentFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: DEPARTMENT_POST_FAILURE, payload: message }))
}

export const departmentPatch = (data, callBack) => dispatch => {

}

export const departmentDuplicate = (row, callBack) => dispatch => {

}

