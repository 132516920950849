import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, CardTitle } from "reactstrap"
import {
  escalationLevelDelete,
  escalationLevelGet,
  escalationLevelPost
} from "../../store/escalationLevels/escalationLevelActions"
import { Link } from "react-router-dom"
import { DataTableList } from "../../components/tables/dataTable"
import EscalationLevelForm from "./form/escalationLevelForm"

export const EscalationLevels = props => {
  const dispatch = useDispatch()

  const { EscalationLevel } = useSelector(state => state)
  const { escalation_levels, loading, error, escalation_level } = EscalationLevel

  const get = () => dispatch(escalationLevelGet())

  useEffect(get, [escalation_level])


  const [form, setForm] = useState(false)
  const [level, setLevel] = useState(false)

  const toggleForm = () => setForm(!form)

  const onCreate = () => {
    setLevel(false)
    toggleForm()
  }

  const onEdit = (level) => {
    setLevel(level)
    toggleForm()
  }

  const onDelete = (level) => {
    setLevel(level)
    dispatch(escalationLevelDelete(level.id, get))
  }

  const columns = [
    {
      name: "ID",
      selector: "id",
      style: { maxWidth: 20 },
      sortable: true
    },
    {
      name: "Name",
      selector: "escalation_level",
      sortable: true,
      left: true
    },
    {
      name: "Groups",
      selector: "id",
      center: true,
      sortable: true
    },
    {
      name: "",
      className: " align-content-end",
      cell: row => <div className="float-end">
        <Link className="p-3" onClick={() => onDelete(row)}><i className=" mdi mdi-trash-can-outline font-size-17 text-danger"></i>{" "}</Link>
        <Link className="p-3" onClick={() => onEdit(row)}><i className=" mdi mdi-pencil-outline font-size-17 text-primary"></i></Link>
      </div>
    }

  ]

  return <Card>
    <CardBody>
      <div className="btn-group float-end ">
        <Button onClick={() => onCreate()} className="btn btn-primary btn-soft-primary">
          <i className="mdi mdi-plus font-size-18"></i>
          Create
        </Button>
      </div>
      <CardTitle>Escalation Levels</CardTitle>

      <DataTableList

        columns={columns}
        get={get}
        className={`project-list-table table-nowrap align-middle table-borderless`}
        current_page={1}
        last_page={1}
        data={escalation_levels}
        callBack={get}
        loading={loading}
        setCurrentPage={() => {
        }}

      />

      <EscalationLevelForm

        isOpen={form}
        toggle={toggleForm}
        escalation_level={level}
        saveChanges={data => {
          dispatch(escalationLevelPost(data, get))
          toggleForm()

        }}
      />
    </CardBody>



  </Card>


}
