import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-data-table-component"
import { ReviewFilters } from "./filter"
import { getCSATReport } from "../../store/reports/reportReducer"
import CSATReportChart from "./charts/csatReports"
import { DownloadDocumentReport } from "../../download"


export const CSATReports = (props) => {

  const dispatch = useDispatch()

  const [filters, setFilters] = useState()

  const get = () => {
    dispatch(getCSATReport(filters))
    // dispatch(getCSATReportData(filters))
  }

  const { loading, data } = useSelector((state) => ({
    loading: state.Reports.loading,
    data: state.Reports.csat
  }))


  useEffect(() => {
    !loading && get()
  }, [filters])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Card>
          <CardBody>

            <DownloadDocumentReport className="float-end" url={`/app/reports/download/csat`} filters={filters}
                                    title={"CSAT Report"} />
            <CardTitle>CSAT Report</CardTitle>
            <hr />
            <ReviewFilters size={4} branch date department onChange={setFilters} />
            <CSATReportChart dataTitle={"Customer Satisfaction"} title={""} data={data}
                             dataColors='["--bs-primary","--bs-warning", "--bs-danger"]' />
          </CardBody>
          <Datatable
            progressPending={loading}
            columns={[
              { name: "Department", selector: ({ name }) => name, sortable: true, wrap: true},
              { name: "CSAT", selector: ({ CSAT }) => CSAT, sortable: true },
              { name: "Satisfied", center: true, selector: (row) => row["satisfied"], sortable: true },
              { name: "Not Sure", center: true, selector: (row) => row["not sure"], sortable: true },
              { name: "Not Satisfied", center: true, selector: (row) => row["not satisfied"], sortable: true },
              {
                name: "Total",
                right: true,
                selector: (row) => (parseInt(row["satisfied"]) + parseInt(row["not satisfied"]) + parseInt(row["not sure"])),
                sortable: true
              }]}
            data={data} />
        </Card>
      </Container>
    </div>
  </React.Fragment>
}



