import axios from "axios"
import { handleError } from "../../helpers/handleError"

const PERMISSION_GET_REQUEST = "PERMISSION_GET_REQUEST"
const PERMISSION_GET_SUCCESS = "PERMISSION_GET_SUCCESS"
const PERMISSION_GET_FAILURE = "PERMISSION_GET_FAILURE"


const init = {
  permissions: [],
  loading: false,
  error: false
}


const Permissions = (state = init, action) => {
  switch (action.type) {
    case PERMISSION_GET_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case PERMISSION_GET_SUCCESS : {
      return { ...state, permissions: action.payload, loading: false, error: false }
    }
    case PERMISSION_GET_FAILURE : {
      return { ...state, error: action.payload, loading: false }
    }
    default : {return {...state}}
  }
}


export default Permissions


export const permissionsGet = () => dispatch => {
  dispatch({ type: PERMISSION_GET_REQUEST })
  axios.get("auth/permissions")
    .then(({ data }) => dispatch({ type: PERMISSION_GET_SUCCESS, payload: data }))
    .catch(e => handleError(e, dispatch({ type: PERMISSION_GET_FAILURE, payload: e.message })))
}
