import {
  QUESTION_GET_REQUEST,
  QUESTION_GET_SUCCESS,
  QUESTION_GET_FAILURE,
  QUESTION_FIND_REQUEST,
  QUESTION_FIND_SUCCESS,
  QUESTION_FIND_FAILURE,
  QUESTION_POST_REQUEST,
  QUESTION_POST_SUCCESS,
  QUESTION_POST_FAILURE,
  QUESTION_ACTION_REQUEST,
  QUESTION_ACTION_SUCCESS,
  QUESTION_ACTION_FAILURE

} from "./questionTypes"


const init = {
  loading: false,
  questions : {},
  question: false,
  error : ""
}

const Question = (state = init, action) => {
  switch(action.type) {
    case QUESTION_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case QUESTION_GET_SUCCESS : {return {...state, loading: false, questions: action.payload, error: ""}}
    case QUESTION_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case QUESTION_FIND_REQUEST : {return {...state, loading: true, error: "", question: false}}
    case QUESTION_FIND_SUCCESS : {return {...state, loading: false, question: action.payload, error: ""}}
    case QUESTION_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case QUESTION_POST_REQUEST : {return {...state, loading: true, error: ""}}
    case QUESTION_POST_SUCCESS : {return {...state, loading: false, error: ""}}
    case QUESTION_POST_FAILURE : {return {...state, loading: false, error: action.payload}}

    case QUESTION_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case QUESTION_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case QUESTION_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default Question;
