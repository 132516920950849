import React, { useEffect, useState } from "react"
import {
  Button,
  Card, CardBody, CardTitle,
  Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink,
  Table, UncontrolledDropdown
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  companyActivate,
  companyDeactivate,
  companyDelete,
  companyDuplicate,
  companyGet,
  companyPatch,
  companyPost, companySuspend
} from "../../store/companies/companyActions"
import { Link } from "react-router-dom"
import { NegativeLevel, StatusBadge } from "../../components/general"
import moment from "moment"
import CompanyForm from "./forms/companyForm"
import { DataTableList } from "../../components/tables/dataTable"


export const Companies = () => {

  const [form, setForm] = useState(false)
  const [company, setCompany] = useState(0)

  const dispatch = useDispatch()
  const { Company } = useSelector(state => state)
  const { loading, error, companies } = Company

  const get = () => dispatch(companyGet())

  useEffect(() => {
    get()
  }, [])


  const toggleForm = () => {
    setCompany(false)
    setForm(false)
  }

  const onCreate = () => {
    setCompany(0)
    setForm(true)
  }


  document.title = "Companies | Q-SYS"


  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Companies"}
          breadcrumbItem="Companies"
        />


        <Card className="bg-transparent">
          <CardBody>

            <div className="btn-group float-end">
              <Button onClick={onCreate} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Button>
            </div>
            <CardTitle>Companies </CardTitle>

          </CardBody>
          <CompaniesList
            get={get}
            data={companies.data}
            loading={loading}

          />

        </Card>


      </Container>

    </div>
    <CompanyForm toggle={toggleForm}
                 isOpen={form}
                 company={company}
                 saveChanges={data => {
                   setForm(false)
                   dispatch(companyPost(data, get))
                   // setCompany(false)
                 }

                 } />
  </React.Fragment>
}

export const CompaniesList = props => {
  const dispatch = useDispatch()
  const [company, setCompany] = useState(0)
  const [form, setForm] = useState(false)

  const onDelete = item => dispatch(companyDelete(item.id))

  const toggleForm = () => {
    setCompany(false)
    setForm(false)
  }
  const onDuplicate = row => dispatch(companyDuplicate(row, props.get))

  const onEdit = (company) => {
    setCompany(company.id)
    setForm(true)
  }
  const columns = [
    // {
    //   name: "#",
    //   selector: "id",
    //   style: { width: 60, alignText: "center", fontWeight: "bold" },
    //   cell: ({ row, index }) => index + 1
    // },
    {
      name: "Logo",
      selector: "id",
      style: { width: 60, alignText: "center" },
      cell: (row) => <img src={row.company_logo} height={25} />
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => <>
        <div>
          <Link to={`/companies/details/${row.id}`}><strong>{row.company_name}</strong></Link></div>
        <div>
          <><StatusBadge status={row.status} {...props} />{" "}<small>{row.companies_id}</small></>
        </div>
      </>
    },
    {
      name: "Country",
      selector: "country_name",
      cell: ({ country_name }) => country_name
    },
    {
      name: "contacts",
      selector: "company_email",
      cell: (row) => <>
        <div><i className="bx bx-phone"></i> {row.company_phone}</div>
        <div><i className="bx bx-at"></i> {row.company_email}</div>
        <div><i className="bx bx-at"></i> {row.company_location}</div>
      </>
    },
    {
      name: "Link",
      selector: "url",
      cell: (row) => <Link target="_blank" to={row.url}>{row.url}</Link>
    },
    {
      name: "",
      selector: "id",
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            href="#"
            onClick={() => onEdit(row)}
          >
            <i className="mdi mdi-pencil-outline font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>
          {(["deactivated", "suspended", "initiated"].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can-outline font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>}
          {(["activated"].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => dispatch(companyDeactivate(row.id))}
          >
            <i className="mdi mdi-stop-circle-outline font-size-16 text-warning me-1" />{" "}
            Deactivate
          </DropdownItem>}
          {(["deactivated", "initiated", "suspended"].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => dispatch(companyActivate(row.id))}
          >
            <i className="mdi mdi-play-circle-outline font-size-16 text-success me-1" />{" "}
            Activate
          </DropdownItem>}
          {(["deactivated"].includes(row.status)) && <DropdownItem
            href="#"
            onClick={() => dispatch(companySuspend(row.id))}
          >
            <i className="mdi mdi-lock-alert-outline font-size-16 text-danger me-1" />{" "}
            Suspend
          </DropdownItem>}


        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]
  return <>
    {props.data && props.data.length > 0 && <DataTableList

      columns={columns}
      get={props.get}
      className={`project-list-table table-nowrap align-middle table-borderless`}
      current_page={1}
      last_page={2}
      data={props.data}
      callBack={props.get}
      loading={props.loading}
      setCurrentPage={() => {
      }}

    />}

    <CompanyForm toggle={toggleForm}
                 isOpen={form}
                 company={company}
                 saveChanges={data => {
                   setForm(false)
                   dispatch(companyPost(data, props.get))
                   // setCompany(false)
                 }

                 } />
  </>

}
