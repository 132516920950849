import {
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_FIND_REQUEST,
  USER_FIND_SUCCESS,
  USER_FIND_FAILURE,
  USER_POST_REQUEST,
  USER_POST_SUCCESS,
  USER_POST_FAILURE,
  USER_ACTION_REQUEST, USER_ACTION_SUCCESS, USER_ACTION_FAILURE
} from "./userTypes"



const init = {
  loading: false,
  users : {},
  user: false,
  error : ""
}

const Roles = (state = init, action) => {
  switch(action.type) {
    case USER_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case USER_GET_SUCCESS : {return {...state, loading: false, users: action.payload, error: ""}}
    case USER_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case USER_FIND_REQUEST : {return {...state, loading: true, error: "", user: false}}
    case USER_FIND_SUCCESS : {return {...state, loading: false, user: action.payload, error: ""}}
    case USER_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case USER_POST_REQUEST : {return {...state, loading: true, error: ""}}
    case USER_POST_SUCCESS : {return {...state, loading: false, error: ""}}
    case USER_POST_FAILURE : {return {...state, loading: false, error: action.payload}}

    case USER_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case USER_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case USER_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}

    default : {  return { ...state }    }
  }

}

export default Roles;
