import React, { useEffect, useState } from "react"
import { Blank } from "../../index"
import {
  Button,
  CardBody,
  CardTitle,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  UncontrolledDropdown
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  smsSettingsDelete,
  smsSettingsDisable,
  smsSettingsEnable,
  smsSettingsGet, smsSettingsPost
} from "../../../store/smsSettings/smsSettingActions"
import { DataTableList } from "../../../components/tables/dataTable"
import { Link } from "react-router-dom"
import { StatusBadge } from "../../../components/general"
import SMSSettingsForm from "./smsSettingsForm"


export const SMSSettings = props => {

  const dispatch = useDispatch();

  const {SMSSettings} = useSelector(state => state);

  const {sms_settings, sms_setting, loading, error} = SMSSettings;

  const [sms, setSms] = useState(false);
  const [form, setForm] = useState(false);

  const toggleForm = () => setForm(!form);


  const get = () => dispatch(smsSettingsGet())

  useEffect(get, [sms_setting])

  // useEffect(() => {}, sms)

  const onCreate = () => {setSms(false); setForm(true)}

  const onEdit = (sms) => {setSms(sms); setForm(true)}

  const onDelete = (sms) => {
    dispatch(smsSettingsDelete(sms.id, get))
  }



  const columns = [
    {
      name: "#",
      selector: "id",
      style: { width: 60, alignText: "center", fontWeight: "bold" },
      cell: (row, index) => index + 1
    },
    {
      name: "Provider",
      selector: "provider",
      cell: (row) => <div>
        <div>
          <Link onClick={() => setSms(row)}><strong>{row.provider}</strong></Link></div>

        <StatusBadge status={row.status}  />
      </div>
    },
    {
      name: "Company",
      selector: "company_name",
      cell: ({ company_name }) => company_name
    },
    {
      name: "Callback URL",
      selector: "callback_url",
      center: true,
      cell: ({ callback_url }) => callback_url
    },


    {
      name: "",
      selector: "id",
      className: "flex-end",
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            href="#"
            onClick={() => onEdit(row)}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>
          <DropdownItem
            href="#"
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>
          {row.status === 'disabled' &&<DropdownItem
            href="#"
            onClick={() => dispatch(smsSettingsEnable(row, get))}
          >
            <i className="mdi mdi-play-circle-outline font-size-16 text-success me-1" />{" "}
            Enable
          </DropdownItem>}
          {row.status === 'enabled' && <DropdownItem
            href="#"
            onClick={() => dispatch(smsSettingsDisable(row, get))}
          >
            <i className="mdi mdi-stop-circle-outline font-size-16 text-warning me-1" />{" "}
            Disable
          </DropdownItem>}
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Settings"
          breadcrumbItem={"Smss"}
        />
        <CardBody>
          <div className="clearfix">
            <div className="btn-group float-end ">
              <Button onClick={() => onCreate()} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Button>
            </div>
            <CardTitle>Sms Settings</CardTitle>

          </div>
        </CardBody>

        {sms_settings?.data && <DataTableList
          {...props}
          columns={columns}
          get={get}
          className={`project-list-table table-nowrap align-middle table-borderless`}
          current_page={1}
          last_page={2}
          data={sms_settings.data}
          callBack={get}
          loading={loading}
          setCurrentPage={() => {
          }}

        />}


        <SMSSettingsForm

          isOpen={form}
          toggle={toggleForm}
          sms_settings={sms}
          saveChanges={data => {
            dispatch(smsSettingsPost(data, get))
            toggleForm()

          }}
        />




      </Container>
    </div>
  </React.Fragment>
}
