import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams, useParams } from "react-router-dom"
import { Blank } from "../index"
import { branchFind } from "../../store/branches/branchActions"


export const BranchDetails = props => {
  const dispatch = useDispatch()
  const { Branch } = useSelector(state => state)
  const { branch,loading } = Branch
  const { id } = useParams()

  const get = () => {
    // console.log(id)
    dispatch(branchFind(id))
  }

  useEffect(get, [])

  return  <Blank breach={branch}
                title={false ? "Loading" : "Branch Details"} />

}
