import React from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import axios from "axios"
import Select from "react-select"
import { selectColorStyles } from "../../../components/selectColorStyle"
import { Pre } from "../../../helpers/pre"


export default class EscalationGroupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    if(!this.props.isOpen) return false
    const { escalation_group } = this.props
    axios
      .post("app/escalation/group/form", { id: escalation_group })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, escalation_group, saveChanges } = this.props
    const { loading, fields, form } = this.state
    return <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
      {form && <>
        <ModalHeader>

          {escalation_group.id && <>Edit : {escalation_group.escalation_group}</>}
          {!escalation_group.id && <>Create Escalation Group</>}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="form-department-name">Company</Label>
                <select
                  value={fields?.companies_id}
                  onChange={e => this.handleChange("companies_id", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-department-name"
                  placeholder=""
                >
                  <option value={0}>Select Company</option>

                  {form.companies && typeof form.companies === "object" && form.companies.map(row => <option
                    key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

                </select>
              </div>
            </Col>
            <Col sm={12}>
              {(form.branches || []).filter(row => row.companies_id == fields?.companies_id).length > 0 && <div className="mb-3">
                <Label htmlFor="form-department-branches_id">Branches</Label>
                <select
                  value={fields?.branches_id}
                  onChange={e => this.handleChange("branches_id", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-department-branches_id"
                  placeholder=""
                >
                  <option>SELECT BRANCH</option>
                  {form.branches &&
                  (form.branches.filter(row => row.companies_id == fields?.companies_id)).map(row =>
                    <option key={`company-${row.id}`} value={row.id}>{row.branch_name}</option>)}
                </select>
              </div>}
            </Col>
            <Col sm={12}>
              {(form.departments || []).filter(row =>
               ( row.branches_id == fields?.branches_id).length > 0 ||
                (row.companies_id == fields?.companies_id).length > 0 )

              && <div className="mb-3">
                <Label htmlFor="form-department-branches_id">Department</Label>
                <select
                  value={fields?.departments_id}
                  onChange={e => this.handleChange("departments_id", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-department-departments_id"
                  placeholder=""
                >
                  <option>SELECT DEPARTMENT</option>
                  {
                  ((form.departments || []).filter(row => (row.companies_id == fields?.companies_id || row.branches_id === fields?.branches_id))).map(row =>
                    <option key={`departments-${row.id}`} value={row.id}>{row.department_name}</option>)}

                </select>
              </div>}
            </Col>
          </Row>

          <div className="mb-3">
            <Label htmlFor="form-escalation_group-name">Escalation Group</Label>
            <Input
              value={fields?.group_name}
              onChange={e => this.handleChange("group_name", e.target.value)}
              type="text"
              className="form-control"
              id="form-escalation_group-name"
              placeholder=""
            />
          </div>
          {(form.escalation_levels || []).map((row, index) => {
            return <Row key={`escalation-levels-${row.id}`}>

              <Col sm={(index !== form.escalation_levels.length -1 ) ? 8 : 12}>
                <div className="mb-3">
                  <Label htmlFor={`escalation-group-${index}`}>{row.escalation_level}</Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={form.users}
                    value={typeof fields?.escalation_levels[index]?.users !== 'undefined' ? fields?.escalation_levels[index]?.users : []}
                    isMulti
                    clearable
                    onChange={e => {
                      let { fields } = this.state
                      fields.escalation_levels[index].users = e
                      fields.escalation_levels[index].escalation_group_level = row.id
                      this.setState({ fields })
                    }}

                    id={`escalation-group-${index}`}
                    placeholder="Select Users"
                    styles={selectColorStyles}
                  />
                </div>
              </Col>
              {(index !== form.escalation_levels.length -1 ) && <Col sm={4}>
                <div className="mb-3">
                  <Label htmlFor="form-escalation_group-name">TAT</Label>
                  <Input
                    value={fields?.escalation_levels[index]?.tat}
                    onChange={e => {
                      let { fields } = this.state
                      fields.escalation_levels[index].tat = e.target.value
                      fields.escalation_levels[index].escalation_group_level = row.id

                      this.setState({ fields })
                    }}
                    type="text"
                    className="form-control"
                    id="form-escalation_group-name"
                    placeholder=""
                  />
                </div>
              </Col>}

            </Row>
          })}
        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </>}
    </Modal>
  }


}
