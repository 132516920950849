import React, { useEffect, useState } from "react"
import {
  Button,
  CardBody, CardTitle,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { EscalationLevels } from "./escalationLevels"
import { useDispatch, useSelector } from "react-redux"
import {
  escalationGroupDelete,
  escalationGroupGet,
  escalationGroupPost
} from "../../store/escalationGroups/escalationGroupActions"
import { DataTableList } from "../../components/tables/dataTable"
import { Link } from "react-router-dom"
import EscalationGroupForm from "./form/escalationGroupForm"
import { EscalationGroupModal } from "./escalationGroup"
import toastr from "toastr"

export const EscalationGroups = props => {
  const dispatch = useDispatch()
  const { EscalationGroup } = useSelector(state => state)
  const { escalation_groups, loading, escalation_group, error } = EscalationGroup

  const [form, setForm] = useState(false)
  const [show, setShow] = useState(false)
  const [group, setGroup] = useState(false)

  const toggleForm = () => setForm(!form)

  const onCreate = () => {
    setGroup(false)
    toggleForm()
  }

  const onEdit = (group) => {
    setGroup(group)
    toggleForm()
  }

  const onDelete = (group) => {
    setGroup(group)
    dispatch(escalationGroupDelete(group.id, get))
  }


  const get = () => dispatch(escalationGroupGet())

  useEffect(get, [escalation_group])


  const columns = [
    // {
    //   name: "#",
    //   selector: "id",
    //   style: { width: 60, alignText: "center", fontWeight: "bold" },
    //   cell: (row, index) => index + 1
    // },
    {
      name: "Name",
      selector: "group_name",
      cell: (row) => <>
        <div>

          <Link onClick={() => {
            setGroup(row.id)
            setShow(true)
          }}><strong>{row.group_name}</strong></Link></div>
      </>
    },
    {
      name: "Company",
      selector: "company_name",
      cell: ({ company_name }) => company_name
    },
    {
      name: "Branch",
      selector: "branch_name",
      cell: ({ branch_name }) => branch_name
    },
    {
      name: "Departments",
      selector: "department_name",
      cell: ({ department_name }) => department_name
    },

    {
      name: "",
      selector: "id",
      className: "flex-end",
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          className="card-drop"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            onClick={() =>onEdit(row.id)

              // onEdit(row)

            }
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Escalation "
          breadcrumbItem={"Groups"}
        />


        <CardBody>
        <div className="clearfix">
          <div className="btn-group float-end ">
            <Button onClick={() => onCreate()} className="btn btn-primary btn-soft-primary">
              <i className="mdi mdi-plus font-size-18"></i>
              Create
            </Button>
          </div>
          <CardTitle>Escalation Groups</CardTitle>

        </div>
        </CardBody>

        {escalation_groups?.data && <DataTableList
          {...props}
          columns={columns}
          get={get}
          className={`project-list-table table-nowrap align-middle table-borderless`}
          current_page={1}
          last_page={2}
          data={escalation_groups.data}
          callBack={get}
          loading={loading}
          setCurrentPage={() => {
          }}
        />}

        {!loading && <EscalationGroupForm
          isOpen={form}
          toggle={toggleForm}
          escalation_group={group}
          saveChanges={data => {
            toggleForm()
            dispatch(escalationGroupPost(data, get))

          }} />}

          <EscalationGroupModal isOpen={show} toggle={() => setShow(!show)} id={group} />


        <EscalationLevels {...props} />

      </Container>
    </div>
  </React.Fragment>
}
