import {
  DEPARTMENT_GET_REQUEST,
  DEPARTMENT_GET_SUCCESS,
  DEPARTMENT_GET_FAILURE,
  DEPARTMENT_FIND_REQUEST,
  DEPARTMENT_FIND_SUCCESS,
  DEPARTMENT_FIND_FAILURE,
  DEPARTMENT_POST_REQUEST,
  DEPARTMENT_POST_SUCCESS,
  DEPARTMENT_POST_FAILURE,
  DEPARTMENT_ACTION_REQUEST,
  DEPARTMENT_ACTION_SUCCESS,
  DEPARTMENT_ACTION_FAILURE

} from "./departmentTypes"


const init = {
  loading: false,
  departments : false,
  department: false,
  error : ""
}

const Department = (state = init, action) => {
  switch(action.type) {
    case DEPARTMENT_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case DEPARTMENT_GET_SUCCESS : {return {...state, loading: false, departments: action.payload, error: ""}}
    case DEPARTMENT_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case DEPARTMENT_FIND_REQUEST : {return {...state, loading: true, error: "", department: false}}
    case DEPARTMENT_FIND_SUCCESS : {return {...state, loading: false, department: action.payload, error: ""}}
    case DEPARTMENT_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case DEPARTMENT_POST_REQUEST : {return {...state, loading: true, error: ""}}
    case DEPARTMENT_POST_SUCCESS : {return {...state, loading: false, error: ""}}
    case DEPARTMENT_POST_FAILURE : {return {...state, loading: false, error: action.payload}}

    case DEPARTMENT_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case DEPARTMENT_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case DEPARTMENT_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default Department;
