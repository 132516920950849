import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import React, { useState } from "react"
import { exportDataToExcel } from "../../utility/download"


export const DropDownButtonDownload = ({data,title, filename}) => {
  const [singlebtn1, setSinglebtn1] = useState(false);

  return  <Dropdown
    isOpen={singlebtn1}
    toggle={() => setSinglebtn1(!singlebtn1)}
    className="mt-4 mt-sm-0"
  >
    <DropdownToggle className="btn btn-soft-primary" caret>
      Dropdown Report <i className="mdi mdi-chevron-down" />
    </DropdownToggle>
    <DropdownMenu>
      {/*<DropdownItem>PDF</DropdownItem>*/}
      <DropdownItem onClick={() => exportDataToExcel(filename, title, data)      }>CSV</DropdownItem>
    </DropdownMenu>
  </Dropdown>
}
