import React, { useEffect, useState } from "react"
import { CardBody, Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import { companyGet } from "../../store/companies/companyActions"
import { branchGet } from "../../store/branches/branchActions"
import { departmentGet } from "../../store/departments/depatmentActions"
import { useDispatch, useSelector } from "react-redux"
import { filterGet } from "../../store/filters/filterReducer"

const years = [
  { label: 2024, value: 2024 },
  { label: 2023, value: 2023 },
  { label: 2022, value: 2022 },
  { label: 2021, value: 2021 },
  { label: 2020, value: 2020 },
  { label: 2019, value: 2019 }
]

export const ReviewFilters = props => {

  const dispatch = useDispatch()
  const { loading, filters } = useSelector(state => ({
    filters: state.Filter.filters,
    loading: state.Filter.loading
  }))

  useEffect(() => {
    dispatch(filterGet())
  }, [])


  const [company, setCompany] = useState()
  const [department, setDepartment] = useState()
  const [branch, setBranch] = useState()
  const [year, setYear] = useState(2024)
  const [complaint, setComplaint] = useState()
  const [sentiment, setSentiment] = useState()
  const [search, setSearch] = useState("")
  const [date, setDate] = useState();
  const { size } = props

  useEffect(() => {
    console.log({ date })

    const filter = new Object()
    if (company) filter.company = company.value
    if (branch) filter.branch = branch.value
    if (department) filter.department = department.value
    // if (year) filter.year = year.value
    if (complaint) filter.complaint = complaint.value
    if (sentiment) filter.sentiment = sentiment.value
    if (search) filter.search = search;
    if (date) filter.date = date;


    props.onChange(filter);

  }, [company, date, department, branch, complaint, search, sentiment])


  return <Row>
    {props.company && filters && filters.companies && <Col sm={size ? size : 4}>
      <FormGroup>
        <label>Company</label>
        <Select isClearable onChange={(e) => setCompany(e)} options={filters.companies} />
      </FormGroup>
    </Col>}
    {props.branch && filters &&
    <Col sm={size ? size : 4}>
      <FormGroup>
        <label>Branches</label>
        <Select isClearable onChange={(e) => setBranch(e)}
                options={filters.branches} />
      </FormGroup>
    </Col>}
    {props.department && filters && filters.departments && <Col sm={size ? size : 4}>
      <FormGroup>
        <label>Departments</label>
        <Select isClearable onChange={(e) => setDepartment(e)}
                options={filters.departments
                  // .filter(row => (branch && row.branches_id == branch.id) || !branch)
                } />
      </FormGroup>
    </Col>}

    {props.date && <Col sm={size ? size : 4}>
      <FormGroup className="mb-4">
        <Label>Dates Filter</Label>
        <InputGroup>
          <Flatpickr
            value={date}
            className="form-control d-block"
            placeholder="dd M,yyyy"
            onChange={setDate}
            options={{
              mode: "range",
              dateFormat: "Y-m-d"
            }}
          />
        </InputGroup>
      </FormGroup>
    </Col>}
    {props.sentiment && <Col sm={size ? size : 4}>
      <FormGroup>
        <label>Sentiment</label>
        <Select isClearable onChange={(e) => setSentiment(e)}
                options={[
                  { label: "Good", value: "Good" },
                  { label: "Neutral", value: "Neutral" },
                  { label: "Poor", value: "Poor" }
                ]} />
      </FormGroup>
    </Col>}
    {props.year && <Col sm={size ? size : 4}>
      <FormGroup>
        <label>Years</label>
        <Select isClearable onChange={(e) => setYear(e)}
                options={years} value={year} />
      </FormGroup>
    </Col>}
    {props.search && <Col sm={size ? size : 4}>
      <FormGroup className="mb-4">
        <Label>Search</Label>
        <Input placeholder="search" value={search} onChange={e => setSearch(e.target.value)} />
      </FormGroup>
    </Col>}
    {props.complaint_status && <Col sm={size ? size : 4}>
      <FormGroup>
        <label>Complaint Status</label>
        <Select isClearable onChange={(e) => setComplaint(e)}
                options={[
                  { label: "Open", value: "open" },
                  { label: "Closed", value: "closed" },
                  { label: "In Progress", value: "in progress" }
                ]} />
      </FormGroup>
    </Col>}
  </Row>
}
