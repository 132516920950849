import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const Line = ({dataColors}) => {
  const lineEChartColors = getChartColorsArray(dataColors);

  const options = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: ["Mar","Apr","May","Jun","Jul","Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb"],
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#8791af"
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)"
        }
      }
    },
    series: [
      {
        data: [280,400,510,695,765,820, 932, 901, 934, 1290, 1330, 1320],
        type: "line",
        color: "green"
      },
      {
        data: [100,240,390,456,523,609, 799, 820, 934, 1190, 1230, 1400],
        type: "line",
        color: "blue"
      },
    ],
    color: lineEChartColors,
    textStyle: {
      color: ["#8791af"],
    },
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default Line;
