import axios from "axios"

export const COMPLAINTS_GET_REQUEST = "COMPLAINTS_GET_REQUEST"
export const COMPLAINTS_GET_SUCCESS = "COMPLAINTS_GET_SUCCESS"
export const COMPLAINTS_GET_FAILURE = "COMPLAINTS_GET_FAILURE"

export const COMPLAINTS_REPORT_REQUEST = "COMPLAINTS_REPORT_REQUEST"
export const COMPLAINTS_REPORT_SUCCESS = "COMPLAINTS_REPORT_SUCCESS"
export const COMPLAINTS_REPORT_FAILURE = "COMPLAINTS_REPORT_FAILURE"

export const COMPLAINTS_DATA_REQUEST = "COMPLAINTS_DATA_REQUEST"
export const COMPLAINTS_DATA_SUCCESS = "COMPLAINTS_DATA_SUCCESS"
export const COMPLAINTS_DATA_FAILURE = "COMPLAINTS_DATA_FAILURE"

export const COMPLAINTS_FIND_REQUEST = "COMPLAINTS_FIND_REQUEST"
export const COMPLAINTS_FIND_SUCCESS = "COMPLAINTS_FIND_SUCCESS"
export const COMPLAINTS_FIND_FAILURE = "COMPLAINTS_FIND_FAILURE"

export const COMPLAINTS_ACTION_REQUEST = "COMPLAINTS_ACTION_REQUEST"
export const COMPLAINTS_ACTION_SUCCESS = "COMPLAINTS_ACTION_SUCCESS"
export const COMPLAINTS_ACTION_FAILURE = "COMPLAINTS_ACTION_FAILURE"

const init = {
  complaint: false,
  complaints: [],
  rating: false,
  loading: false,
  report: false,
  data: [],
}

export const Complaints = (state = init, action) => {

  switch (action.type) {
    case COMPLAINTS_GET_REQUEST : {
      return { ...state, loading: true }
    }
    case COMPLAINTS_GET_SUCCESS : {
      return { ...state, loading: false, complaints: action.payload }
    }
    case COMPLAINTS_GET_FAILURE : {
      return { ...state, loading: false }
    }

    case COMPLAINTS_REPORT_REQUEST : {
      return { ...state, loading: true }
    }
    case COMPLAINTS_REPORT_SUCCESS : {
      return { ...state, loading: false, report: action.payload }
    }
    case COMPLAINTS_REPORT_FAILURE : {
      return { ...state, loading: false }
    }
    case COMPLAINTS_DATA_REQUEST : {
      return { ...state, loading: true }
    }
    case COMPLAINTS_DATA_SUCCESS : {
      return { ...state, loading: false, data: action.payload }
    }
    case COMPLAINTS_DATA_FAILURE : {
      return { ...state, loading: false }
    }

    case COMPLAINTS_ACTION_REQUEST : {
      return { ...state, loading: true }
    }
    case COMPLAINTS_ACTION_SUCCESS : {
      return { ...state, loading: false }
    }
    case COMPLAINTS_ACTION_FAILURE : {
      return { ...state, loading: false }
    }

    case COMPLAINTS_FIND_REQUEST : {
      return { ...state, loading: true }
    }
    case COMPLAINTS_FIND_SUCCESS : {
      return { ...state, loading: false, complaint: action.payload }
    }
    case COMPLAINTS_FIND_FAILURE : {
      return { ...state, loading: false }
    }


    default : {
      return { ...state }
    }
  }
}


export const complaintsGet = (filters) => dispatch => {
  dispatch({ type: COMPLAINTS_GET_REQUEST })
  axios.get("app/complaints/get", {params: filters})
    .then(({ data }) => dispatch({ type: COMPLAINTS_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_GET_FAILURE, payload: message }))
}

export const complaintsChart = () => dispatch => {
  dispatch({ type: COMPLAINTS_REPORT_REQUEST })
  axios.get("app/complaints/report")
    .then(({ data }) => dispatch({ type: COMPLAINTS_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_REPORT_FAILURE, payload: message }))

}


export const complaintsData = (filter) => dispatch => {
  dispatch({ type: COMPLAINTS_DATA_REQUEST })
  axios.get("app/complaints/data", {params: filter})
    .then(({ data }) => dispatch({ type: COMPLAINTS_DATA_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_DATA_FAILURE, payload: message }))

}

export const complaintsFind = (id) => dispatch => {
  dispatch({ type: COMPLAINTS_FIND_REQUEST })
  axios.get(`app/complaints/get/${id}`)
    .then(({ data }) => dispatch({ type: COMPLAINTS_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_FIND_FAILURE, payload: message }))

}
export const complaintResolve = ({ complaint, resolution_comment }, callBack) => dispatch => {
  dispatch({ type: COMPLAINTS_ACTION_REQUEST })
  axios.post(`app/complaints/resolve`, { complaint, resolution_comment })
    .then(({ data }) => dispatch(complaintsGet()))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_ACTION_FAILURE, payload: message }))
    .finally(callBack)

}

export const complaintTag = ({complaint, user}) => dispatch => {
  dispatch({ type: COMPLAINTS_ACTION_REQUEST })
  axios.post(`app/complaints/tag`, { complaint, user })
    .then(({ data }) => dispatch(complaintsGet()))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_ACTION_FAILURE, payload: message }))
}

export const complaintComment = ({ complaint, users, comment }, callBack) => dispatch => {
  dispatch({ type: COMPLAINTS_ACTION_REQUEST })
  axios.post(`app/complaints/comment`, { complaint, users, comment })
    .then(({ data }) => dispatch(complaintsFind(complaint)))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_ACTION_FAILURE, payload: message }))
    .finally(() => {
      callBack()
    })
}



