export const COMPANY_GET_REQUEST = 'COMPANY_GET_REQUEST';
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS';
export const COMPANY_GET_FAILURE = 'COMPANY_GET_FAILURE';

export const COMPANY_FIND_REQUEST = 'COMPANY_FIND_REQUEST';
export const COMPANY_FIND_SUCCESS = 'COMPANY_FIND_SUCCESS';
export const COMPANY_FIND_FAILURE = 'COMPANY_FIND_FAILURE';

export const COMPANY_POST_REQUEST = 'COMPANY_POST_REQUEST';
export const COMPANY_POST_SUCCESS = 'COMPANY_POST_SUCCESS';
export const COMPANY_POST_FAILURE = 'COMPANY_POST_FAILURE';

export const COMPANY_ACTION_REQUEST = 'COMPANY_ACTION_REQUEST';
export const COMPANY_ACTION_SUCCESS = 'COMPANY_ACTION_SUCCESS';
export const COMPANY_ACTION_FAILURE = 'COMPANY_ACTION_FAILURE';

