import {
  FEEDBACK_GET_REQUEST,
  FEEDBACK_GET_SUCCESS,
  FEEDBACK_GET_FAILURE,
  FEEDBACK_FIND_REQUEST,
  FEEDBACK_FIND_SUCCESS,
  FEEDBACK_FIND_FAILURE,
  FEEDBACK_POST_REQUEST,
  FEEDBACK_POST_SUCCESS,
  FEEDBACK_POST_FAILURE,
  FEEDBACK_ACTION_REQUEST,
  FEEDBACK_ACTION_SUCCESS,
  FEEDBACK_ACTION_FAILURE, FEEDBACK_FORM_REQUEST, FEEDBACK_FORM_SUCCESS, FEEDBACK_FORM_FAILURE

} from "./feedbackTypes"
import axios from "axios"
import { feedbackComplaintsGet } from "../complaints/feedbackComplaintsReducer"


export const feedbackGet = () => dispatch => {
  dispatch({ type: FEEDBACK_GET_REQUEST })
  axios.get("/app/feedbacks/get")
    .then(({ data }) => dispatch({ type: FEEDBACK_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: FEEDBACK_GET_FAILURE, payload: message }))
}

export const feedbackFind = (id) => dispatch => {
  dispatch({ type: FEEDBACK_FIND_REQUEST })
  axios.get(`/feedbacks/get/${id}`)
    .then(({ data }) => dispatch({ type: FEEDBACK_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: FEEDBACK_FIND_FAILURE, payload: message }))
}

export const feedbackDelete = (id, callBack) => dispatch => {
  dispatch({ type: FEEDBACK_ACTION_REQUEST })
  axios.post(`/app/feedbacks/delete`, { id })
    .then(({ data }) => {
      dispatch({ type: FEEDBACK_ACTION_SUCCESS, payload: data })
      dispatch(feedbackGet())

    })
    .catch(({ message }) => dispatch({ type: FEEDBACK_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}
export const feedbackForm = (id, callBack) => dispatch => {
  dispatch({ type: FEEDBACK_FORM_REQUEST })
  axios.post(`/feedbacks/form`, { id })
    .then(({ data }) => dispatch({ type: FEEDBACK_FORM_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: FEEDBACK_FORM_FAILURE, payload: message }))
    .finally(() => callBack(data))
}

export const feedbackPost = (data, callBack) => dispatch => {
  dispatch({ type: FEEDBACK_POST_REQUEST })
  axios.post(`/feedbacks/submit`, data)
    .then(({ data }) => {
      dispatch({ type: FEEDBACK_POST_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(feedbackGet())
        dispatch(feedbackFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: FEEDBACK_POST_FAILURE, payload: message }))
}

export const customerFeedbackInfoSubmit = ({ name, email, phone, feedback_form }) => {
  return axios.post("app/feedbacks/form/submit", { name, email, phone, feedback_form })
}

export const feedbackFormSubmit = ({ response, name, email, phone, callBack, feedback_form_id }) => dispatch => {
  dispatch({ type: FEEDBACK_ACTION_REQUEST })
  axios.post(`form/submit`, { response, name, email, phone, feedback_form_id })
    .then(({ data }) => {
      dispatch({ type: FEEDBACK_ACTION_SUCCESS, payload: data })
      dispatch(feedbackGet())
    })
    .catch(({ message }) => dispatch({ type: FEEDBACK_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const customerFeedbackResolve = ({ id, resolution_reasons }) => dispatch  =>{
  dispatch({ type: FEEDBACK_ACTION_REQUEST })
  axios.post(`app/feedbacks/resolve/resolve`, { id, resolution_reasons })
    .then(({ data }) => {
      dispatch({ type: FEEDBACK_ACTION_SUCCESS, payload: data })
      dispatch(feedbackComplaintsGet({}))
    })
    .catch(({ message }) => dispatch({ type: FEEDBACK_ACTION_FAILURE, payload: message }))
    // .finally(callBack)
}

export const customerFeedbackChatSubmit = ({id, chat}, refresh) => dispatch => {
  dispatch({ type: FEEDBACK_ACTION_REQUEST })
  axios.post(`app/feedbacks/resolve/comment`, { id, chat })
    .then(({ data }) => {
      dispatch({ type: FEEDBACK_ACTION_SUCCESS, payload: data })
      dispatch(feedbackComplaintsGet({}))
    })
    .catch(({ message }) => dispatch({ type: FEEDBACK_ACTION_FAILURE, payload: message }))
    .finally(() => refresh && refresh())
}

export const customerFeedbackAssignManager = ({id, user}, callBack) => dispatch => {

  dispatch({ type: FEEDBACK_ACTION_REQUEST })
  axios.post(`app/feedbacks/resolve/tag`, { id, user })
    .then(({ data }) => {
      dispatch({ type: FEEDBACK_ACTION_SUCCESS, payload: data })
      dispatch(feedbackComplaintsGet({}))
    })
    .catch(({ message }) => dispatch({ type: FEEDBACK_ACTION_FAILURE, payload: message }))
    .finally(() => refresh && refresh())
}
