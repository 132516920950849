import {
  BRANCH_GET_REQUEST,
  BRANCH_GET_SUCCESS,
  BRANCH_GET_FAILURE,
  BRANCH_FIND_REQUEST,
  BRANCH_FIND_SUCCESS,
  BRANCH_FIND_FAILURE,
  BRANCH_POST_REQUEST,
  BRANCH_POST_SUCCESS,
  BRANCH_POST_FAILURE,
  BRANCH_ACTION_REQUEST,
  BRANCH_ACTION_SUCCESS,
  BRANCH_ACTION_FAILURE

} from "./branchTypes"
import axios from "axios"
import { companyGet } from "../companies/companyActions"


export const branchGet = () => dispatch => {
  dispatch({ type: BRANCH_GET_REQUEST })
  axios.get("/app/branches/get")
    .then(({ data }) => dispatch({ type: BRANCH_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: BRANCH_GET_FAILURE, payload: message }))
}

export const branchFind = (id) => dispatch => {
  dispatch({ type: BRANCH_FIND_REQUEST })
  axios.get(`/app/branches/get/${id}`)
    .then(({ data }) => dispatch({ type: BRANCH_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: BRANCH_FIND_FAILURE, payload: message }))
}

export const branchDelete = (id, callBack) => dispatch => {
  dispatch({ type: BRANCH_ACTION_REQUEST })
  axios.post(`/app/branches/delete`, {id})
    .then(({ data }) => {
      dispatch({ type: BRANCH_ACTION_SUCCESS, payload: data })
      dispatch(branchGet())
    })
    .catch(({ message }) => dispatch({ type: BRANCH_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const branchPost = (data, callBack) => dispatch => {
  dispatch({ type: BRANCH_POST_REQUEST })
  axios.post(`/app/branches/submit`, data)
    .then(({ data }) => {
      dispatch({ type: BRANCH_POST_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(branchGet())
        dispatch(branchFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: BRANCH_POST_FAILURE, payload: message }))
}


export const branchActivate = (id, callBack) => dispatch => {
  dispatch({ type: BRANCH_ACTION_REQUEST })
  axios.post(`app/branches/activate/`, { id })
    .then(({ data }) => {
      dispatch({ type: BRANCH_ACTION_SUCCESS, payload: data })
      dispatch(branchGet())

    })
    .catch(({ message }) => dispatch({ type: BRANCH_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const branchDeactivate = (id, callBack) => dispatch => {
  dispatch({ type: BRANCH_ACTION_REQUEST })
  axios.post(`app/branches/deactivate/`, { id })
    .then(({ data }) => {
      dispatch({ type: BRANCH_ACTION_SUCCESS, payload: data })
      dispatch(branchGet())

    })
    .catch(({ message }) => dispatch({ type: BRANCH_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const branchSuspend = (id, callBack) => dispatch => {
  dispatch({ type: BRANCH_ACTION_REQUEST })
  axios.post(`app/branches/suspend/`, { id })
    .then(({ data }) => {
      dispatch({ type: BRANCH_ACTION_SUCCESS, payload: data })
      dispatch(branchGet())

    })
    .catch(({ message }) => dispatch({ type: BRANCH_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}



export const branchPatch = (data, callBack) => dispatch => {

}

export const branchDuplicate = (row, callBack) => dispatch => {

}

