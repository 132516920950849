import React, { useEffect } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { feedbackFind } from "../../store/feedback/feedbackActions"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Container,
  Spinner
  , Form
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Preview } from "./preview"


export const Feedback = props => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { Feedback } = useSelector(state => state)
  const { loading, feedback } = Feedback
  const dispatch = useDispatch()
  const get = () => dispatch(feedbackFind(id))

  useEffect(() => get(), [])


  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem={feedback && feedback.form_name}
        />

        {feedback && <Card>
          <CardBody>
            <>
              <ButtonGroup className="float-end">
                <Link className="btn btn-success" color="default" to={`/form/${id}`}>Preview</Link>
                <Button outline color="success" onClick={() => navigate(`/feedback/form/${id}`)}>Publish</Button>
                <Button outline color="info" onClick={() => navigate(`/feedback/form/${id}`)}>Edit</Button>
                <Button outline color="danger" onClick={() => navigate(`/feedback/form/${id}`)}>Delete</Button>
              </ButtonGroup>
              <CardTitle>
                {feedback && feedback.form_name}
                {loading && <Spinner color="primary" size="sm" />}

              </CardTitle>
            </>
          </CardBody>
          <Form onSubmit={e => {


            e.preventDefault()
          }}>
            <Preview {...props} />
          </Form>

        </Card>

        }
      </Container>
    </div>
  </React.Fragment>


}
