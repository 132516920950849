export const actionStatus = {
  "Created": { title: "Contract Created", color: "primary", icon: "bx bx-add-to-queue" },
  "Execution Requested": { title: "Execution Requested", color: "info", icon: "bx bx-share" },
  "Execution Rejected": { title: "Execution Requested", color: "danger", icon: "bx bx-error-alt" },
  "Executed By Signing": { title: "Executed By Signing", color: "success", icon: "bx bxs-pen" },
  "Executed By Sealing": { title: "Executed By Signing", color: "success", icon: "bx bx-badge-check" },
  "Verification Requested": { title: "Verification Requested", color: "info", icon: "bx bx-share" },
  "Verification Rejected": { title: "Verification Rejected", color: "danger", icon: "bx bx-error-alt" },
  "Verified": { title: "Verified", color: "success", icon: "bx bx-check-double" },
  "Termination Requested": { title: "Termination Requested", color: "warning", icon: "bx bx-error-alt" },
  "Termination Rejected": { title: "Termination Rejected", color: "success", icon: "bx bx-x" },
  "Termination Approved": { title: "Termination Approved", color: "danger", icon: "bx bx-error" },
  "Terminated": { title: "Terminated", color: "danger", icon: "bx bx-strikethrough" },
  "Amended": { title: "Amended", color: "primary", icon: "bx bx-edit" },
  "Expired": { title: "Amended", color: "danger", icon: "bx bx-edit" },
  "Renewed": { title: "Amended", color: "info", icon: "bx bx-edit" },
}

export const userStatus = {
  "active": { title: "Active", color: "success", icon: "mdi mdi-account-check" },
  "inactive": { title: "Inactive", color: "warning", icon: "mdi mdi-account-check-outline" },
  "unverified": { title: "Unverified", color: "primary", icon: "mdi mdi-account-cancel-outline" },
  "blocked": { title: "Blocked", color: "danger", icon: "mdi mdi-account-cancel-outline" },

}
