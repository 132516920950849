import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle, Col,
  Container, Row
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { userDelete, userGet, userPost } from "../../store/users/userActions"
import { Link, useParams, useNavigate } from "react-router-dom"
import { DataTableList } from "../../components/tables/dataTable"
import UserForm from "./forms/userForm"
import Select from "react-select"
import { userStatus } from "../../components/status"
import { Avatar } from "../../components/Common/Avatar"

export const Users = props => {
  const dispatch = useDispatch()
  const { loading, users, permissions, roles } = useSelector(state => ({
    permissions: state.Permissions.permissions,
    users: state.Users.users,
    loading: state.Users.loading,
    roles: state.Roles.roles
  }))

  const [user, setUser] = useState(false)
  const [form, setForm] = useState(false)
  const [role, set_role] = useState()
  const [status, set_status] = useState()


  const { page } = useParams()

  const get = () => !loading && dispatch(userGet({ page, status: status?.value, role: role?.value }))


  const onCreate = () => {
    setUser(0)
    setForm(true)
  }


  const toggleForm = () => {
    setUser(false)
    setForm(false)
  }


  const canCreate = true // (permissions || []).filter(({ name }) => ["EDIT USER", "CREATE USER"].includes(name)).length > 0

  document.title = `${typeof props.employees !== "undefined" ? "Employees" : "Users"} | CMS`

  useEffect(() => get(), [page, status, role])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : `${typeof props.employees !== "undefined" ? "Employees" : "Users"}`}
          breadcrumbItem={typeof props.employees !== "undefined" ? "Employees" : "Users"}
        />
        <Card className="bg-transparent">
          <CardBody className="bg-white">
            <div className="clearfix mb-3">
              <div className="btn-group float-end">
                {canCreate && <Button onClick={onCreate} className="btn btn-primary btn-soft-primary">
                  <i className="mdi mdi-plus font-size-18"></i>
                  Create
                </Button>}
              </div>
              <CardTitle>Users </CardTitle>
            </div>
            <Row>

              {/*<Col sm={3}>*/}
              {/*  <Select placeholder="Filter Companies" options={companies} />*/}
              {/*</Col>*/}
              {/*<Col sm={3}>*/}
              {/*  <Select placeholder="Filter Companies" options={companies} />*/}
              {/*</Col>*/}
              <Col sm={3}>
                <Select isClearable placeholder="Filter Roles" onChange={set_role} options={users?.roles} />
              </Col>
              <Col sm={3}>
                <Select isClearable placeholder="Filter Status" onChange={set_status} options={users?.status} />
              </Col>
            </Row>
          </CardBody>

          {users && users.data && users.data.length > 0 && <UsersList
            {...users}
            get={get}
            data={users?.data}
            loading={loading}
          />}

        </Card>
        <UserForm toggle={toggleForm}
                  sourceLoading={props.loading}
                  isOpen={form}
                  user={user}
                  saveChanges={data => {
                    setForm(false)
                    dispatch(userPost(data, get))
                  }} />
      </Container>
    </div>

  </React.Fragment>
}


export const UsersList = props => {

  const dispatch = useDispatch()

  const onDelete = item => dispatch(userDelete(item.id, navigate(`/admin/users`)))

  const [user, setUser] = useState(false)
  const [form, setForm] = useState(false)


  const { get } = props

  const onEdit = (user) => {
    setUser(user.id)
    setForm(true)
  }


  const toggleForm = () => {
    setUser(false)
    setForm(false)
  }

  const columns = [
    {
      name: "#",
      selector: "id",
      style: { width: "40px" },
      cell: (row) => <Avatar user={row} height={40} className="rounded-circle" />
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => <>
        <div>
          <Link to={`/user/${row.id}`}><strong>{row.name}</strong></Link></div>
        <div>
          <><span>{row.email}</span></>
        </div>
      </>
    },

    {
      name: "Roles",
      selector: "roles",
      cell: ({ id, roles }) => <>
        <div>
          {(roles || []).map((role, index) => {
            if (index > 1) return null
            return <span className="badge badge-soft-primary m-1" key={`user-${id}-${role.id}`}
                         to={`tel:${role.name}`}>
            <strong>{role.name}</strong>
          </span>
          })}
          {(roles || []).length > 2 && `+${roles.length - 2} more`}
        </div>

      </>
    },
    {
      name: "Company",
      selector: "company",
      style: {},
      cell: ({ company, branch, department }) => <div>
        {company && <Link>
          <strong>{company?.company_name}</strong>
        </Link>}
        {branch && <> / <Link>
          <strong>{branch?.branch_name}</strong>
        </Link></>}
        {department && <> / <Link>
          <strong>{department?.department_name}</strong>
        </Link></>}


      </div>
    },
    {
      name: "Status",
      selector: "status",
      style: { maxWidth: "100px", textAlign: "right" },
      cell: (user) => <>
        <Badge className="mt-1" color={userStatus[user.status].color}><i
          className={userStatus[user.status].icon} /> {userStatus[user.status].title}</Badge>
      </>
    }

  ]

  const navigate = useNavigate()
  return <>
    {props.data && props.data.length > 0 && <>
      <DataTableList
        {...props}
        columns={columns}
        get={props.get}
        className={`project-list-table table-nowrap align-middle table-borderless`}
        data={props.data}
        callBack={props.get}
        loading={props.loading}
        setCurrentPage={(page) => navigate(`/settings/users/${page}`)}
      />
      <UserForm toggle={toggleForm}
                sourceLoading={props.loading}
                isOpen={form}
                user={user}
                saveChanges={data => {
                  setForm(false)
                  dispatch(userPost(data, get))
                }} />
    </>
    }
  </>

}


