import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardTitle,
  Col,
  ModalBody, ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from "reactstrap"
import Axios from "axios"
import Dropzone from "react-dropzone"
import { CircularProgressbar } from "react-circular-progressbar"
import { useDispatch, useSelector } from "react-redux"
import { fileGet } from "../../store/files/fileActions"
import { FilesTable } from "../files/filesTable"
import { formatSize } from "./formats"
import { Modal } from "reactstrap"

export class UploadFile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      visible: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.files !== this.props.files && typeof this.props.files === "object") {
      let { files } = this.state
      this.props.files?.map((file, index) => files[index] = { progress: 0, status: "pending", file })
      this.setState({ files, visible: true }, this.upload)
    }
  }

  updateVisible = (data) => {
    let flag = false
    this.state.files.map(row => {
      if (row.progress < 100) {
        flag = true
      }
    })
    if (flag === false) {
      this.props.onSuccess && this.props.onSuccess(data)
      this.setState({ visible: flag })
    }
  }

  upload = () => {

    let { files } = this.state
    const updateState = (files) => this.setState({ files })
    this.props.files.map((file, index) => {
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: function(progressEvent) {
            files[index].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            files[index].status = "uploading"
            updateState(files)
          }
        }

        const formData = new FormData()
        formData.append("file", file)
        let url = this.props.url ? this.props.url : "app/files/picture"
        Axios.post(url, formData, config)
          .then((res) => {
            files[index].progress = 100
            files[index].status = "complete"
            this.setState({ files })
            this.updateVisible(res.data)
            this.props.onSuccess && this.props.onSuccess(res.data)
          })
          .catch((e) => {
            files[index].progress = 100
            files[index].status = "error"
            this.setState({ files })
            this.updateVisible()
            this.props.onError && this.props.onError(e.message)

          })

      }
    )
  }

  render() {
    const { files, visible } = this.state

    return <Modal isOpen={visible}
                  className="modal-dialog-centered"
                  toggle={() => this.setState({ visible: false })}>
      <ModalBody>
        <div className="dropzone-previews mt-3" id="file-previews">

          <Table>

            <tbody>
            {files.map((row, index) => <tr key={index + "-file"}>
              <td style={{ width: "70px" }}><CircularProgressbar value={row.progress} text={`${row.progress}%`} /></td>
              <td>{row.file.name}
                <div>
                  <strong>{formatSize(row.file.size, 0)}</strong>
                </div>
              </td>
            </tr>)}
            </tbody>
          </Table>

        </div>
      </ModalBody>
    </Modal>
  }
}

export const UploadDropZone = ({ onUpload, callBack , autoFocus, url, message}) => {

  const ref = useRef()
  const dispatch = useDispatch()
  const { Files } = useSelector(state => state)
  const { files } = Files

  const [selectedFile, setSelectedFile] = useState(false)
  const handleAcceptedFiles = (file) => setSelectedFile(file)



  const dropFiles = () => <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>

    {({ getRootProps, getInputProps }) => (
      <div className="dropzone">
        <div className="dz-message needsclick mt-1" {...getRootProps()}>
          <input autoFocus={autoFocus} ref={ref} onChange={handleAcceptedFiles} {...getInputProps()} />
          <div className="mb-1">
            <i style={{ fontSize: 60 }} className=" opacity-25 text-muted mdi mdi-cloud-upload-outline" />
          </div>
          <p className="font-size-14 opacity-50">{message ? message : 'Drop files here or click to upload.'}</p>
        </div>
      </div>
    )}
  </Dropzone>

  return <React.Fragment>

    {dropFiles()}
    {/*<Nav tabs className="nav-tabs-custom">*/}
    {/*  <NavItem>*/}
    {/*    <NavLink style={{ cursor: "pointer" }} active={activeTab === "1"} onClick={() => toggle("1")}>*/}
    {/*      <i className="mdi mdi-view-list"></i> My Files*/}
    {/*    </NavLink>*/}
    {/*  </NavItem>*/}
    {/*  <NavItem>*/}
    {/*    <NavLink style={{ cursor: "pointer" }} active={activeTab === "2"} onClick={() => toggle("2")}>*/}
    {/*      <i className="mdi mdi-cloud-upload-outline"></i> Upload*/}
    {/*    </NavLink>*/}
    {/*  </NavItem>*/}
    {/*</Nav>*/}
    {/*<TabContent activeTab={activeTab} className="pt-3 p-3 text-muted">*/}
    {/*  <TabPane tabId="2">{dropFiles()}</TabPane>*/}
    {/*  <TabPane tabId="1"><FilesTable*/}
    {/*    data={files}*/}
    {/*    selectedFile={selectedFile}*/}
    {/*    selectAndAttach={file => {*/}
    {/*      callBack(file)*/}
    {/*    }}*/}
    {/*    setSelectedFile={(file) => {*/}
    {/*      // console.log("File", file)*/}
    {/*      // setSelectedFile(file)*/}
    {/*      callBack(file)*/}
    {/*    }} /></TabPane>*/}

    {/*</TabContent>*/}

    <UploadFile
      url={url}
      files={selectedFile}
      onSuccess={onUpload}
      onError={() => {
        dispatch(fileGet())
      }} />


  </React.Fragment>
}
