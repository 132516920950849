import {
  FILE_DELETE_FAILURE,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_GET_FAILURE,
  FILE_GET_REQUEST,
  FILE_GET_SUCCESS
} from "./fileTypes"

const INIT = {
  files: [],
  error: '',
  loading: false
}

const Files = (state = INIT, action) => {
  switch (action.type){
    case FILE_GET_REQUEST : {return {...state, loading: true, error: ''}}
    case FILE_GET_SUCCESS : {return {...state, loading: false, error: '', files : action.payload}}
    case FILE_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case FILE_DELETE_REQUEST : {return {...state, loading: true, error: ''}}
    case FILE_DELETE_SUCCESS : {return {...state, loading: false, error: '', }}
    case FILE_DELETE_FAILURE : {return {...state, loading: false, error: action.payload}}


    default : {return {...state}}
  }
}

export default Files;
