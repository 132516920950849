import {
  FEEDBACK_GET_REQUEST,
  FEEDBACK_GET_SUCCESS,
  FEEDBACK_GET_FAILURE,
  FEEDBACK_FIND_REQUEST,
  FEEDBACK_FIND_SUCCESS,
  FEEDBACK_FIND_FAILURE,
  FEEDBACK_POST_REQUEST,
  FEEDBACK_POST_SUCCESS,
  FEEDBACK_POST_FAILURE,
  FEEDBACK_ACTION_REQUEST,
  FEEDBACK_ACTION_SUCCESS,
  FEEDBACK_ACTION_FAILURE, FEEDBACK_FORM_REQUEST, FEEDBACK_FORM_SUCCESS, FEEDBACK_FORM_FAILURE

} from "./feedbackTypes"


const init = {
  loading: false,
  feedbacks : {},
  form : false,
  feedback: false,
  error : ""
}

const Feedback = (state = init, action) => {
  switch(action.type) {
    case FEEDBACK_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case FEEDBACK_GET_SUCCESS : {return {...state, loading: false, feedbacks: action.payload, error: ""}}
    case FEEDBACK_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case FEEDBACK_FIND_REQUEST : {return {...state, loading: true, error: "",}}
    case FEEDBACK_FIND_SUCCESS : {return {...state, loading: false, feedback: action.payload, error: ""}}
    case FEEDBACK_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case FEEDBACK_POST_REQUEST : {return {...state, loading: true, error: ""}}
    case FEEDBACK_POST_SUCCESS : {return {...state, loading: false,  feedback: action.payload, error: ""}}
    case FEEDBACK_POST_FAILURE : {return {...state, loading: false, error: action.payload}}

    case FEEDBACK_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case FEEDBACK_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case FEEDBACK_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}

    case FEEDBACK_FORM_REQUEST : {return {...state, loading: true, error: ""}} // todo set form to false {form: false}
    case FEEDBACK_FORM_SUCCESS : {return {...state, loading: false, form: action.payload, error: ""}}
    case FEEDBACK_FORM_FAILURE : {return {...state, loading: false, error: action.payload}}

    default : {
      return { ...state }
    }
  }

}

export default Feedback;
