import axios from "axios"

export const RATING_GET_REQUEST = 'RATING_GET_REQUEST';
export const RATING_GET_SUCCESS = 'RATING_GET_SUCCESS';
export const RATING_GET_FAILURE = 'RATING_GET_FAILURE';

export const RATING_FIND_REQUEST = 'RATING_FIND_REQUEST';
export const RATING_FIND_SUCCESS = 'RATING_FIND_SUCCESS';
export const RATING_FIND_FAILURE = 'RATING_FIND_FAILURE';

export const RATING_ALL_REQUEST = 'RATING_ALL_REQUEST';
export const RATING_ALL_SUCCESS = 'RATING_ALL_SUCCESS';
export const RATING_ALL_FAILURE = 'RATING_ALL_FAILURE';

const init = {
  all_ratings: [],
  ratings : [],
  rating: false,
  loading: false
}

export const CustomerRatings = (state = init, action) =>{

  switch (action.type){
    case RATING_GET_REQUEST : {return {...state, loading: true}}
    case RATING_GET_SUCCESS : {return {...state, loading: false, ratings: action.payload}}
    case RATING_GET_FAILURE : {return {...state, loading: false}}

    case RATING_ALL_REQUEST : {return {...state, loading: true}}
    case RATING_ALL_SUCCESS : {return {...state, loading: false, all_ratings: action.payload}}
    case RATING_ALL_FAILURE : {return {...state, loading: false}}

    case RATING_FIND_REQUEST : {return {...state, loading: true}}
    case RATING_FIND_SUCCESS : {return {...state, loading: false, rating: action.payload}}
    case RATING_FIND_FAILURE : {return {...state, loading: false}}



    default : {return {...state}}
  }
}


export const ratingsGet = (page, filters) => dispatch => {
  dispatch({type: RATING_GET_REQUEST});
  axios.get('app/ratings/get', {params: { page, ...filters }})
    .then(({data}) =>   dispatch({type: RATING_GET_SUCCESS, payload: data}))
    .catch(({message}) =>   dispatch({type: RATING_GET_FAILURE, payload: message}))

}

export const ratingsFind = (id) => dispatch => {
  dispatch({type: RATING_FIND_REQUEST});
  axios.get('app/ratings/get/id')
    .then(({data}) =>   dispatch({type: RATING_FIND_SUCCESS, payload: data}))
    .catch(({message}) =>   dispatch({type: RATING_FIND_FAILURE, payload: message}))

}
export const allRatings = (question_type, filters) => dispatch => {
  dispatch({type: RATING_ALL_REQUEST});
  axios.get(`app/ratings/all/${question_type}`, {params: filters})
    .then(({data}) =>   dispatch({type: RATING_ALL_SUCCESS, payload: data}))
    .catch(({message}) =>   dispatch({type: RATING_ALL_FAILURE, payload: message}))

}



