import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { allRatings } from "../../store/ratings/ratingReducer"
import { Badge, Card, CardBody, CardTitle, Container } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Datatable from "react-data-table-component"
import { ReviewFilters } from "../reports/filter"
import moment from "moment"
import { DownloadDocumentReport } from "../../download"

export const CsatReview = props => {

  const { CustomerRatings } = useSelector(state => state)
  const { all_ratings } = CustomerRatings
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({})

  useEffect(() => {
    console.log(filter)
    dispatch(allRatings("CSAT", filter))
  }, [filter])

  const columns = [
    { name: "#", sortable: true, maxWidth: "20px", selector: (row, index) => (index + 1) },
    {
      name: "Customer", selector: row => row.customer_name, cell: row => <div>
        <div><strong>{row.customer_name}</strong></div>
        <div>
          <span><i className="mdi mdi-email-outline" /> {row.customer_email}</span>
          <span><i className="mdi mdi-phone-outline" /> {row.customer_mobileno}</span>
        </div>
      </div>
    },
    {
      name: "Company / Branch", sortable: true, selector: row => <div>
        {row.company_name} {row.branch_name && <>{row.branch_name}</>}
      </div>
    },
    { name: "Question", sortable: true, wrap: true, selector: row => row.question },
    {
      name: "Rating",
      sortable: true,
      wrap: true,
      selector: row => <>
        <Badge
          className={`font-size-12 ${row.rating < 3 ? "bg-danger" : (Math.round(row.rating) === 3 ? "bg-warning" : "bg-success")}`}>
          {Math.round(row.rating)}
        </Badge>
        <span
          className={`font-size-12 ${row.rating < 3 ? "text-danger" : (Math.round(row.rating) === 3 ? "text-warning" : "text-success")}`}>{row.rating < 3 ? "POOR" : (row.rating == 3 ? "NEUTRAL" : "GOOD")}</span> </>
    },
    { name: "Response", sortable: true, wrap: true, selector: row => row.comments },
    { name: "Date", sortable: true, wrap: true, selector: row => moment(row.created_at).format("DD MMM YYYY") }
  ]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="CSAT"
        />
        <Card>
          <CardBody>
            <DownloadDocumentReport className="float-end" url={`/app/reviews/download/csat`} filters={filter}
                                    title={"CSAT Reviews"} />
            <CardTitle>CSAT </CardTitle>
          </CardBody>
          <CardBody>
            <ReviewFilters sentiment branch date department search size={3} onChange={setFilter} />
          </CardBody>
          <Datatable striped columns={columns} data={all_ratings} pagination />
        </Card>
      </Container>

    </div>

  </React.Fragment>


}
