import {ESCALATION_GROUP_GET_REQUEST,
  ESCALATION_GROUP_GET_SUCCESS,
  ESCALATION_GROUP_GET_FAILURE,
  ESCALATION_GROUP_FIND_REQUEST,
  ESCALATION_GROUP_FIND_SUCCESS,
  ESCALATION_GROUP_FIND_FAILURE,
  ESCALATION_GROUP_ACTION_REQUEST,
  ESCALATION_GROUP_ACTION_SUCCESS,
  ESCALATION_GROUP_ACTION_FAILURE,} from './escalationGroupTypes'



const init = {
  loading: false,
  escalation_groups : {},
  escalation_group: false,
  error : ""
}

const EscalationGroup = (state = init, action) => {
  switch(action.type) {
    case ESCALATION_GROUP_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case ESCALATION_GROUP_GET_SUCCESS : {return {...state, loading: false, escalation_groups: action.payload, error: ""}}
    case ESCALATION_GROUP_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case ESCALATION_GROUP_FIND_REQUEST : {return {...state, loading: true, error: ""}}
    case ESCALATION_GROUP_FIND_SUCCESS : {return {...state, loading: false, escalation_group: action.payload, error: ""}}
    case ESCALATION_GROUP_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case ESCALATION_GROUP_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case ESCALATION_GROUP_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case ESCALATION_GROUP_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default EscalationGroup;
