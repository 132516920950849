import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams, useParams, Link } from "react-router-dom"
import { companyFind, companyPost } from "../../store/companies/companyActions"
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,

  Nav,
  NavItem, NavLink, TabContent, TabPane, CardText
} from "reactstrap"
import { Pre } from "../../helpers/pre"
import CompanyForm from "./forms/companyForm"
import { UploadDropZone } from "../component/upload"
import BranchForm from "../branch/forms/branchForm"
import { branchPost } from "../../store/branches/branchActions"
import classnames from "classnames"
import { BranchesList } from "../branch/branches"


export const CompanyDetails = props => {
  const dispatch = useDispatch()
  const { company, loading } = useSelector(state => ({
    company: state.Company.company,
    loading: state.Company.loading
  }))
  const { id } = useParams()

  const get = () => {
    console.log(id)
    dispatch(companyFind(id))
  }

  useEffect(get, [id])

  const [logo, setLogo] = useState(false)
  const [form, setForm] = useState(false)
  const [cover, setCover] = useState(false)
  const [branchForm, setBranchForm] = useState(false)
  const [branch, setBranch] = useState(0)
  const [active, setActive] = useState("info")

  const updateLogo = (file) => {
    let data = company
    data.company_logo = file.url
    dispatch(companyPost(data, setLogo(false)))
  }
  const updateCover = (file) => {
    let data = company
    data.cover = file.url
    dispatch(companyPost(data, setCover(false)))
  }

  const toggle = () => setForm(!form)

  return <React.Fragment>
    <div className="w-100 mt-3 bg-light p-5 top-0" style={{
      height: 250,
      background: `url(${company.cover})`,
      backgroundPosition: "center ",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    }}>
      <Button style={{ marginTop: 100 }} className="btn mt-5 float-end  btn-success" onClick={() => setCover(true)}>Edit
        Cover</Button>
    </div>

    <div className={`avatar-lg d-inline-block m-3`} onClick={() => setLogo(true)}
         style={{ position: "absolute", top: 80 }}>
      {company.company_logo ?
        <img src={company.company_logo} className=" rounded-circle mt-5" width={100} height={100} /> : <Link

          className={
            " avatar-title rounded-circle bg-gradient  bg-success" +
            " text-white" +
            // typeof status[user.status] !== 'undefined' && status[user.status] +
            " font-size-14 "
          }
        >
          CN
        </Link>}
    </div>

    <Card className="mb-0">
      <CardBody>
        <ButtonGroup className="float-end">

          <Button className="btn btn-soft-danger" onClick={toggle}><i className="mdi mdi-trash-can-outline"/> Delete </Button>
          <Button className="btn btn-soft-success" onClick={toggle}><i className="mdi mdi-home-edit-outline" />Edit </Button>
          <Button className="btn btn-soft-info" onClick={() => {
            setBranch(0)
            setBranchForm(true)
          }}><i className="mdi mdi-view-grid-plus" /> Create Branch </Button>
          <Button className="btn btn-soft-info" onClick={toggle}><i className="mdi mdi-view-grid-plus" /> Create Department </Button>

        </ButtonGroup>
        <CardTitle>{company.company_name}</CardTitle>

      </CardBody>
    </Card>
    <div className=" mt-0 pt-0">

      <>

        <Card className="bg-light">
          <Nav tabs className="bg-white nav-tabs-custom nav-justified">
            <NavItem className="p-2">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: active === "info" })}
                onClick={() => setActive("info")}>
                Info
              </NavLink>
            </NavItem>
            <NavItem className="p-2">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: active === "Branches" })}
                onClick={() => setActive("Branches")}>
                Branches
              </NavLink>
            </NavItem>

            <NavItem className="p-2">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: active === "Departments" })}
                onClick={() => setActive("Departments")}>
                Departments
              </NavLink>
            </NavItem>
            <NavItem className="p-2">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: active === "Users" })}
                onClick={() => setActive("Users")}>
                Users
              </NavLink>
            </NavItem>
            <NavItem className="p-2">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: active === "data" })}
                onClick={() => setActive("data")}>
                Data
              </NavLink>
            </NavItem>

          </Nav>

          <TabContent activeTab={active} className="p-3 text-muted">
            <TabPane tabId="info">

            </TabPane>
            <TabPane tabId="Branches" className="bg-light">
              <BranchesList data={company?.branches} get={get} loading={loading} />
            </TabPane>

            <TabPane tabId="data">
              <Pre data={company} />
            </TabPane>
          </TabContent>

          <CardBody className="bg-light">


          </CardBody>

        </Card>

      </>
      <CompanyForm toggle={toggle}
                   isOpen={form}
                   company={company}
                   saveChanges={data => {
                     setForm(false)
                     dispatch(companyPost(data, props.get))
                   }
                   } />

      <Modal isOpen={logo} toggle={() => setLogo(!logo)} className="modal-dialog-centered" onUpload={updateLogo}>
        <ModalBody>
          <UploadDropZone message={"Upload Company Logo"} callBack={console.log}
                          onUpload={updateLogo} />
        </ModalBody>
      </Modal>
      <Modal isOpen={cover} toggle={() => setCover(!cover)} className="modal-dialog-centered" onUpload={updateCover}>
        <ModalBody>
          <UploadDropZone url={`app/files/upload`} message={"Upload Company Cover"} callBack={console.log}
                          onUpload={updateCover} />
        </ModalBody>
      </Modal>
      <BranchForm
        toggle={() => setBranchForm(!branchForm)}
        isOpen={branchForm}
        branch={branch}
        company={company.id}
        saveChanges={data => {
          setForm(false)
          dispatch(branchPost(data, get))
          setBranchForm(false)
        }

        } />
    </div>
  </React.Fragment>


}
