import axios from "axios"
import {
  AUTHENTICATION_LOGIN_FAILURE,
  AUTHENTICATION_LOGIN_REQUEST,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_LOGOUT_FAILURE,
  AUTHENTICATION_LOGOUT_REQUEST,
  AUTHENTICATION_LOGOUT_SUCCESS
} from "./authenticationTypes"


const setAuth = (auth) => dispatch => {
  localStorage.setItem("authUser", JSON.stringify(auth.user))
  localStorage.setItem("authToken", auth.token)
  axios.defaults.headers.common = { "Authorization": `Bearer ${auth.token}` }
  dispatch({ type: AUTHENTICATION_LOGIN_SUCCESS, payload: auth.user })
}

export const checkLoggedIn = () => dispatch => {
  const authUser = localStorage.getItem("authUser")
  const authToken = localStorage.getItem("authToken")
  if (authUser) dispatch({ type: AUTHENTICATION_LOGIN_SUCCESS, payload: JSON.parse(authUser) })
  if (authToken) axios.defaults.headers.common = { "Authorization": `Bearer ${authToken}` }
}

export const loginWithUsernamePassword = ({ email, password, remember }) => dispatch => {
  dispatch({ type: AUTHENTICATION_LOGIN_REQUEST })
  axios
    .post("auth/login", { email, password, remember })
    .then(res => dispatch(setAuth(res.data)))
    .catch(e => dispatch({ type: AUTHENTICATION_LOGIN_FAILURE, payload: e.message }))

}
export const loginWithGoogle = () => {

}
export const loginWithOffice365 = () => {

}

export const logout = (callback) => {
  localStorage.removeItem("authUser")
  localStorage.removeItem("authToken")
  dispatch({ type: AUTHENTICATION_LOGOUT_REQUEST })

  return axios
    .post("auth/logout")
    .then(res => {
      dispatch({ type: AUTHENTICATION_LOGOUT_SUCCESS, payload: null })
    })
    .catch(e => dispatch({ type: AUTHENTICATION_LOGOUT_FAILURE, payload: e }))
    .finally(() => {
      axios.defaults.headers.common = { "Authorization": null }
      callback && callback()
    })
}


