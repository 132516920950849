import React from "react"
import axios from "axios"
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import { Pre } from "../../helpers/pre"


export default class TemplateForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: false,
      fields: false,
      loading: false
    }
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isOpen !== this.props.isOpen) {
     this.init();
    }
  }

  init() {
    if(!this.props.isOpen) return false;

    this.setState({ loading: true })

    axios.post("/app/template/form", { id: this.props.id })
      .then((response) => {
        this.setState({
          form: response.data,
          fields: response.data.fields
        })
      })
      .finally(() => this.setState({ loading: false }))
  }


  handleChange(label, value) {
    let fields = this.state.fields
    fields[label] = value
    this.setState({ fields })
  }

  appendAction (label, item) {
    let fields = this.state.fields
    fields[label] = fields[label] + item
    this.setState({ fields })
  }

  render() {
    const { form, fields } = this.state
    const { isOpen, saveChanges, toggle } = this.props
    return <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
      <ModalHeader toggle={toggle}>
        Template Form
      </ModalHeader>
      <Form onSubmit={e => {
        e.preventDefault()
        saveChanges(fields)
      }}>

        <ModalBody>
          <Row>
            <Col sm={8}>
              <FormGroup>
                <Label htmlFor="template-name">Name</Label>
                <Input type="text" value={fields.name} onChange={e => this.handleChange("name", e.target.value)} />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label htmlFor="template-code">Code</Label>
                <select  onChange={e => this.handleChange("code", e.target.value)} className="form-control">
                  {(form.enums?.code || []).map(code => <option selected={code === fields.code} key={code} value={code}>{code}</option>)}
                </select>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label htmlFor="template-email">Email Template</Label>
            <Input type="textarea" rows={15} value={fields.email} onChange={e => this.handleChange("email", e.target.value)} />
            <Badge onClick={() => this.appendAction('email' , "{{user.name}}")}>from name</Badge>{" "}
            <Badge onClick={() => this.appendAction('email' , "{{company.company_name}}")}>company name</Badge>
          </FormGroup>

          {/*<FormGroup>*/}
          {/*  <Label htmlFor="template-sms">SMS Template</Label>*/}
          {/*  <Input type="textarea" rows={3} value={fields.sms} onChange={e => this.handleChange("sms", e.target.value)} />*/}
          {/*  <Badge onClick={() => this.appendAction('email' , "{{user.name}}")}>from name</Badge>{" "}*/}
          {/*  <Badge onClick={() => this.appendAction('email' , "{{company.company_name}}")}>company name</Badge>*/}
          {/*</FormGroup>*/}
          {/*<FormGroup>*/}
          {/*  <Label htmlFor="template-whatsapp">Whatsapp Template</Label>*/}
          {/*  <Input type="textarea" rows={3} value={fields.whatsapp} onChange={e => this.handleChange("whatsapp", e.target.value)} />*/}
          {/*  <Badge onClick={() => this.appendAction('email' , "{{user.name}}")}>from name</Badge>{" "}*/}
          {/*  <Badge onClick={() => this.appendAction('email' , "{{company.company_name}}")}>company name</Badge>*/}
          {/*</FormGroup>*/}


        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} className="btn btn-light">Close</Button>
          <Button type="submit" className="btn btn-soft-success">Save Changes</Button>
        </ModalFooter>


      </Form>

    </Modal>
  }


}