export const BRANCH_GET_REQUEST = 'BRANCH_GET_REQUEST';
export const BRANCH_GET_SUCCESS = 'BRANCH_GET_SUCCESS';
export const BRANCH_GET_FAILURE = 'BRANCH_GET_FAILURE';

export const BRANCH_FIND_REQUEST = 'BRANCH_FIND_REQUEST';
export const BRANCH_FIND_SUCCESS = 'BRANCH_FIND_SUCCESS';
export const BRANCH_FIND_FAILURE = 'BRANCH_FIND_FAILURE';

export const BRANCH_POST_REQUEST = 'BRANCH_POST_REQUEST';
export const BRANCH_POST_SUCCESS = 'BRANCH_POST_SUCCESS';
export const BRANCH_POST_FAILURE = 'BRANCH_POST_FAILURE';

export const BRANCH_ACTION_REQUEST = 'BRANCH_ACTION_REQUEST';
export const BRANCH_ACTION_SUCCESS = 'BRANCH_ACTION_SUCCESS';
export const BRANCH_ACTION_FAILURE = 'BRANCH_ACTION_FAILURE';

