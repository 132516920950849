import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup, Input, Label, Modal, ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { notificationsGet, profileGet, profileSubmit, settingsGet } from "../../../store/profile/profileReducer"
import { Pre } from "../../../helpers/pre"
import { UploadDropZone } from "../../component/upload"
import { Link } from "react-router-dom"


export const AccountInformation = props => {
  const dispatch = useDispatch()
  const {
    info,
    loading,
    notifications,
    settings
  } = useSelector(state => ({
    loading: state.Profile.loading,
    info: state.Profile.info,
    notifications: state.Profile.notifications,
    settings: state.Profile.settings
  }))


  const [active, setActive] = useState("info")
  const [showAvatar, setShowAvatar] = useState(false)

  const [name, set_name] = useState("")
  const [avatar, set_avatar] = useState("")
  const [email, set_email] = useState("")
  const [mobile_number, set_mobile_number] = useState("")

  const [old_password, set_old_password] = useState("")
  const [new_password, set_new_password] = useState("")
  const [confirm_password, set_confirm_password] = useState("")

  const getInfo = () => dispatch(profileGet())

  const getNotifications = () => dispatch(notificationsGet())

  const getSettings = () => dispatch(settingsGet())


  useEffect(() => {
    if (active === "info") getInfo()
    if (active === "notifications") notificationsGet()
    if (active === "settings") getSettings()


  }, [active])


  useEffect(() => {

    if (info) {
      set_avatar(info.avatar)
      set_name(info.name)
      set_email(info.email)
      set_mobile_number(info.mobile_number)
    }

  }, [loading])

  const updateAvatar = (f) => {
    setShowAvatar(false)
    set_avatar(f.url)
  }

  return <React.Fragment>

    <div className="page-content">
      <Container fluid={true}>
        <Card className="bg-transparent shadow-none">
          <Row>

            <Col md="3">
              <img alt="avatar" src={info.avatar} className="img-fluid" />

              <CardBody className="bg-white">

                <Nav pills className="flex-column">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", borderRadius: 0 }}
                      className={classnames({
                        "mb-2": true,
                        active: active === "info"
                      })}
                      onClick={() => {
                        setActive("info")
                      }}
                    >
                      Information
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", borderRadius: 0 }}
                      className={classnames({
                        "mb-2": true,
                        active: active === "password"
                      })}
                      onClick={() => {
                        setActive("password")
                      }}
                    >
                      Security
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", borderRadius: 0 }}
                      className={classnames({
                        "mb-2": true,
                        active: active === "notifications"
                      })}
                      onClick={() => {
                        setActive("notifications")
                      }}
                    >
                      Notification
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
              {/*<Pre  data={{loading, info }}/>*/}
            </Col>
            <Col md="9">
              <CardBody className="bg-white" style={{ marginLeft: 0, minHeight: "400px" }}>
                <TabContent
                  activeTab={active}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="info">


                    <CardTitle className="mb-5">Personal Information </CardTitle>

                    <Row>
                      <Col sm={4}>
                        <Link onClick={() => setShowAvatar(true)}>
                          <img alt="avatar" src={avatar} className="img-fluid" />
                        </Link>
                      </Col>
                      <Col sm={8}>
                        <FormGroup>
                          <Label htmlFor="input-name">Name</Label>
                          <Input id="input-name" name="name" value={name} onChange={e => set_name(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="input-name">Email</Label>
                          <Input id="input-email" name="email" value={email}
                                 onChange={e => set_email(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="input-name">Phone</Label>
                          <Input id="input-mobile_number" name="mobile_number" value={mobile_number}
                                 onChange={e => set_mobile_number(e.target.value)} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button onClick={() => dispatch(profileSubmit({ name, email, mobile_number, avatar }, "info"))}
                            className="btn btn-soft-primary">Save Changes</Button>
                  </TabPane>
                  <TabPane tabId="password">

                    <CardTitle className="mb-5">Security Settings </CardTitle>

                    <FormGroup>
                      <Label htmlFor="input-name">Old Password</Label>
                      <Input id="input-name" type="password" name="old_password" value={old_password}
                             onChange={e => set_old_password(e.target.value)} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="input-name">New Password</Label>
                      <Input id="input-name" type="password" name="new_password" value={new_password}
                             onChange={e => set_new_password(e.target.value)} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="input-name">Confirm Password</Label>
                      <Input id="input-name" type="password" name="confirm_password" value={confirm_password}
                             onChange={e => set_confirm_password(e.target.value)} />
                    </FormGroup>
                    <Button onClick={() => dispatch(profileSubmit({old_password, new_password, confirm_password}, 'password'))} className="btn btn-soft-primary">Update Password</Button>
                  </TabPane>
                  <TabPane tabId="notifications">
                    <CardTitle className="mb-5">Notification Settings </CardTitle>

                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch2"
                        defaultChecked
                        // onClick={e => {
                        //   email(!toggleSwitch)
                        // }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch2"
                      >
                        Email Notifications
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch2"
                        defaultChecked
                        // onClick={e => {
                        //   email(!toggleSwitch)
                        // }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch2"
                      >
                        SMS Notifications
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch2"
                        defaultChecked
                        // onClick={e => {
                        //   email(!toggleSwitch)
                        // }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch2"
                      >
                        Push Notifications
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch2"
                        defaultChecked
                        // onClick={e => {
                        //   email(!toggleSwitch)
                        // }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch2"
                      >
                        Whats-App Notifications
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch2"
                        defaultChecked
                        // onClick={e => {
                        //   email(!toggleSwitch)
                        // }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch2"
                      >
                        Telegram Notifications
                      </label>
                    </div>
                  </TabPane>


                </TabContent>
              </CardBody>
            </Col>
          </Row>
        </Card>
        <Modal isOpen={showAvatar} toggle={() => setShowAvatar(!showAvatar)} className="modal-dialog-centered"
               onUpload={updateAvatar}>
          <ModalBody>
            <UploadDropZone message={"Upload Company Logo"} callBack={console.log}
                            onUpload={updateAvatar} />
          </ModalBody>
        </Modal>
      </Container>
    </div>
  </React.Fragment>

}
