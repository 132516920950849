import {
  SMS_SETTINGS_GET_REQUEST,
  SMS_SETTINGS_GET_SUCCESS,
  SMS_SETTINGS_GET_FAILURE,
  SMS_SETTINGS_FIND_REQUEST,
  SMS_SETTINGS_FIND_SUCCESS,
  SMS_SETTINGS_FIND_FAILURE,
  SMS_SETTINGS_ACTION_REQUEST,
  SMS_SETTINGS_ACTION_SUCCESS,
  SMS_SETTINGS_ACTION_FAILURE,} from './smsSettingTypes'


import axios from "axios"


export const smsSettingsGet = () => dispatch => {
  dispatch({ type: SMS_SETTINGS_GET_REQUEST })
  axios.get("/app/settings/sms/get")
    .then(({ data }) => dispatch({ type: SMS_SETTINGS_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SMS_SETTINGS_GET_FAILURE, payload: message }))
}

export const smsSettingsFind = (id) => dispatch => {
  dispatch({ type: SMS_SETTINGS_FIND_REQUEST })
  axios.get(`/app/settings/sms/get/${id}`)
    .then(({ data }) => dispatch({ type: SMS_SETTINGS_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SMS_SETTINGS_FIND_FAILURE, payload: message }))
}

export const smsSettingsDelete = (id, callBack) => dispatch => {
  dispatch({ type: SMS_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/sms/delete/`, {id})
    .then(({ data }) => {
      dispatch({ type: SMS_SETTINGS_ACTION_SUCCESS, payload: data })
      dispatch(smsSettingsGet())

    })
    .catch(({ message }) => dispatch({ type: SMS_SETTINGS_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const smsSettingsPost = (data, callBack) => dispatch => {
  dispatch({ type: SMS_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/sms/submit`, data)
    .then(({ data }) => {
      dispatch({ type: SMS_SETTINGS_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(smsSettingsGet())
        dispatch(smsSettingsFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: SMS_SETTINGS_ACTION_FAILURE, payload: message }))
}

export const smsSettingsEnable = (data, callBack) => dispatch => {
  dispatch({ type: SMS_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/sms/enable`, data)
    .then(({ data }) => {
      dispatch({ type: SMS_SETTINGS_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(smsSettingsGet())
        dispatch(smsSettingsFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: SMS_SETTINGS_ACTION_FAILURE, payload: message }))
}

export const smsSettingsDisable = (data, callBack) => dispatch => {
  dispatch({ type: SMS_SETTINGS_ACTION_REQUEST })
  axios.post(`/app/settings/sms/disable`, data)
    .then(({ data }) => {
      dispatch({ type: SMS_SETTINGS_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(smsSettingsGet())
        dispatch(smsSettingsFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: SMS_SETTINGS_ACTION_FAILURE, payload: message }))
}
