import React from "react"

export const filesIcons = {
  "mp3": <i className="font-size-24 mdi mdi-file-music-outline"></i>,
  "mp4": <i className="font-size-24 mdi mdi-movie-outline"></i>,
  "jpg": <i className="font-size-24 mdi mdi-image-outline"></i>,
  "jpeg": <i className="font-size-24 mdi mdi-image-outline"></i>,
  "png": <i className="font-size-24 mdi mdi-image-outline"></i>,
  "bmp": <i className="font-size-24 mdi mdi-image-outline"></i>,
  "pdf": <i className="font-size-24 mdi mdi-file-pdf"></i>,
  "doc": <i className="font-size-24 mdi mdi-word"></i>,
  "docx": <i className="font-size-24 mdi mdi-word"></i>
}
