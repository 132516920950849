import React, { useEffect, useState } from "react"
import {
  Button,
  CardBody,
  CardTitle,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  emailSettingsDelete,
  emailSettingsDisable,
  emailSettingsEnable,
  emailSettingsGet, emailSettingsPost
} from "../../../store/emailSettings/emailSettingActions"
import { DataTableList } from "../../../components/tables/dataTable"
import { Link } from "react-router-dom"
import { StatusBadge } from "../../../components/general"
import EmailSettingsForm from "./emailSettingsForm"


export const EmailSettings = props => {

  const dispatch = useDispatch();

  const {EmailSettings} = useSelector(state => state);

  const {email_settings, email_setting, loading, error} = EmailSettings;

  const [email, setEmail] = useState(false);
  const [form, setForm] = useState(false);

  const toggleForm = () => setForm(!form);


  const get = () => dispatch(emailSettingsGet())

  useEffect(get, [email_setting])

  // useEffect(() => {}, email)

  const onCreate = () => {setEmail(false); setForm(true)}

  const onEdit = (email) => {setEmail(email); setForm(true)}

  const onDelete = (email) => {
    dispatch(emailSettingsDelete(email.id, get))
  }



  const columns = [
    {
      name: "#",
      selector: "id",
      style: { width: 60, alignText: "center", fontWeight: "bold" },
      cell: (row, index) => index + 1
    },
    {
      name: "Mail Server",
      selector: "mail_server",
      cell: (row) => <div>
        <div>
          <Link onClick={() => setEmail(row)}><strong>{row.mail_server}</strong></Link></div>

        <StatusBadge status={row.status}  />
      </div>
    },
    {
      name: "Company",
      selector: "company_name",
      cell: ({ company_name }) => company_name
    },
    {
      name: "Outport",
      selector: "outport",
      center: true,
      cell: ({ outport }) => outport
    },

    {
      name: "Username",
      selector: "username",
      cell: ({ username }) => username
    },

    {
      name: "Driver",
      selector: "driver",
      cell: ({ driver }) => driver
    },

    {
      name: "Encryption",
      selector: "encryption",
      cell: ({ encryption }) => encryption
    },

    {
      name: "Sender Name",
      selector: "sender_name",
      cell: ({ sender_name }) => sender_name
    },

    {
      name: "",
      selector: "id",
      className: "flex-end",
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            href="#"
            onClick={() => onEdit(row)}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>
          <DropdownItem
            href="#"
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>
          {row.status === 'disabled' &&<DropdownItem
            href="#"
            onClick={() => dispatch(emailSettingsEnable(row, get))}
          >
            <i className="mdi mdi-play-circle-outline font-size-16 text-success me-1" />{" "}
            Enable
          </DropdownItem>}
          {row.status === 'enabled' && <DropdownItem
            href="#"
            onClick={() => dispatch(emailSettingsDisable(row, get))}
          >
            <i className="mdi mdi-stop-circle-outline font-size-16 text-warning me-1" />{" "}
            Disable
          </DropdownItem>}
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Settings"
          breadcrumbItem={"Emails"}
        />
        <CardBody>
          <div className="clearfix">
            <div className="btn-group float-end ">
              <Button onClick={() => onCreate()} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Button>
            </div>
            <CardTitle>Email Settings</CardTitle>

          </div>
        </CardBody>

        {email_settings?.data && <DataTableList
          {...props}
          columns={columns}
          get={get}
          className={`project-list-table table-nowrap align-middle table-borderless`}
          current_page={1}
          last_page={2}
          data={email_settings.data}
          callBack={get}
          loading={loading}
          setCurrentPage={() => {
          }}

        />}


        <EmailSettingsForm

          isOpen={form}
          toggle={toggleForm}
          email_settings={email}
          saveChanges={data => {
            dispatch(emailSettingsPost(data, get))
            toggleForm()

          }}
        />




      </Container>
    </div>
  </React.Fragment>
}
