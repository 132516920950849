import axios from "axios"

const BULK_GET_REQUEST = 'BULK_GET_REQUEST';
const BULK_GET_SUCCESS = 'BULK_GET_SUCCESS';
const BULK_GET_FAILURE = 'BULK_GET_FAILURE';

const BULK_FIND_REQUEST = 'BULK_FIND_REQUEST';
const BULK_FIND_SUCCESS = 'BULK_FIND_SUCCESS';
const BULK_FIND_FAILURE = 'BULK_FIND_FAILURE';

const BULK_ACTION_REQUEST = 'BULK_ACTION_REQUEST';
const BULK_ACTION_SUCCESS = 'BULK_ACTION_SUCCESS';
const BULK_ACTION_FAILURE = 'BULK_ACTION_FAILURE';


const init = {
  messages: [],
  message: false,
  loading: false,
  errors: false
}


const Bulk = (state = init, action) => {
  switch (action.type){
    case BULK_GET_REQUEST : { return  {...state, loading: true, errors: false}}
    case BULK_GET_SUCCESS : { return  {...state, loading: false, messages: action.payload}}
    case BULK_GET_FAILURE : { return  {...state, loading: false, errors: action.payload}}

    case BULK_FIND_REQUEST : { return  {...state, loading: true, errors: false}}
    case BULK_FIND_SUCCESS : { return  {...state, loading: false, message: action.payload}}
    case BULK_FIND_FAILURE : { return  {...state, loading: false, errors: action.payload}}

    case BULK_ACTION_REQUEST : { return  {...state, loading: true, errors: false}}
    case BULK_ACTION_SUCCESS : { return  {...state, loading: false}}
    case BULK_ACTION_FAILURE : { return  {...state, loading: false, errors: action.payload}}

    default : { return {...state}}
  }

}

export default Bulk;


export const getBulk = () => dispatch => {
  dispatch({type: BULK_GET_REQUEST})
  axios.get('app/bulk/get')
    .then(({data}) => dispatch({type: BULK_GET_SUCCESS, payload: data}))
    .catch(e => dispatch({type: BULK_GET_FAILURE, payload: e.message}))
}


export const findBulk = (id) => dispatch => {
  dispatch({type: BULK_FIND_REQUEST})
  axios.get(  `app/bulk/get/${id}`)
    .then(({data}) => dispatch({type: BULK_FIND_SUCCESS, payload: data}))
    .catch(e => dispatch({type: BULK_FIND_FAILURE, payload: e.message}))
}

export const resendBulk = (id) => dispatch => {
  dispatch({type: BULK_ACTION_REQUEST})
  axios.post(  `app/bulk/resend/`, {id})
    .then(({data}) => dispatch({type: BULK_ACTION_SUCCESS, payload: data}))
    .catch(e => dispatch({type: BULK_ACTION_FAILURE, payload: e.message}))
}

export const deleteBulk = (id) => dispatch => {
  dispatch({type: BULK_ACTION_REQUEST})
  axios.post(  `app/bulk/delete/`, {id})
    .then(({data}) => dispatch({type: BULK_ACTION_SUCCESS, payload: data}))
    .catch(e => dispatch({type: BULK_ACTION_FAILURE, payload: e.message}))
}

export const broadcastBulk = (data, callBack) => dispatch => {
  dispatch({type: BULK_ACTION_REQUEST})
  axios.post(  `app/bulk/broadcast`, data)
    .then(({data}) => dispatch({type: BULK_ACTION_SUCCESS, payload: data}))
    .catch(e => dispatch({type: BULK_ACTION_FAILURE, payload: e.message}))
    .finally(() => callBack && callBack())
}

