import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
import ReactApexChart from "react-apexcharts"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getWeeklyComplaints } from "../../store/reports/reportReducer"


const WeeklyComplaintsChart = (props) => {
  const dataColors = "[\"--bs-danger\", \"--bs-success\"]"

  const dispatch = useDispatch()

  const { data, reviews, loading } = useSelector(state => ({
    reviews: state.Reports.reviews,
    loading: state.Reports.loading
  }))


  const get = () => {
    dispatch(getWeeklyComplaints())
  }

  const [categories, set_categories] = useState([])
  const [series, set_series] = useState([])

  useEffect(() => {
    let cat = []
    let ser = [{ name: "Open", data: [] }, { name: "Closed", data: [] }];
    (reviews || []).map(row => {
      cat.push(row.name)
      ser[0].data.push(parseInt(row.open))
      ser[1].data.push(parseInt(row.closed))
    })
    set_categories(cat)
    set_series(ser)

  }, [reviews])

  useEffect(() => get(), [])


  const apaexlineColumnColors = getChartColorsArray(dataColors)

  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    title: {
      show: false,
      align: "left",
      style: {
        fontWeight: "bold",
        color: "#bbbbbb"
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"]
    },

    colors: apaexlineColumnColors,
    xaxis: {
      categories: categories
    },
    yaxis: {
      title: {
        text: props.dataTitle ? props.dataTitle : "Complaints (Open & Closed)"
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return " " + val + " rating"
        }
      }
    }
  }

  return (
    <React.Fragment>
      <ReactApexChart options={options} series={series} type="bar" height={340} /></React.Fragment>)
}

export default WeeklyComplaintsChart
