import React from "react"
import { Container } from "reactstrap"



export const QuestionDetails = (props) => {


  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <h1>Question Details</h1>
      </Container>
    </div>
  </React.Fragment>

}
