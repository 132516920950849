import {
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_FIND_REQUEST,
  USER_FIND_SUCCESS,
  USER_FIND_FAILURE,
  USER_POST_REQUEST,
  USER_POST_SUCCESS,
  USER_POST_FAILURE,
  USER_ACTION_REQUEST,
  USER_ACTION_SUCCESS,
  USER_ACTION_FAILURE

} from "./userTypes"
import axios from "axios"
import toastr from "toastr"


export const userGet = (params) => dispatch => {
  console.log(params)
  dispatch({ type: USER_GET_REQUEST })
  axios.get("/app/users/get", {params})
    .then(({ data }) => dispatch({ type: USER_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_GET_FAILURE, payload: message }))
}

export const userFind = (id) => dispatch => {
  dispatch({ type: USER_FIND_REQUEST })
  axios.get(`/app/users/get/${id}`)
    .then(({ data }) => dispatch({ type: USER_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: USER_FIND_FAILURE, payload: message }))
}

export const userDelete = (id, callBack) => dispatch => {
  dispatch({ type: USER_ACTION_REQUEST })
  axios.post(`/app/users/delete`, {id})
    .then(({ data }) => {
      dispatch({ type: USER_ACTION_SUCCESS, payload: data })
      dispatch(userGet())

    })
    .catch(({ message }) => dispatch({ type: USER_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const userPost = (data, callBack) => dispatch => {
  dispatch({ type: USER_POST_REQUEST })
  axios.post(`/app/users/submit`, data)
    .then(({ data }) => {
      dispatch({ type: USER_POST_SUCCESS, payload: data })
      dispatch(userGet())

      if (!callBack) {
        dispatch(userFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch(({ message }) => dispatch({ type: USER_POST_FAILURE, payload: message }))
}

export const userBlock = (id) => dispatch => {
  dispatch({type: USER_ACTION_SUCCESS})
  axios.post(`app/users/block`, {id})
    .then(res => {
      dispatch({ type: USER_ACTION_SUCCESS, payload: res.data })
      dispatch(userFind(res.data.id))
      dispatch(userGet())
    })
    .catch(e => dispatch({type: USER_ACTION_FAILURE, payload: e}))
}
export const userActivation = (id) => dispatch => {
  dispatch({type: USER_ACTION_SUCCESS})
  axios.post(`app/users/activation`, {id})
    .then(res => {
      dispatch({ type: USER_ACTION_SUCCESS, payload: res.data })
      dispatch(userFind(res.data.id))
      // dispatch(userGet())
    })
    .catch(e => dispatch({type: USER_ACTION_FAILURE, payload: e}))
}


export const resetPassword = ({ id, password },callBack) => dispatch => {
  dispatch({type: USER_ACTION_SUCCESS})
  axios.post(`app/users/reset`, {id, password})
    .then(res => {
      dispatch({ type: USER_ACTION_SUCCESS, payload: res.data })
      dispatch(userFind(res.data.id))
      toastr.success("Password Reset Successful", "Success")

    })
    .catch(e => dispatch({type: USER_ACTION_FAILURE, payload: e}))
    .finally(() => callBack && callBack() )
}




