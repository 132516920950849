import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { Button, ButtonGroup, Card, CardBody, CardTitle } from "reactstrap"

const chartapex = ({dataColors}) => {
  const apaexlineChartColors = getChartColorsArray(dataColors);
  const [active, setActive] = useState("csat")

  const getSeries = () => {
    let values = []
    for(let i =0; i < 6; i++) {
      const max = active === 'csat' ? 100 : (Math.round(Math.random())) === 1 ? -100 : 100;
      values.push((Math.round(Math.random() * max)))
    }
    return values
  }


  const series = [
    { name: "CSAT Reviews", data:getSeries()},
    // { name: "NPS Reviews", data: getSeries() },
  ]

  //    max: max + 1
  const options = {
    chart: {
      height: 380,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: apaexlineChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: 'smooth'
    },
    // series: [
    //   {
    //   name: "CSAT - 2018",
    //   data: [-26, 24,-32, 36, -33, 31, -33]
    // },
    // {
    //   name: "NPS - 2018",
    //   data: [14, 11, 16, 12, 17, 13, 12]
    // }
    // ],
    title: {
      show:false,
      text: "",
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: '#f1f1f1'
    },
    markers: {
      style: 'inverted',
      size: 6
    },
    xaxis: {
      categories: ['Nov','Dec','Jan', 'Feb', 'Mar', 'Apr', ],
      title: {
        text: 'Monthly Reviews'
      }
    },
    yaxis: {
      title: {
        text: 'Rating'
      },
      min: active === 'nps' ? -100 : 0,
      max: 100
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          toolbar: {
            show: false
          }
        },
        legend: {
          show: false
        },
      }
    }]
  }

  return (<Card>

    <CardBody>
      <ButtonGroup className="float-end">
        {/*<Button onClick={() => setActive("csat")}*/}
        {/*        className={` ${active == "csat" ? "btn-info" : "btn-light"}`}>CSAT Reviews</Button>*/}
        {/*<Button onClick={() => setActive("nps")}*/}
        {/*        className={` ${active == "nps" ? "btn-info" : "btn-light"}`}>NPS Reviews</Button>*/}
      </ButtonGroup>
      <CardTitle>{active !== 'csat' ? "NPS Reviews" : "CSAT Reviews"}</CardTitle>

      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="380"
        className="apex-charts"
      />

    </CardBody>
  </Card>

  )
}

export default chartapex
