import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { allRatings } from "../../store/ratings/ratingReducer"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Datatable from "react-data-table-component"
import { ReviewFilters } from "../reports/filter"
import moment from "moment"
import { DownloadDocumentReport } from "../../download"

export const NormalReport = props => {

  const { CustomerRatings } = useSelector(state => state)
  const { all_ratings } = CustomerRatings
  const dispatch = useDispatch()

  const [filter, setFilter] = useState({})

  useEffect(() => {
    dispatch(allRatings("Normal", filter))
  }, [filter])

  const columns = [{ name: "#", sortable: true, maxWidth: "20px", selector: (row, index) => index + 1 }, {
    name: "Customer", width: "350px", selector: row => row.customer_name, cell: row => <div>
      <div><strong>{row.customer_name}</strong></div>
      <div>
        {row.customer_email && <div><span><i className="mdi mdi-email-outline" /> {row.customer_email}</span></div>}
        {row.customer_mobileno &&
          <div><span><i className="mdi mdi-phone-outline" /> {row.customer_mobileno}</span></div>}
      </div>
    </div>
  }, {
    name: "Company / Branch", sortable: true, selector: row => <div>
      {row.company_name} {row.branch_name && <>{row.branch_name}</>}
    </div>
  }, // { name: "Type", sortable: true,wrap:true, selector: row => row.question_type },
    { name: "Question", sortable: true, wrap: true, selector: row => row.question }, {
      name: "Response", sortable: true, wrap: true, selector: ({ comments }) => comments


      //   <>
      //   {/*{}*/}
      //   {row.comments && row.question_type === "Survey" && row.comments.split(",").map((row, index) => <Badge
      //     color="primary" className="text-white bg-primary p-1 me-1" key={index}>{row}</Badge>)}
      //   {row.question_type === "Normal" && row.comments}
      //
      // </>
    }, { name: "Date", sortable: true, wrap: true, selector: row => moment(row.created_at).format("DD MMM YYYY") }]

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="Generic"
        />
        <Card>

          <CardBody>
            <DownloadDocumentReport className="float-end" url={`/app/reviews/download/generic`} filters={filter}
                                    title={"Generic Reviews"} />
            <CardTitle>Generic</CardTitle>
          </CardBody>
          <CardBody>
            <ReviewFilters search branch department date size={3} onChange={setFilter} />
          </CardBody>
          <Datatable columns={columns} data={all_ratings} pagination />

        </Card>
      </Container>

    </div>

  </React.Fragment>


}
