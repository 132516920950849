export const SMS_SETTINGS_GET_REQUEST = "SMS_SETTINGS_GET_REQUEST";
export const SMS_SETTINGS_GET_SUCCESS = "SMS_SETTINGS_GET_SUCCESS";
export const SMS_SETTINGS_GET_FAILURE = "SMS_SETTINGS_GET_FAILURE";

export const SMS_SETTINGS_FIND_REQUEST = "SMS_SETTINGS_FIND_REQUEST";
export const SMS_SETTINGS_FIND_SUCCESS = "SMS_SETTINGS_FIND_SUCCESS";
export const SMS_SETTINGS_FIND_FAILURE = "SMS_SETTINGS_FIND_FAILURE";

export const SMS_SETTINGS_ACTION_REQUEST = "SMS_SETTINGS_ACTION_REQUEST";
export const SMS_SETTINGS_ACTION_SUCCESS = "SMS_SETTINGS_ACTION_SUCCESS";
export const SMS_SETTINGS_ACTION_FAILURE = "SMS_SETTINGS_ACTION_FAILURE";


