import axios from "axios"

const FILTER_GET_REQUEST = 'FILTER_GET_REQUEST';
const FILTER_GET_SUCCESS = 'FILTER_GET_SUCCESS';
const FILTER_GET_FAILURE = 'FILTER_GET_FAILURE';

const init = {
  loading: false,
  filters: false,
  errors: false
}

export const Filter = (state = init, action) => {
  switch (action.type){
    case FILTER_GET_REQUEST : {return {...state, loading: true, errors: false}}
    case FILTER_GET_SUCCESS : {return {...state, loading: false, errors: false, filters: action.payload}}
    case FILTER_GET_FAILURE : {return {...state, loading: false, errors: action.payload}}
    default : {return {...state}}
  }
}


export const filterGet = () => dispatch=> {
  dispatch({type : FILTER_GET_REQUEST})
  axios.get('app/filter')
    .then(({data})  => dispatch({type : FILTER_GET_SUCCESS, payload : data}))
    .catch(({e})  => dispatch({type : FILTER_GET_FAILURE, payload : e.message}))
}
