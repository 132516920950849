import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import { Profile } from "./profile"
import StatWidgets from "./statWidgets"
import OverallSentiment from "./overallSentiment"
import { useDispatch, useSelector } from "react-redux"

import { ReviewsAndComplaintsWeeklyChart } from "./reviewsAndComplanitsWeeklyChart"
import CSATandNPSStats from "./CSATAndNPSStats"
import { LatestReviews } from "./latestReviews"
import { branchGet } from "../../store/branches/branchActions"
import { BranchesList } from "../branch/branches"

export const SYSDashboard = props => {

  document.title = "Dashboard - SYS"
  const { branches, loading } = useSelector(state => ({
    loading: state.Branch.loading,
    branches: state.Branch.branches
  }))
  const [orderBy, setOrderBy] = useState("NPS")
  const [orderType, setOrderType] = useState("asc")
  const dispatch = useDispatch()

  const get = () => dispatch(branchGet({
    orderBy, orderType
  }))

  useEffect(() => {
    !loading && get()
  }, [orderBy, orderType])
  const aspects = [
    {
      "aspect_name": "Ambiance"
    },
    {
      "aspect_name": "Cleanliness"
    },
    {
      "aspect_name": "Csat aspect"
    },
    {
      "aspect_name": "Elements"
    },
    {
      "aspect_name": "Nps Aspect"
    },
    {
      "aspect_name": "Overall"
    },
    {
      "aspect_name": "Procedure"
    },
    {
      "aspect_name": "Promptness"
    },
    {
      "aspect_name": "Service"
    },
    {
      "aspect_name": "Staff Attitude"
    },
    {
      "aspect_name": "Survey aspect"
    },
    {
      "aspect_name": "Timeliness"
    }
  ]


  const sentiment = <select onChange={e => setOrderType(e.target.value)} value={orderType}
                            className="d-inline border-0 border-bottom m-2">
    <option value={"desc"} selected={orderType === "desc"}>Best</option>
    <option value={"asc"} selected={orderType === "asc"}>Worst</option>
  </select>

  const scores = <select onChange={e => setOrderBy(e.target.value)} value={orderBy}
                         className="d-inline border-0 border-bottom m-2">
    <option selected={orderBy === "NPS"}>NPS</option>
    <option selected={orderBy === "CSAT"}>CSAT</option>
    {aspects.map((row, key) => <option key={key} selected={orderBy === row.aspect_name}>{row.aspect_name}</option>)}

  </select>


  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Profile />
        <StatWidgets />
        <Row>
          <Col sm={8}>
            <ReviewsAndComplaintsWeeklyChart />
          </Col>
          <Col sm={4}><OverallSentiment /></Col>
        </Row>
        <CSATandNPSStats />

        <Card className="bg-transparent">
          <CardBody>
            <div className="float-end">
              This are the {sentiment} Branches sorted by {scores}
            </div>
            <CardTitle>Leader Board</CardTitle></CardBody>
          <BranchesList
            get={get}
            hideActions
            showStats
            data={branches.data}
            loading={loading}
          />
        </Card>
        <LatestReviews />
      </Container>
    </div>
  </React.Fragment>


}



