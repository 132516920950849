export const EMAIL_SETTINGS_GET_REQUEST = "EMAIL_SETTINGS_GET_REQUEST";
export const EMAIL_SETTINGS_GET_SUCCESS = "EMAIL_SETTINGS_GET_SUCCESS";
export const EMAIL_SETTINGS_GET_FAILURE = "EMAIL_SETTINGS_GET_FAILURE";

export const EMAIL_SETTINGS_FIND_REQUEST = "EMAIL_SETTINGS_FIND_REQUEST";
export const EMAIL_SETTINGS_FIND_SUCCESS = "EMAIL_SETTINGS_FIND_SUCCESS";
export const EMAIL_SETTINGS_FIND_FAILURE = "EMAIL_SETTINGS_FIND_FAILURE";

export const EMAIL_SETTINGS_ACTION_REQUEST = "EMAIL_SETTINGS_ACTION_REQUEST";
export const EMAIL_SETTINGS_ACTION_SUCCESS = "EMAIL_SETTINGS_ACTION_SUCCESS";
export const EMAIL_SETTINGS_ACTION_FAILURE = "EMAIL_SETTINGS_ACTION_FAILURE";


