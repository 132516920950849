import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import ComplaintReportChart from "./charts/complaintReports"
import Datatable from "react-data-table-component"
import { ReviewFilters } from "./filter"
import { getComplaintReport, getComplaintsReportData } from "../../store/reports/reportReducer"
import { exportDataToExcel } from "../../utility/download"
import { DownloadDocumentReport } from "../../download"

export const ComplaintsReport = (props) => {

  const dispatch = useDispatch()

  const [filters, setFilters] = useState();

  const get = () => {
    dispatch(getComplaintReport(filters))

    dispatch(getComplaintsReportData(filters))
  }

  const { loading, data,table } = useSelector((state) => ({
    loading: state.Reports.loading,
    data: state.Reports.complaints,
    table: state.Reports.complaintsReport
  }))



  useEffect(() => {
    get()

  }, [filters])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>


        <Card>
          <CardBody>
            <DownloadDocumentReport className="float-end" url={`/app/reports/download/complaints`} filters={filters}
                                    title={"Complaints Report"} />

            {/*<UncontrolledDropdown>*/}
            {/*  <DropdownToggle*/}
            {/*    onClick={() => get()}*/}
            {/*    className="float-end card-drop btn btn-soft-primary">*/}
            {/*    Export <i className="mdi mdi-chevron-down"></i>*/}
            {/*  </DropdownToggle>*/}
            {/*  <DropdownMenu className="dropdown-menu-end">*/}
            {/*    */}
            {/*    */}
            {/*    <DropdownItem onClick={() => exportDataToExcel(*/}
            {/*      'complaints-report', 'complaints', data.filter(({open, closed}) =>(parseInt(open) + parseInt(closed)))*/}
            {/*    )}> Excel </DropdownItem>*/}
            {/*  </DropdownMenu>*/}
            {/*</UncontrolledDropdown>*/}
            <CardTitle>Complaint Report</CardTitle>
            <hr />
            <ReviewFilters size={4}  branch date department onChange={setFilters} />
            <ComplaintReportChart dataTitle="Number of Complaints" title={""} data={data.filter(({open, closed}) =>(parseInt(open) + parseInt(closed)))} dataColors='["--bs-danger","--bs-success"]' />
          </CardBody>

          <Datatable pagination paginationPerPage={10} striped progressPending={loading} columns={[
            { name: "Name", selector: ({ name }) => name, sortable: true },
            { name: "Open", selector: ({ open }) => open, sortable: true, center: true },
            { name: "Closed", selector: ({ closed }) => closed, sortable: true, center: true },
            { name: "Total", selector: ({ open, closed }) => (parseInt(open) + parseInt(closed)), sortable: true, center: true }
          ]} data={table.filter(({open, closed}) =>(parseInt(open) + parseInt(closed)))} />

        </Card>
      </Container>
    </div>
  </React.Fragment>
}



