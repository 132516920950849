import Rating from "react-rating"
import React from "react"
import { Input } from "reactstrap"

export const RatingSmiley = ({ onChange, value }) => {
  return <> <Rating
    onClick={e => console.log("You clicked me")}
    onChange={v => {
      console.log(v)
      onChange(v)
    }} max={5}
    style={{ fontSize: 50 }}
    size={40}
    initialRating={value}

    emptySymbol={"mdi mdi-emoticon-happy-outline text-muted"}
    fullSymbol={"mdi mdi-emoticon-happy-outline text-warning"}
    className="rating-symbol-background"
  />

  </>
}

export const RatingStar = ({ onChange, value }) => {
  return <>
    <Rating
      onChange={v => {
        console.log(v)
        onChange(v)
      }}
      max={5}
      style={{ fontSize: 50 }}
      size={40}
      initialRating={value}
      emptySymbol={"mdi mdi-star-outline text-muted"}
      fullSymbol={"mdi mdi-star text-warning"}
      className="rating-symbol-background"
    />
  </>
}

export const RatingQualitativeAgree = ({ id, onChange, value }) => {

  return <>
    {["Strongly Disagree", "Disagree", "Neither Agree nor Disagree", "Agree", "Strongly Agree"].map((row, index) => {
      return <div className="form-check form-check-inline mb-3" key={index}>
        <>
          <input
            onClick={() => onChange(index + 1)}
            checked={index + 1 === value}
            type={"radio"}
            id={`customRadio-${index}-${id}`}
            name={`custom-radio-${id}`}
            className="form-check-input"
            value={row}
          />
          <label
            className="form-check-label"
            htmlFor={`customRadio-${index}-${id}`}
          >
            {row}
          </label>
        </>
      </div>
    })}
  </>
}
export const RatingQualitativeExcellence = ({ id, onChange, value }) => {

  return <>
    {["Very Poor", "Poor", "Good", "Very Good", "Excellent"].map((row, index) => {
      return <div className="form-check form-check-inline mb-3" key={index}>
        <>
          <input
            onClick={() => onChange(index + 1)}
            checked={index + 1 === value}
            type={"radio"}
            id={`customRadio-${index}-${id}`}
            name={`custom-radio-${id}`}
            className="form-check-input"
            value={row}
          />
          <label
            className="form-check-label"
            htmlFor={`customRadio-${index}-${id}`}
          >
            {row}
          </label>
        </>
      </div>

    })}
  </>
}


export const CommentBox = ({ onChange, value }) => {
  return <Input type="textarea" onChange={e => onChange(e.target.value)} value={value} />

}
