export const DEPARTMENT_GET_REQUEST = "DEPARTMENT_GET_REQUEST";
export const DEPARTMENT_GET_SUCCESS = "DEPARTMENT_GET_SUCCESS";
export const DEPARTMENT_GET_FAILURE = "DEPARTMENT_GET_FAILURE";


export const DEPARTMENT_FIND_REQUEST = "DEPARTMENT_FIND_REQUEST";
export const DEPARTMENT_FIND_SUCCESS = "DEPARTMENT_FIND_SUCCESS";
export const DEPARTMENT_FIND_FAILURE = "DEPARTMENT_FIND_FAILURE";


export const DEPARTMENT_POST_REQUEST = "DEPARTMENT_POST_REQUEST";
export const DEPARTMENT_POST_SUCCESS = "DEPARTMENT_POST_SUCCESS";
export const DEPARTMENT_POST_FAILURE = "DEPARTMENT_GET_FAILURE";


export const DEPARTMENT_DELETE_REQUEST = "DEPARTMENT_DELETE_REQUEST";
export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAILURE = "DEPARTMENT_DELETE_FAILURE";



export const DEPARTMENT_ACTION_REQUEST = "DEPARTMENT_ACTION_REQUEST";
export const DEPARTMENT_ACTION_SUCCESS = "DEPARTMENT_ACTION_SUCCESS";
export const DEPARTMENT_ACTION_FAILURE = "DEPARTMENT_ACTION_FAILURE";


