import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { findBulk } from "../../store/bulk/BulkReducer"
import { Button, Card, CardBody, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Link } from "react-router-dom"


export const BulkNotificationModal = (props) => {
  const { id, isOpen, toggle } = props

  const dispatch = useDispatch()
  const { loading, message } = useSelector(state => ({
    loading: state.Bulk.loading,
    message: state.Bulk.message
  }))

  useEffect(() => {
    dispatch(findBulk(id))
  }, [dispatch, id])


  return <Modal isOpen={isOpen} toggle={toggle} className="modal-xl">
    <ModalHeader toggle={toggle}>
      {loading && "loading"}
      {props.subject}

    </ModalHeader>
    <ModalBody>

      <Card>
        <CardBody>
          <CardTitle>Sent To : </CardTitle>
          {props.emails.map((email, key) => <spam key={key}><Link to={`mailTo:${email}`}>{email}</Link> , </spam>)}
        </CardBody>
      </Card>
      <div style={{ overflow: "scroll" }} dangerouslySetInnerHTML={{ __html: props.body }} />
    </ModalBody>
    <ModalFooter>
      <Button className="btn btn-soft-primary" onClick={toggle}>Close</Button>
    </ModalFooter>
  </Modal>
}

