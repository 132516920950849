import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"
import ReactApexChart from "react-apexcharts"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { complaintsChart } from "../../../store/complaints/complaintsReducer"
import { Button, ButtonGroup, Card, CardBody, CardTitle } from "reactstrap"
import { Pre } from "../../../helpers/pre"


const NPSReportChart = (props) => {
  const { title, data, dataColors } = props



  const dispatch = useDispatch()
  const [categories, set_categories] = useState([])
  const [series, set_series] = useState([])


  useEffect(() => {
    let cat = []
    let ser = [{ name: "Promoters", data: [] }, { name: "Neutral", data: [] }, { name: "Detractors", data: [] }];
    (data || []).map(row => {
      cat.push(row.name ? row.name : "Untitled")
      ser[0].data.push(row["promoters"] ? row["promoters"] : 0)
      ser[1].data.push(row["neutral"] ? row["neutral"] : 0)
      ser[2].data.push(row["detractors"] ? row["detractors"] : 0)
    })
    set_categories(cat)
    set_series(ser)


  }, [data])

  const apaexlineColumnColors = getChartColorsArray(dataColors)


  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    title: {
      show: false,
      align: "left",
      style: {
        fontWeight: "bold",
        color: "#bbbbbb"
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"]
    },

    colors: apaexlineColumnColors,
    xaxis: {
      categories: categories
    },
    yaxis: {
      title: {
        text: props.dataTitle ? props.dataTitle : "Numbers"
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return " " + val + " rating"
        }
      }
    }
  }

  return (
    <Card className="shadow-none">
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <ReactApexChart options={options} series={series} type="bar" height={340} />
      </CardBody>
    </Card>
  )
}

export default NPSReportChart
