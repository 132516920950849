import React from "react"
import { Link } from "react-router-dom"


export const UserChip = ({ user }) => {

  const status = {
    "inactive": "secondary",
    "active": "success",
    "unverified": "warning",
    "blocked": "danger"
  }

  if (!user) return <></>

  return <div className="d-inline-block bg-light rounded-5 mb-2 m-1">
    <Link
      to={`/settings/user/${user.id}`}
      className="d-inline-block"
      id={"member" + user.id}
    >
      <div className="avatar-xs d-inline-block">
          <span
            className={
              "avatar-title rounded-circle bg-gradient  bg-" +
              status[user.status] +
              " text-white" +
              // typeof status[user.status] !== 'undefined' && status[user.status] +
              " font-size-16"
            }
          >
            {user.name.charAt(0)}
          </span>
      </div>
      <span className={`d-inline-block p-2 text-${status[user.status]}`}>
        {user.name}
        {/*{typeof status[user.status] !== 'undefined' && status[user.status]}*/}
      </span>
    </Link>
  </div>
}

export const UserChips = ({ users }) => {
  return <React.Fragment>
    {(users || []).map((user, index) => <UserChip user={user} key={index} />)}
  </React.Fragment>
}
