import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Button, ButtonGroup, Card, CardBody, CardTitle } from "reactstrap"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
import { getCSATNPSRatings } from "../../store/reports/reportReducer"
import { useDispatch, useSelector } from "react-redux"
import { Pre } from "../../helpers/pre"

const CSATandNPSStats = () => {
  const dispatch = useDispatch()

  const apaexlineChartColors = getChartColorsArray("[\"--bs-primary\", \"--bs-success\"]")
  const [active, setActive] = useState("csat")

  const get = () => dispatch(getCSATNPSRatings())

  const { csat_nps_ratings, loading } = useSelector(state => ({
    csat_nps_ratings: state.Reports.csat_nps_ratings,
    loading: state.Reports.loading
  }))

  useEffect(() => get(), [active])


  const options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: apaexlineChartColors,
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [3, 3],
      curve: "smooth"
    },
    title: {
      show: false,
      text: ""
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: "#f1f1f1"
    },
    markers: {
      style: "inverted",
      size: 6
    },
    xaxis: {
      categories: csat_nps_ratings?.months,
      title: {
        text: "Monthly Reviews"
      }
    },
    yaxis: {
      title: {
        text: "Rating"
      },
      min: active === "nps" ? -100 : 0,
      max: 100
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          toolbar: {
            show: false
          }
        },
        legend: {
          show: false
        }
      }
    }]
  }

  return (<Card>

      <CardBody>
        <ButtonGroup className="float-end">
          <Button onClick={() => setActive("csat")}
                  className={` ${active == "csat" ? "btn-info" : "btn-light"}`}>CSAT Reviews</Button>
          <Button onClick={() => setActive("nps")}
                  className={` ${active == "nps" ? "btn-info" : "btn-light"}`}>NPS Reviews</Button>
        </ButtonGroup>
        <CardTitle>{active !== "csat" ? "NPS Reviews" : "CSAT Reviews"}</CardTitle>
        {csat_nps_ratings?.months.length && <ReactApexChart
          options={options}
          series={[{name: "" ,  data: csat_nps_ratings[active]}]}
          type="line"
          height="380"
          className="apex-charts"
        />}
      </CardBody>
    </Card>

  )
}

export default CSATandNPSStats
