import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody, Col,
  Container,
  Nav,
  NavItem,
  NavLink, Row, UncontrolledTooltip
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { roleGet, roleDelete, rolePost, rolePostUsers } from "../../store/roles/roleActions"
import classnames from "classnames"
import { RoleDetails } from "./roleDetails"
import RoleForm from "./roleForm"
import RoleUsersForm from "./roleUsersForm"
import { UserChips } from "../../components/Common/userChip"
import { permissionsGet } from "../../store/permissions/permissionsReducer"
import Pages413 from "../413"

export const Roles = props => {

  const dispatch = useDispatch()
  const {  roles, role, loading, permissions } = useSelector(state => ({
    permissions: state.Permissions.permissions ,
    roles: state.Roles.roles ,
    role: state.Roles.role ,
    loading: state.Roles.loading ,
  }))
  const [form, setForm] = useState(false)
  const [userForm, setUserForm] = useState(false)
  const toggleForm = () => setForm(!form)

  const get = () => dispatch(roleGet())


  const [active, setActive] = useState(false)


  const toggle = active => {
    setActive(active.id)
  }

  useEffect(() => {
    if (roles.length > 0 && active === false) {
      toggle(roles[0])
    }
  }, [roles])

  useEffect(get, [])


  document.title = "Roles | Q-SYS"

  const p_CREATE = true || ((permissions || []).filter(({ name }) => name === "CREATE ROLE").length > 0)
  const p_ASSIGN_USERS_ROLES = true || ((permissions || []).filter(({ name }) => name === "ASSIGN USERS ROLES").length > 0)
  const p_ASSIGN_ROLE_PERMISSIONS = true || ((permissions || []).filter(({ name }) => name === "ASSIGN ROLE PERMISSIONS").length > 0)
  const p_DELETE_ROLES = true || ((permissions || []).filter(({ name }) => name === "DELETE ROLE").length > 0)
  const p_VIEW_ROLES = true || ((permissions || []).filter(({ name }) => name === "VIEW ROLES").length > 0)

  // if(!p_VIEW_ROLES ) return <Pages413 />
  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Roles"}
          breadcrumbItem="Roles"
        />
        <Card>
          <CardBody>
            <Nav tabs className="nav-tabs-custom nav-justified">
              {p_CREATE && <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleForm()
                  }}>
                  <i className="mdi mdi-plus "></i> Create
                </NavLink>
              </NavItem>
              }
              {roles.map(row => <NavItem key={`roles-${row.id}`}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: active === row.id
                  })} onClick={() => {
                  toggle(row)
                }}
                >
                  <Badge
                    color={row.users.length > 0 ? (active === row.id ? "info" : "dark") : "danger"}>{row.users.length}</Badge>
                  {" "}
                  {row.name}
                </NavLink>
              </NavItem>)}
            </Nav>
          </CardBody>
          <CardBody>
            {!role && loading && <h1 className="text-muted text-center">Loading ....</h1>}
            {role && <Row>
              <Col sm={9}>
                <UserChips users={role.users} />
              </Col>
              <Col sm={3}>
                <div className="float-end">
                  <ButtonGroup className="">

                    <Button outline color="dark" onClick={() => dispatch(permissionsGet())}>
                      <i className="d-block mdi mdi-reload"></i> </Button>

                    {p_DELETE_ROLES && <Button outline color="danger" onClick={() => dispatch(roleDelete(role.id, get))}>
                      <i className="d-block mdi mdi-trash-can-outline"></i> Delete </Button>}
                    {p_ASSIGN_USERS_ROLES && <Button outline color="info" onClick={() => setUserForm(!userForm)}>
                      <i className="d-block mdi mdi-account-group-outline"></i> Assign Users</Button>}
                  </ButtonGroup>
                </div>
              </Col>
            </Row>}
          </CardBody>
        </Card>
        <RoleDetails assignRoles={p_ASSIGN_USERS_ROLES} assignPermissions={p_ASSIGN_ROLE_PERMISSIONS} loading={loading} id={active} {...props} />

        <RoleForm isOpen={form} toggle={toggleForm} saveChanges={data => {
          toggleForm()
          dispatch(rolePost(data, (id) => {
            setActive(id)
            get()
          }))
        }} />


        <RoleUsersForm isOpen={userForm} role={role.id} toggle={() => setUserForm(!userForm)} saveChanges={data => {
          setUserForm(!userForm)
          dispatch(rolePostUsers(data))
        }} />
      </Container>
    </div>
  </React.Fragment>
}
