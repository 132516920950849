import React from "react"
import {
  Alert,
  Button,
  CardBody,
  CardTitle, Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Row
} from "reactstrap"
import axios from "axios"
import Select from "react-select"
import { selectColorStyles } from "../../../components/selectColorStyle"
import makeAnimated from "react-select/animated"
import { Pre } from "../../../helpers/pre"
import { useSelector } from "react-redux"

const animatedComponents = makeAnimated()

const amSuperAdmin = () => {
  const { user } = useSelector(state => ({ user: state.Authentication.user }))
  return user.super_admin
}


export default class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()
  }

  init = () => {
    const { department, isOpen } = this.props
    if (!isOpen) return false
    this.setState({ fields: false, form: null, loading: true })
    axios
      .post("app/users/form", { id: this.props.user })
      .then(res => this.setState({ form: res.data, fields: res.data.fields }))
      .finally(() => this.setState({ loading: false }))
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, user, saveChanges, sourceLoading } = this.props
    const { loading, fields, form } = this.state
    return <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
      <Form method="post" onSubmit={(e) => {
        e.preventDefault()
        saveChanges(fields)
      }}>
        <ModalHeader>
          <CardTitle>
            {!loading && <>
              {user && <>Edit User</>}
              {!user && <>Create User</>}
            </>}
            {loading && "loading..."}
          </CardTitle>
        </ModalHeader>
        <ModalBody>

          {sourceLoading && <Alert color="success">Saving Changes... please wait</Alert>}
          <Row>
            <Col sm={4}>
              <div className="mb-3">
                <Label htmlFor="form-department-name">Full Name</Label>
                <Input
                  required
                  name={"name"}
                  readOnly={loading}
                  value={fields?.name}
                  onChange={e => fields && this.handleChange("name", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-department-name"
                  placeholder="e.g. First name Surname "
                />
              </div>
            </Col>
            <Col sm={8}>
              <div className="mb-3">
                <Label htmlFor="form-department-name">Email</Label>
                <Input
                  name="email"
                  required
                  readOnly={loading}
                  value={fields?.email}
                  onChange={e => fields && this.handleChange("email", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-department-name"
                  placeholder="e.g. name@domain.com"
                />
              </div>

            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              {fields && <CardBody>
                <CardTitle>Assign Roles</CardTitle>
                {fields.roles.map((role, index) => <div key={`user-form-role-${role.id}`}
                                                        className="form-check form-switch mb-3"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value={role.checked}
                    defaultChecked={role.checked}
                    onChange={(e) => {
                      let record = fields
                      record.roles[index].checked = !role.checked
                      this.setState({ fields: record })
                    }}
                    id={`user-form-role-${role.id}`}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`user-form-role-${role.id}`}
                  >
                    {role.name}
                  </label>
                </div>)}
              </CardBody>}
            </Col>
            <Col sm={8}>
              <div className="mb-3">
                <Label htmlFor="form-department-name">Mobile Number</Label>
                <Input
                  name="mobile_number"
                  readOnly={loading}
                  value={fields?.mobile_number}
                  onChange={e => fields && this.handleChange("mobile_number", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-mobile_number-name"
                  placeholder="e.g. +254 123 456 789"
                />
              </div>
              {fields && <div className="mb-3">
                <Label htmlFor="user-companies">Company</Label>
                <Select
                  required
                  closeMenuOnSelect={false}
                  onChange={e => this.handleChange("companies_id", e)}
                  value={fields?.companies_id}
                  options={form?.companies}
                  loading={loading}
                  components={animatedComponents}
                  styles={selectColorStyles}
                />
              </div>}
              {fields && <div className="mb-3">
                <Label htmlFor="user-companies">Branch</Label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  // onChange={e => this.handleChange("branch_id", e)}
                  value={fields?.branch}
                  options={form?.branches.filter((row) => row.companies_id === fields?.companies_id?.value)}
                  loading={loading}
                  components={animatedComponents}
                  styles={selectColorStyles}
                />
              </div>}
              {fields && <div className="mb-3">
                <Label htmlFor="user-companies">Department</Label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  // onChange={e => this.handleChange("department_id", e)}
                  value={fields?.department}
                  options={form?.departments.filter((row) => (row.companies_id === fields?.companies_id?.value || row.branches_id === fields?.branches_id?.value))}
                  loading={loading}
                  components={animatedComponents}
                  styles={selectColorStyles}
                />
              </div>}
            </Col>
          </Row>
          {/*<Pre data={fields} />*/}
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} outline color="dark" onClick={toggle}>Close</Button>
          <Button disabled={loading} outline color="success" type="submit">
            <i className={loading ? "mdi mdi-loading mdi-spin" : "mdi mdi mdi-account-check-outline "} /> Save
            Changes</Button>
        </ModalFooter>
      </Form>
    </Modal>
  }


}
