import axios from "axios"
import {
  MESSAGES_ACTION_FAILURE,
  MESSAGES_ACTION_REQUEST, MESSAGES_ACTION_SUCCESS, MESSAGES_FIND_FAILURE, MESSAGES_FIND_REQUEST, MESSAGES_FIND_SUCCESS,
  MESSAGES_GET_FAILURE,
  MESSAGES_GET_REQUEST,
  MESSAGES_GET_SUCCESS
} from "./messagesTypes"


export const messagesGet = (page, state) => dispatch => {
  dispatch({ type: MESSAGES_GET_REQUEST })
  axios.get("app/profile/messages", { params: { page, state } })
    .then(({ data }) => dispatch({ type: MESSAGES_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: MESSAGES_GET_FAILURE, payload: message }))
}

export const messagesFind = (message) => dispatch => {
  dispatch({ type: MESSAGES_FIND_REQUEST, payload: message })
  axios.get(`app/profile/messages/${message.id ? message.id : message}`)
    .then(({ data }) => dispatch({ type: MESSAGES_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: MESSAGES_FIND_FAILURE, payload: message }))
}

export const messageStar = (message) => dispatch => {
  message.star = !message.star
  dispatch({ type: MESSAGES_ACTION_REQUEST, payload: message })
  axios.post(`app/profile/messages/star`, { id: message.id })
    .then(({ data }) => dispatch({ type: MESSAGES_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: MESSAGES_ACTION_FAILURE, payload: message }))
}
export const messageImportant = (message) => dispatch => {
  message.star = !message.star
  dispatch({ type: MESSAGES_ACTION_REQUEST, payload: message })
  axios.post(`app/profile/messages/important`, { id: message.id })
    .then(({ data }) => dispatch({ type: MESSAGES_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: MESSAGES_ACTION_FAILURE, payload: message }))
}

export const messageUnread = (message) => dispatch => {
  message.star = !message.star
  dispatch({ type: MESSAGES_ACTION_REQUEST, payload: message })
  axios.post(`app/profile/messages/unread`, { id: message.id })
    .then(({ data }) => dispatch({ type: MESSAGES_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: MESSAGES_ACTION_FAILURE, payload: message }))
}

export const messageTrash = (message) => dispatch => {
  message.star = !message.star
  dispatch({ type: MESSAGES_ACTION_REQUEST, payload: message })
  axios.post(`app/profile/messages/trash`, { id: message.id })
    .then(({ data }) => dispatch({ type: MESSAGES_ACTION_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: MESSAGES_ACTION_FAILURE, payload: message }))
}




