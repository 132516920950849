import toastr from "toastr"
import React from "react"
// import {useNavigate} from 'react-router-dom'
import "toastr/build/toastr.min.css"
import axios from "axios"


export const handleError = (e, callBack) => {
  // const navigate = useNavigate()

  console.log(e)
  toastr.clear()
  const message = e.response.data?.message ? e.response.data.message : e.message


  if (e.response.status === 404) {
    axios.get("optimize")
  }
  if (e.response.status === 403) {
    toastr.warning("Sorry, You do not have the required access", "Access Denied")
    return callBack && callBack()
  }
  if (e.response.status === 204) {
    toastr.warning("Record deleted or does not exist", "Empty")
    return callBack && callBack()
  }



  toastr.error(message, "Error")


  callBack && callBack()

}
