export const MESSAGES_GET_REQUEST = 'MESSAGES_GET_REQUEST';
export const MESSAGES_GET_SUCCESS = 'MESSAGES_GET_SUCCESS';
export const MESSAGES_GET_FAILURE = 'MESSAGES_GET_FAILURE';

export const MESSAGES_FIND_REQUEST = 'MESSAGES_FIND_REQUEST';
export const MESSAGES_FIND_SUCCESS = 'MESSAGES_FIND_SUCCESS';
export const MESSAGES_FIND_FAILURE = 'MESSAGES_FIND_FAILURE';

export const MESSAGES_ACTION_REQUEST = 'MESSAGES_ACTION_REQUEST';
export const MESSAGES_ACTION_SUCCESS = 'MESSAGES_ACTION_SUCCESS';
export const MESSAGES_ACTION_FAILURE = 'MESSAGES_ACTION_FAILURE';



