import React from "react"
import {
  Button, Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import { Pre } from "../../../components/pre"
import axios from "axios"
import Select from "react-select"
import { selectColorStyles } from "../../../components/selectColorStyle"
import Creatable from 'react-select/creatable';


export default class QuestionForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false,
      form: false,
      branches: [],
      departments: []
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true) this.init()


    if (prevState.fields && prevState.fields != this.state.fields) {
      this.updateBranches()
    }

  }

  updateBranches = () => {

    const { fields, form } = this.state
    this.setState({ branches: form.branches.filter(row => row.companies_id == fields.companies_id) }, this.updateDepartments)
  }

  updateDepartments = () => {
    const { fields, form, branches } = this.state
    this.setState({
      departments: form.departments.filter(row => {

        const condition_1 = row.companies_id == fields.companies_id
        const condition_2 = branches.length > 0 && row.branches_id == fields.branches_id

        return condition_1 || condition_2

      })
    }, () => console.log("Departments Updated"))


  }

  init = () => {
    console.clear()
    const { question } = this.props
    axios
      .post("app/questions/form", { id: question })
      .then(res => this.setState({
        form: res.data,
        fields: res.data.fields
      }, this.updateBranches))
  }

  handleChange = (label, value) => {
    let { fields } = this.state

    if (label === "question_type" && value === "CSAT") {
      fields["negative_review_level"] = 3
    }
    if (label === "question_type" && value === "NPS") {
      fields["negative_review_level"] = 6
    }


    fields[label] = value
    this.setState({ fields }, () => {
      this.updateBranches()
      this.updateDepartments()
    })
  }


  render() {
    const { isOpen, toggle, question, saveChanges } = this.props
    const { loading, fields, form, branches, departments } = this.state

    const handleChoiceChange = (value, index) => {
      let { fields } = this.state
      fields.choices[index].choice = value
      this.setState({ fields })

    }

    return <Modal isOpen={isOpen} toggle={toggle}>
      {!loading && form && <>
        <ModalHeader>

          {question.id && <>Edit : {question.question_name}</>}
          {!question.id && <>Create Question</>}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={departments.length > 0 || branches.length > 0 ? 6 : 12}>

              <div className="mb-3">
                <Label htmlFor="form-question-name">Company</Label>
                <select
                  value={fields.companies_id}
                  onChange={e => this.handleChange("companies_id", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  <option value={0}>Choose Companies</option>

                  {form.companies.map(row => <option
                    key={`company-${row.id}`} value={row.id}>{row.company_name}</option>)}

                </select>
              </div>
            </Col>
            {branches.length > 0 && <Col sm={6}>

              <div className="mb-3">
                <Label htmlFor="form-question-branches_id">Branch {branches.length}</Label>
                <select
                  value={fields.branches_id}
                  onChange={e => this.handleChange("branches_id", e.target.value)}
                  className="form-control"
                  id="form-question-branches_id"
                  placeholder=""
                >
                  <option value={0}>Choose Branches</option>
                  {branches.map(row => <option
                    key={`company-${row.id}`} value={row.id}>{row.branch_name}</option>)}

                </select>
              </div>
            </Col>}


            {departments.length > 0 && <Col sm={branches.length > 0 ? 12 : 6}>
              <div className="mb-3">
                <Label htmlFor="form-question-branches_id">Department {departments.length}</Label>
                <select
                  value={fields.department_id}
                  onChange={e => this.handleChange("department_id", e.target.value)}
                  className="form-control"
                  id="form-question-department"
                >
                  <option>Choose Departments</option>
                  {departments.map(row => <option
                    key={`department-company-${row.id}`} value={row.id}>{row.department_name}</option>)}
                </select>
              </div>
            </Col>}
          </Row>

          <div className="mb-3">
            <Label htmlFor="form-question-name">Question</Label>
            <Input
              value={fields.question}
              onChange={e => this.handleChange("question", e.target.value)}
              type="textarea"
              className="form-control"
              id="form-question-name"
              placeholder=""
            />
          </div>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Aspect</Label>

                <Creatable
                  styles={selectColorStyles}
                  value={fields.aspect_id}
                  options={form.aspects}
                  isClearable
                  onChange={e => this.handleChange("aspect_id", e)}

                />


              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-is_mandatory-name">Mandatory</Label>
                <select
                  value={fields.is_mandatory}
                  onChange={e => this.handleChange("is_mandatory", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-is_mandatory-name">
                  {form.enums.is_mandatory.map(row =>
                    <option key={`is_mandatory-${row}`} value={row}>{row}</option>)}
                </select>
              </div>
            </Col>
          </Row>
          <FormGroup>
            <Label htmlFor="escalation_level">Escalation Group</Label>
            <Creatable

              isClearable
              id={'escalation_level'}
                    options={form.escalation_groups}
                    styles={selectColorStyles}
                    onChange={(e) => this.handleChange('escalation_group', e)}
                    value={fields.escalation_group} />
          </FormGroup>

          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Question Type</Label>
                <select
                  value={fields.question_type}
                  onChange={e => this.handleChange("question_type", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  <option>NONE</option>

                  {form.enums.question_type.map(row =>
                    <option key={`question_types-${row}`} value={row}>{row}</option>)}
                </select>
              </div>
            </Col>
            {["Normal"].includes(fields.question_type) && <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Response Type</Label>
                <select
                  value={fields.response_type}
                  onChange={e => this.handleChange("response_type", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  <option>NONE</option>
                  {form.enums.response_type.map(row =>
                    <option key={`question_types-${row}`} value={row}>{row}</option>)}
                </select>
              </div>
            </Col>}
            {["CSAT"].includes(fields.question_type) && <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Response Type</Label>
                <select
                  value={fields.csat_response_type}
                  onChange={e => this.handleChange("csat_response_type", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  <option>NONE</option>
                  {form.enums.csat_response_type.map(row =>
                    <option key={`question_types-${row}`} value={row}>{row}</option>)}
                </select>
              </div>
            </Col>}
            {["Survey"].includes(fields.question_type) && <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Response Type</Label>
                <select
                  value={fields.survey_response_type}
                  onChange={e => this.handleChange("survey_response_type", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  <option>Choose CSAT Response Type</option>
                  {form.enums.survey_response_type.map(row =>
                    <option key={`survey_response_types-${row}`} value={row}>{row}</option>)}
                </select>
              </div>
            </Col>}
          </Row>
          {["Single Choice", "Multi Choice"].includes(fields.survey_response_type) && ["Survey"].includes(fields.question_type) &&
          <Card className="shadow-none">
            <CardBody>
              <FormGroup>
                {fields.choices.map((row,index) =><Input key={index} value={fields.choices[index].choice} onChange={e => handleChoiceChange(e.target.value, index)}
                        className="mb-1" placeholder={`Choice ${index}`} />)}

              </FormGroup>
            </CardBody>
          </Card>}

          {["CSAT", "NPS"].includes(fields.question_type) && <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-question-name">Negative Review Level</Label>
                <select

                  disabled={fields.question_type === "CSAT"}
                  value={fields.negative_review_level}
                  onChange={e => this.handleChange("negative_review_level", e.target.value)}
                  type="text"
                  className="form-control"
                  id="form-question-name"
                  placeholder=""
                >
                  {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map(row => {

                    if (fields.question_type === "CSAT" && row > 5) {
                      return <></>
                    }

                    if (fields.question_type === "NPS" && row > 6) {
                      return <></>
                    }
                    return <option key={row} value={row}>{row}</option>
                  })}

                </select>


              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="form-response-type">Negative Response Type</Label>
                <select
                  value={fields.negative_response_type}
                  onChange={e => this.handleChange("negative_response_type", e.target.value)}
                  className="form-control"
                  id="form-response_type"
                >
                  <option>Choose Response Type</option>
                  {form.enums.survey_response_type.map(row =>
                    <option key={`response_types-${row}`} value={row}>{row}</option>)}
                </select>
              </div>
            </Col>
          </Row>}

          {["Single Choice", "Multi Choice"].includes(fields.negative_response_type) && <Card className="shadow-none">
            <CardBody>
              <FormGroup>
                <Input className="mb-1" placeholder="Choice 1" />
                <Input className="mb-1" placeholder="Choice 2" />
                <Input className="mb-1" placeholder="Choice 3" />
                <Input className="mb-1" placeholder="Choice 4" />
              </FormGroup>
            </CardBody>
          </Card>}


          {/*<Pre data={fields} />*/}
        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </>}
    </Modal>
  }


}
