import axios from "axios"

const NPS_REPORT_REQUEST = "NPS_REPORT_REQUEST"
const NPS_REPORT_SUCCESS = "NPS_REPORT_SUCCESS"
const NPS_REPORT_FAILURE = "NPS_REPORT_FAILURE"

const CSAT_REPORT_REQUEST = "CSAT_REPORT_REQUEST"
const CSAT_REPORT_SUCCESS = "CSAT_REPORT_SUCCESS"
const CSAT_REPORT_FAILURE = "CSAT_REPORT_FAILURE"

const CSAT_REPORT_DATA_REQUEST = "CSAT_REPORT_DATA_REQUEST"
const CSAT_REPORT_DATA_SUCCESS = "CSAT_REPORT_DATA_SUCCESS"
const CSAT_REPORT_DATA_FAILURE = "CSAT_REPORT_DATA_FAILURE"

const NPS_REPORT_DATA_REQUEST = "NPS_REPORT_DATA_REQUEST"
const NPS_REPORT_DATA_SUCCESS = "NPS_REPORT_DATA_SUCCESS"
const NPS_REPORT_DATA_FAILURE = "NPS_REPORT_DATA_FAILURE"

const COMPLAINTS_REPORT_DATA_REQUEST = "COMPLAINTS_REPORT_DATA_REQUEST"
const COMPLAINTS_REPORT_DATA_SUCCESS = "COMPLAINTS_REPORT_DATA_SUCCESS"
const COMPLAINTS_REPORT_DATA_FAILURE = "COMPLAINTS_REPORT_DATA_FAILURE"

const SURVEY_REPORT_REQUEST = "SURVEY_REPORT_REQUEST"
const SURVEY_REPORT_SUCCESS = "SURVEY_REPORT_SUCCESS"
const SURVEY_REPORT_FAILURE = "SURVEY_REPORT_FAILURE"

const ASPECT_REPORT_REQUEST = "ASPECT_REPORT_REQUEST"
const ASPECT_REPORT_SUCCESS = "ASPECT_REPORT_SUCCESS"
const ASPECT_REPORT_FAILURE = "ASPECT_REPORT_FAILURE"

const COMPLAINTS_REPORT_REQUEST = "COMPLAINTS_REPORT_REQUEST"
const COMPLAINTS_REPORT_SUCCESS = "COMPLAINTS_REPORT_SUCCESS"
const COMPLAINTS_REPORT_FAILURE = "COMPLAINTS_REPORT_FAILURE"

const GENERAL_REPORT_REQUEST = "GENERAL_REPORT_REQUEST"
const GENERAL_REPORT_SUCCESS = "GENERAL_REPORT_SUCCESS"
const GENERAL_REPORT_FAILURE = "GENERAL_REPORT_FAILURE"

const REVIEWS_REPORT_REQUEST = "REVIEWS_REPORT_REQUEST"
const REVIEWS_REPORT_SUCCESS = "REVIEWS_REPORT_SUCCESS"
const REVIEWS_REPORT_FAILURE = "REVIEWS_REPORT_FAILURE"

const SENTIMENT_REPORT_REQUEST = "SENTIMENT_REPORT_REQUEST"
const SENTIMENT_REPORT_SUCCESS = "SENTIMENT_REPORT_SUCCESS"
const SENTIMENT_REPORT_FAILURE = "SENTIMENT_REPORT_FAILURE"

const CSAT_NPS_RATINGS_REPORT_REQUEST = "CSAT_NPS_RATINGS_REPORT_REQUEST"
const CSAT_NPS_RATINGS_REPORT_SUCCESS = "CSAT_NPS_RATINGS_REPORT_SUCCESS"
const CSAT_NPS_RATINGS_REPORT_FAILURE = "CSAT_NPS_RATINGS_REPORT_FAILURE"


const init = {
  loading: false,
  reportCSAT: [],
  aspect: [],
  nps: [],
  csat: [],

  csatReport: [],
  npsReport: [],
  complaintsReport: [],

  survey: [],
  complaints: [],

  general: [],
  reviews: [],
  sentiment: false,
  csat_nps_ratings: {'months' : [], 'nps' : [], 'csat': []}
}

const Reports = (state = init, action) => {
  switch (action.type) {

    case NPS_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case NPS_REPORT_SUCCESS : {
      return { ...state, loading: false, nps: action.payload }
    }
    case NPS_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case CSAT_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case CSAT_REPORT_SUCCESS : {
      return { ...state, loading: false, csat: action.payload }
    }
    case CSAT_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case SURVEY_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case SURVEY_REPORT_SUCCESS : {
      return { ...state, loading: false, survey: action.payload }
    }
    case SURVEY_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case ASPECT_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case ASPECT_REPORT_SUCCESS : {
      return { ...state, loading: false, aspect: action.payload }
    }
    case ASPECT_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case COMPLAINTS_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case COMPLAINTS_REPORT_SUCCESS : {
      return { ...state, loading: false, complaints: action.payload }
    }
    case COMPLAINTS_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case GENERAL_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case GENERAL_REPORT_SUCCESS : {
      return { ...state, loading: false, general: action.payload }
    }
    case GENERAL_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case REVIEWS_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case REVIEWS_REPORT_SUCCESS : {
      return { ...state, loading: false, reviews: action.payload }
    }
    case REVIEWS_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case SENTIMENT_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case SENTIMENT_REPORT_SUCCESS : {
      return { ...state, loading: false, sentiment: action.payload }
    }
    case SENTIMENT_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }
    case CSAT_NPS_RATINGS_REPORT_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case CSAT_NPS_RATINGS_REPORT_SUCCESS : {
      return { ...state, loading: false, csat_nps_ratings: action.payload }
    }
    case CSAT_NPS_RATINGS_REPORT_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }



    case CSAT_REPORT_DATA_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case CSAT_REPORT_DATA_SUCCESS : {
      return { ...state, loading: false, csatReport: action.payload }
    }
    case CSAT_REPORT_DATA_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }

    case NPS_REPORT_DATA_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case NPS_REPORT_DATA_SUCCESS : {
      return { ...state, loading: false, npsReport: action.payload }
    }
    case NPS_REPORT_DATA_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }


    case COMPLAINTS_REPORT_DATA_REQUEST : {
      return { ...state, loading: true, error: false }
    }
    case COMPLAINTS_REPORT_DATA_SUCCESS : {
      return { ...state, loading: false, complaintsReport: action.payload }
    }
    case COMPLAINTS_REPORT_DATA_FAILURE : {
      return { ...state, loading: false, error: action.payload }
    }


    default : {
      return { ...state }
    }
  }
}

export default Reports

export const getComplaintReport = (filter) => dispatch => {
  dispatch({ type: COMPLAINTS_REPORT_REQUEST })
  axios.get("app/reports/complaints", { params: filter })
    .then(({ data }) => dispatch({ type: COMPLAINTS_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_REPORT_FAILURE, payload: message }))
}



export const getNPSReport = (filter) => dispatch => {
  dispatch({ type: NPS_REPORT_REQUEST })
  axios.get("app/reports/nps", { params: filter })
    .then(({ data }) => dispatch({ type: NPS_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: NPS_REPORT_FAILURE, payload: message }))
}

export const getCSATReport = (filter) => dispatch => {
  dispatch({ type: CSAT_REPORT_REQUEST })
  axios.get("app/reports/csat", { params: filter })
    .then(({ data }) => dispatch({ type: CSAT_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: CSAT_REPORT_FAILURE, payload: message }))
}
export const getCSATReportData = (filter) => dispatch => {
  dispatch({ type: CSAT_REPORT_DATA_REQUEST })
  axios.get("app/reports/download/csat", { params: filter })
    .then(({ data }) => dispatch({ type: CSAT_REPORT_DATA_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: CSAT_REPORT_DATA_FAILURE, payload: message }))
}
export const getNPSReportData = (filter) => dispatch => {
  dispatch({ type: NPS_REPORT_DATA_REQUEST })
  axios.get("app/reports/download/nps", { params: filter })
    .then(({ data }) => dispatch({ type: NPS_REPORT_DATA_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: NPS_REPORT_DATA_FAILURE, payload: message }))
}
export const getComplaintsReportData = (filter) => dispatch => {
  dispatch({ type: COMPLAINTS_REPORT_DATA_REQUEST })
  axios.get("app/reports/complaints/data", { params: filter })
    .then(({ data }) => dispatch({ type: COMPLAINTS_REPORT_DATA_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: COMPLAINTS_REPORT_DATA_FAILURE, payload: message }))
}

export const getSurveyReport = (filter) => dispatch => {
  dispatch({ type: SURVEY_REPORT_REQUEST })
  axios.get("app/reports/survey", { params: filter })
    .then(({ data }) => dispatch({ type: SURVEY_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SURVEY_REPORT_FAILURE, payload: message }))
}

export const getAspectReport = (filter) => dispatch => {
  dispatch({ type: ASPECT_REPORT_REQUEST })
  axios.get("app/reports/aspect", { params: filter })
    .then(({ data }) => dispatch({ type: ASPECT_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ASPECT_REPORT_FAILURE, payload: message }))
}

export const getGeneralReport = (filter) => dispatch => {
  dispatch({ type: GENERAL_REPORT_REQUEST })
  axios.get("app/reports/general", { params: filter })
    .then(({ data }) => dispatch({ type: GENERAL_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: GENERAL_REPORT_FAILURE, payload: message }))
}

export const getGeneralReviews = (filter) => dispatch => {
  dispatch({ type: REVIEWS_REPORT_REQUEST })
  axios.get("app/reports/reviews", { params: filter })
    .then(({ data }) => dispatch({ type: REVIEWS_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: REVIEWS_REPORT_FAILURE, payload: message }))
}

export const getWeeklyComplaints = (filter) => dispatch => {
  dispatch({ type: REVIEWS_REPORT_REQUEST })
  axios.get("app/reports/weekly/complaints", { params: filter })
    .then(({ data }) => dispatch({ type: REVIEWS_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: REVIEWS_REPORT_FAILURE, payload: message }))
}

export const getSentiment = () => dispatch => {
  dispatch({ type: SENTIMENT_REPORT_REQUEST })
  axios.get("app/reports/sentiment")
    .then(({ data }) => dispatch({ type: SENTIMENT_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: SENTIMENT_REPORT_FAILURE, payload: message }))
}


export const getCSATNPSRatings = () => dispatch => {
  dispatch({ type: CSAT_NPS_RATINGS_REPORT_REQUEST })
  axios.get("app/reports/ratings")
    .then(({ data }) => dispatch({ type: CSAT_NPS_RATINGS_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: CSAT_NPS_RATINGS_REPORT_FAILURE, payload: message }))
}



