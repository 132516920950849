import React, { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"


export const Thanks = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    setTimeout((function() {
      (window.location.pathname == `/thanks/${id}`) && navigate(`/form/${id}`)
    }), 5000)
  }, [])


  return <React.Fragment>
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
      </div>
      <section className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col className="col-12 text-center">
              <div className="home-wrapper">


                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="maintenance-img">
                      <i className="mdi mdi-thumb-up-outline mx-auto d-block text-warning"
                         style={{ fontSize: "150px" }} />
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-5">Thank you for your feedback</h3>
                <p>Your feedback goes a long way helping us improve our services.</p>
                <Button onClick={() => navigate(`/form/${id}`)} className="btn btn-soft-primary btn-lg m-5">Back to
                  Form</Button>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>

  </React.Fragment>
}
