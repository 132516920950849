import {
  AUTHENTICATION_LOGIN_FAILURE,
  AUTHENTICATION_LOGIN_REQUEST,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_LOGOUT_FAILURE,
  AUTHENTICATION_LOGOUT_REQUEST,
  AUTHENTICATION_LOGOUT_SUCCESS,

} from "./authenticationTypes"

const init = {
  user: false,
  loggedIn: false,
  loading: false,
  error: false
}


const Authentication = (state = init, action) => {
  switch (action.type) {

    case AUTHENTICATION_LOGIN_REQUEST : {
      return { ...state, loading: true, error: false, loggedIn: false, user: false }
    }
    case AUTHENTICATION_LOGIN_SUCCESS : {
      return { ...state, loading: false, error: false, loggedIn: true, user: action.payload }
    }
    case AUTHENTICATION_LOGIN_FAILURE : {
      return { ...state, loading: false, error: action.payload, loggedIn: false, user: false }
    }

    case AUTHENTICATION_LOGOUT_REQUEST : {
      return { ...state, loading: true, error: false, loggedIn: false, user: false }
    }
    case AUTHENTICATION_LOGOUT_SUCCESS : {
      return { ...state, loading: true, error: false, loggedIn: false, user: false }
    }
    case AUTHENTICATION_LOGOUT_FAILURE : {
      return { ...state, loading: true, error: action.payload, loggedIn: false, user: false }
    }

    default : {
      return { ...state }
    }


  }
}

export default Authentication
