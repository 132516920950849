import React from "react"
import axios from "axios"
import { handleError } from "../../helpers/handleError"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap"
import { UploadDropZone } from "../component/upload"
import toastr from 'toastr'

export default class BulkEmailNotificationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false, form: false, fields: false, cover: false, contacts: false, show: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    prevProps.isOpen !== this.props.isOpen && this.props.isOpen == true && this.init()
  }

  init = () => {
    const url = `app/bulk/form`
    const data = { id: this.props.id }
    this.setState({ loading: true })
    axios.post(url, data)
      .then(({ data }) => this.setState({ form: data, fields: data.fields }))
      .catch((e) => handleError(e))
      .finally(() => this.setState({ loading: false }))
  }


  handleOnChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { loading, form, contacts, cover, fields, show } = this.state
    const { isOpen, toggle } = this.props
    const coverToggle = () => {
      this.setState({ cover: !this.state.cover })
    }
    const contactsToggle = () => {
      this.setState({ contacts: !this.state.contacts })
    }

    const handleSubmit = () => this.props.onSubmit(fields)
    return <Modal isOpen={isOpen} toggle={toggle} className="modal-xl">
      <ModalHeader toggle={toggle}>
        Create Bulk Email
      </ModalHeader>
      <ModalBody>
        <div style={{
          minHeight: 100,
          backgroundImage: `url("${fields?.cover ? fields.cover : null}")`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}>
          <Button style={{ marginTop: 100 }} className="btn mt-5 btn-block btn-soft-success" onClick={coverToggle}>Upload
            Cover Image</Button>
        </div>


        <FormGroup>
          <Label htmlFor="input-subject">Subject</Label>
          <Input name="subject" id="input-subject" value={fields.subject}
                 onChange={e => this.handleOnChange("subject", e.target.value)} />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="input-body">Email Body</Label>
          <Input name="body" id="input-body" value={fields.body}
                 type="textarea"
                 rows={10}
                 onChange={e => this.handleOnChange("body", e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>Contacts</Label>
          <Button className="btn btn-sm btn-soft-info" onClick={() => contactsToggle()}>Add Contacts</Button>
        </FormGroup>
        <Card>
          <CardBody>
            {(form?.emails || []).map((email, index) => email && <span key={index}>&lt;{email}&gt; </span>)}
          </CardBody>
        </Card>

      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button onClick={toggle} className="btn btn-soft-danger">Close</Button>
          <Button onClick={handleSubmit} className="btn btn-soft-primary">Save Changes</Button>
        </ButtonGroup>
      </ModalFooter>

      <Modal isOpen={cover} toggle={coverToggle} className="modal-dialog-centered">
        <ModalBody>
          <UploadDropZone url={`app/files/upload`} message={"Upload Company Cover"} callBack={console.log}
                          onUpload={(file) => {
                            this.handleOnChange("cover", file.url)
                            coverToggle()
                          }} />
        </ModalBody>
      </Modal>

      <Modal isOpen={contacts} toggle={contactsToggle} className="modal-dialog-centered">
        <ModalBody>
          <UploadDropZone url={`app/contacts/upload`} message={"Upload Contacts"}
                          callBack={console.log}
                          onUpload={(res) => {
                            toastr.success(`${res.length} Contacts Uploaded`)
                            contactsToggle()
                          }} />
        </ModalBody>
      </Modal>
    </Modal>


  }

}