import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { selectColorStyles } from "../../components/selectColorStyle"
import PerfectScrollbar from "react-perfect-scrollbar"
import { complaintComment, complaintResolve } from "../../store/complaints/complaintsReducer"
import moment from "moment"
import { StatusBadge } from "../../components/general"
import { ComplainFormModal } from "./complainModal"
import {
  customerFeedbackAssignManager,
  customerFeedbackChatSubmit,
  customerFeedbackResolve
} from "../../store/feedback/feedbackActions"
import { customerFeedbackFind } from "../../store/customerFeedback/customerFeedback"
import { userGet } from "../../store/users/userActions"


export const FeedbackComplainFormModal = ({ isOpen, get, toggle, customer_feedback }) => {

  const dispatch = useDispatch()
  const { feedback, loading, users } = useSelector(state => ({
    feedback: state.CustomerFeedback.customer_feedback,
    loading: state.CustomerFeedback.loading,
    users: state.Users.users
  }))


  const [active, setActive] = useState(0)

  const [messageBox, setMessageBox] = useState(null)

  const [form, setForm] = useState(false)
  const [complaint, setComplaint] = useState(false)
  const [comment, setComment] = useState(false)
  const toggleActive = (active) => setActive(active)
  const [resolution_reasons, set_resolution_reasons] = useState(customer_feedback.resolution_reasons)

  const onResolve = () => {
    dispatch(customerFeedbackResolve({ id: customer_feedback.id, resolution_reasons }))
    setComment(!comment)
  }


  const addMessage = () => {
    dispatch(customerFeedbackChatSubmit({ id: customer_feedback.id, chat }, refresh))
    setChat("")
  }


  const [chat, setChat] = useState("")

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setMessageBox(value)
      addMessage()
    }
  }


  const refresh = () => {
    if (!users.data || users.data.length == 0) {
      dispatch(userGet())
    }
    dispatch(customerFeedbackFind(customer_feedback.id))
  }

  useEffect(() => {
    refresh()
  }, [customer_feedback])

  return <Modal isOpen={isOpen} toggle={toggle} className="modal-lg modal-dialog-centered">
    <ModalHeader>
      <CardTitle>{customer_feedback.form_name}</CardTitle>
    </ModalHeader>
    <ModalBody>
      <div className="float-end text-right">
        <div>AVG Rating : Poor : {customer_feedback.avg_rating} <i className="mdi mdi-star text-danger" /></div>
        <div>{moment(customer_feedback.created_at).format("Do MMM YYYY HH:mm")} </div>
        <div className="text-right"><StatusBadge status={customer_feedback.complaints_status} /></div>

      </div>
      <div>
        <dl>
          <dt>Customer</dt>
          <dd><strong>Name : </strong>{customer_feedback.customer_name}</dd>
          {customer_feedback.customer_email && <dd><strong>Email : </strong>{customer_feedback.customer_email}</dd>}
          {customer_feedback.customer_mobileno &&
            <dd><strong>Phone : </strong>{customer_feedback.customer_mobileno}</dd>}
        </dl>
      </div>
      <Table condesed striped>
        <thead>
        <tr>
          <th>Question</th>
          <th>Rating</th>
          <th>Overall Remarks</th>
        </tr>
        </thead>
        <tbody>
        {(customer_feedback.complaints || []).map(row => <tr key={row.id}>
          <td>{row.question}</td>
          <td>
            {row.rating}{row.question_type === "NPS" ? "/10" : "/5"}
          </td>
          <td className="text-danger">{row.overall_remarks ? row.overall_remarks : "No Remarks"}</td>
        </tr>)}
        </tbody>
      </Table>
      {customer_feedback.complaints_status === "closed" &&
        <Alert className="alert-success">{customer_feedback.resolution_reasons}</Alert>}
      <div>
        <div className="chat-conversation p-3">
          <ul className="list-unstyled">
            <PerfectScrollbar
              style={{ height: "270px" }}
              containerRef={ref => setMessageBox(ref)}
            >
              {(feedback.customer_feedback_complaint_comments || []).map((message) => (
                <li
                  key={"test_k" + message.id}
                  className={
                    // message.sender === currentUser.name
                    false
                      ? "right"
                      : ""
                  }
                >
                  <div className="conversation-list">

                    <div className="ctext-wrap">

                      <div className="conversation-name">
                        {message.user ? message.user.name : "unknown"}
                      </div>
                      <p>{message.comments}</p>
                      <p className="chat-time mb-0"><i
                        className="bx bx-time-five align-middle me-1"></i> {moment(message.created_at).fromNow()}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </PerfectScrollbar>
          </ul>
        </div>
        {customer_feedback && customer_feedback.complaints_status !== "closed" && <>
          <div className="p-3 chat-input-section">
            <Row>
              <Col>
                <div className="position-relative">
                  <input
                    type="text"
                    value={chat}
                    onKeyPress={onKeyPress}
                    onChange={e => setChat(e.target.value)}
                    className="form-control chat-input"
                    placeholder="Enter Message..."
                  />

                </div>
              </Col>
              <Col className="col-auto">
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    addMessage()
                  }

                  }
                  className="btn btn-primary btn-rounded chat-send w-md "
                >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>{" "}
                  {!loading && <i className="mdi mdi-send" />}
                  {loading && <i className="mdi mdi-spin" />}
                </Button>
              </Col>
            </Row>
          </div>
        </>}
      </div>
      {customer_feedback && customer_feedback.complaints_status !== "closed" && <FormGroup>
        <Select
          className="m-3 mt-0"
          placeholder="Tag Users"
          defaultValue={customer_feedback.complaints_manager}
          onChange={(user) => dispatch(customerFeedbackAssignManager({ id: customer_feedback.id, user: user.id }))}
          options={users?.data}
          styles={selectColorStyles} />
      </FormGroup>}
    </ModalBody>
    <ModalFooter>
      {customer_feedback.complaints_status !== "closed" &&
        <Button className="btn btn-soft-success" onClick={() => setComment(!comment)}>Mark As Resolved</Button>}
      <Button onClick={toggle} className="btn btn-light">Close</Button>
    </ModalFooter>
    <Modal className="modal modal-dialog-centered" isOpen={comment} toggle={() => setComment(!comment)}>
      <>
        <ModalHeader>
          Resolution Comment
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={resolution_reasons}
            onChange={e => set_resolution_reasons(e.target.value)}
            placeholder="Enter Resolution Comments..."
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setComment(!comment)} className="btn btn-light">Close</Button>
          {customer_feedback && customer_feedback.complaint_status !== "closed" &&
            <Button onClick={() => onResolve()}
                    className="btn btn-soft-success">
              Resolve
            </Button>}
        </ModalFooter>
      </>
    </Modal>

    <ComplainFormModal
      onComment={(id, comment, tagged) => {
        get()
        setForm(false)
        dispatch(complaintComment({ complaint: id, comment, users: tagged }))
      }}
      onSubmit={(id, comment, tagged) => {
        get()
        setForm(false)
        dispatch(complaintResolve({ complaint: id, comment, users: tagged }))
      }}
      isOpen={form} toggle={() => setForm(!form)} complaintID={complaint} />

  </Modal>

}
