import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { Pre } from "../../components/pre"
import { departmentDelete, departmentGet, departmentPost } from "../../store/departments/depatmentActions"
import { Link } from "react-router-dom"
import moment from "moment"
import { DataTableList } from "../../components/tables/dataTable"
import CompanyForm from "../company/forms/companyForm"
import { companyPost } from "../../store/companies/companyActions"
import DepartmentForm from "./forms/departmentForm"

export const Departments = props => {
  const dispatch = useDispatch()
  const { Department } = useSelector(state => state)

  const { loading, departments } = Department

  const [department, setDepartment] = useState(false)
  const [form, setForm] = useState(false)


  const get = () => dispatch(departmentGet())

  const onEdit = (department) => {
    setDepartment(department.id)
    setForm(true)
  }

  const toggleForm = () => {
    setDepartment(false)
    setForm(false)
  }

  const onCreate = () => {
    setDepartment(0)
    setForm(true)
  }


  document.title = "Companies | Q-SYS"
  useEffect(() => get(), [])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={loading ? "Loading" : "Departments"}
          breadcrumbItem="Departments"
        />

        <Card className="bg-transparent">
          <CardBody>

            <div className="btn-group float-end">
              <Button onClick={onCreate} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Button>
            </div>
            <CardTitle>Departments </CardTitle>

          </CardBody>


          {/*<Pre data={Department} />*/}

          <DepartmentsList
            get={get}
            data={Department.departments.data}
            loading={loading}

          />

        </Card>

      </Container>
    </div>
    <DepartmentForm toggle={toggleForm}
                    isOpen={form}
                    department={department}
                    saveChanges={data => {
                      setForm(false)
                      dispatch(departmentPost(data, get))
                      // setCompany(false)
                    }

                    } />
  </React.Fragment>
}


export const DepartmentsList = props => {


  const dispatch = useDispatch()

  const onDelete = item => dispatch(departmentDelete(item.id))

  const [form, setForm] = useState(false)

  const [department, setDepartment] = useState(0)

  const onEdit = item => {
    setDepartment(item.id)
    toggleForm()
  }

  const toggleForm = () => setForm(!form)
  const columns = [
    // {
    //   name: "#",
    //   selector: "id",
    //   style: { width: 60, alignText: "center", fontWeight: "bold" },
    //   cell: (row, index) => index + 1
    // },
    {
      name: "Logo",
      selector: "id",
      style: { width: 60, alignText: "center" },
      cell: (row) => <img src={require("../../assets/images/companies/img-1.png")} height={25} />
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => <>
        <div>
          <Link to={`/department/details/${row.id}`}><strong>{row.department_name}</strong></Link>
        </div>
        <div>
          <><small>{row.company_name} {row.branch_name && <i className="mdi mdi-chevron-double-right" />}{row.branch_name}</small></>
        </div>
      </>
    },
    {
      name: "CSAT",
      selector: "csat",
      center: true,
      omit: !props.showStats,
      cell: ({CSAT}) => CSAT
    },
    {
      name: "NPS",
      selector: "NPS",
      center: true,
      omit: !props.showStats,
      cell: ({NPS}) => NPS
    },
    // {
    //   name: "Reviews",
    //   selector: "nps",
    //   center: true,
    //   omit: !props.showStats,
    //   cell: (row) => 0
    // },

    // {
    //   name: "Promoters",
    //   selector: "nps",
    //   center: true,
    //   omit: !props.showStats,
    //   cell: ({ promoters }) => promoters
    // },
    // {
    //   name: "Detractors",
    //   selector: "nps",
    //   center: true,
    //   omit: !props.showStats,
    //   cell: ({detractors}) => detractors
    // },
    // {
    //   name: "Passive",
    //   selector: "nps",
    //   center: true,
    //   omit: !props.showStats,
    //   cell: ({passive}) => passive
    // },
    {
      name: "",
      selector: "id",
      className: "flex-end",
      omit: props.hideActions,
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          // href="#"
          className="card-drop"
          tag="a"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            href="#"
            onClick={() => onEdit(row)}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>
          <DropdownItem
            href="#"
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]
  return <>
    {props.data && props.data.length > 0 && <>
      <DataTableList
        hidePagination
        columns={columns}
        get={props.get}
        className={`project-list-table table-nowrap align-middle table-borderless`}
        current_page={1}
        last_page={2}
        data={props.data}
        callBack={props.get}
        loading={props.loading}

      />
    </>

    }

    <DepartmentForm toggle={toggleForm}
                    isOpen={form}
                    department={department}
                    saveChanges={data => {
                      setForm(false)
                      dispatch(departmentPost(data, props.get))
                      // setCompany(false)
                    }

                    } />
  </>

}


