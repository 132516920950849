import axios from "axios"

const TEMPLATE_GET_REQUEST = 'TEMPLATE_GET_REQUEST';
const TEMPLATE_GET_SUCCESS = 'TEMPLATE_GET_SUCCESS';
const TEMPLATE_GET_FAILURE = 'TEMPLATE_GET_FAILURE';

const TEMPLATE_FIND_REQUEST = 'TEMPLATE_FIND_REQUEST';
const TEMPLATE_FIND_SUCCESS = 'TEMPLATE_FIND_SUCCESS';
const TEMPLATE_FIND_FAILURE = 'TEMPLATE_FIND_FAILURE';

const TEMPLATE_ACTION_REQUEST = 'TEMPLATE_ACTION_REQUEST';
const TEMPLATE_ACTION_SUCCESS = 'TEMPLATE_ACTION_SUCCESS';
const TEMPLATE_ACTION_FAILURE = 'TEMPLATE_ACTION_FAILURE';

const init = {
  templates:[],
  template: false,
  loading: false,
  errors: false
}

const Templates = (state = init, action) => {
  switch (action.type){

    case TEMPLATE_GET_REQUEST : {return {...state, loading : true, errors: false, }}
    case TEMPLATE_GET_SUCCESS : {return {...state, loading : false, errors: false, templates: action.payload}}
    case TEMPLATE_GET_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case TEMPLATE_FIND_REQUEST : {return {...state, loading : true, template: false, errors: false,}}
    case TEMPLATE_FIND_SUCCESS : {return {...state, loading : false, errors: false, template: action.payload}}
    case TEMPLATE_FIND_FAILURE : {return {...state, loading : false, errors:  action.payload}}

    case TEMPLATE_ACTION_REQUEST : {return {...state, loading : true, errors: false}}
    case TEMPLATE_ACTION_SUCCESS : {return {...state, loading : false, errors: false}}
    case TEMPLATE_ACTION_FAILURE : {return {...state, loading : false, errors: action.payload}}


    default : {return {...state}}
  }
}

export default Templates;


export const templateGet = () => dispatch => {
  dispatch({type: TEMPLATE_GET_REQUEST})
  axios.get('app/template/get')
    .then(({data}) => dispatch({type: TEMPLATE_GET_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: TEMPLATE_GET_FAILURE, payload: message}))
}
export const templateFind = (id) => dispatch => {
  dispatch({type: TEMPLATE_FIND_REQUEST})
  axios.get(`app/template/get/${id}`)
    .then(({data}) => dispatch({type: TEMPLATE_FIND_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: TEMPLATE_FIND_FAILURE, payload: message}))
}

export const templateDelete = (id) => dispatch => {
  dispatch({type: TEMPLATE_FIND_REQUEST})
  axios.post(`app/template/delete`, {id})
    .then(({data}) => dispatch({type: TEMPLATE_FIND_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: TEMPLATE_FIND_FAILURE, payload: message}))
    .finally(() => dispatch(templateGet()))
}


export const templateSubmit = (data) => dispatch => {
  dispatch({type: TEMPLATE_FIND_REQUEST})
  axios.post(`app/template/submit`, data)
    .then(({data}) => {
      dispatch({ type: TEMPLATE_FIND_SUCCESS, payload: data })
      dispatch(templateFind(data.id))
    })
    .catch(({message}) => dispatch({type: TEMPLATE_FIND_FAILURE, payload: message}))
    .finally(() => dispatch(templateGet()))
}



