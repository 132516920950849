import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { messagesGet } from "../../../store/messages/messageActions"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const dispatch = useDispatch()
  const { messages, loading } = useSelector(state => ({
    messages: state.Messages.messages,
    loading: state.Messages.loading
  }))


  useEffect(() => {menu && dispatch(messagesGet())}, [menu])


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {messages?.unread > 0 &&  <span className="badge bg-danger rounded-pill">{messages?.unread}</span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to={`/profile/messages`} className="small">
                  {" "}
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>

            {((messages && messages.data) || []).map((row, key) => <Link key={key}
                                                                        to={`/profile/messages/${row.id}`}
                                                                        className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar rounded-circle ">
                   <img
                     src={avatar3}
                     className="me-3 rounded-circle avatar-xs"
                     alt="user-pic"
                   />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">
                    {row.from_user?.name}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {row.subject}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {moment(row.created_at).fromNow()}
                    </p>
                  </div>
                </div>
              </div>
            </Link>)}

          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/profile/messages">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span
              key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}
