import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { resetPassword } from "../../../store/users/userActions"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"


export default function ResetPasswordForm({ isOpen, user, toggle, callBack }) {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [new_password, set_new_password] = useState("")
  const [confirm_password, set_confirm_password] = useState("")

  const generate = () => {
    const password = (Math.random() + 1).toString(36).substring(7)
    setShow(true)
    set_new_password(password)
    set_confirm_password(password)
  }

  useEffect(() => {
    setShow(false)
    set_new_password('')
    set_confirm_password('')
  }, [isOpen])

  const onSubmit = () => {
    dispatch(resetPassword({ id: user, password: new_password }, callBack))
  }

  return <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader>
      Reset User Password
    </ModalHeader>
    <ModalBody>
      <FormGroup>
        <Label>New Password</Label>
        <Input type={show ? 'text' : 'password'} onChange={e => set_new_password(e.target.value)} value={new_password}
               placeholder="New Password" />
      </FormGroup>
      <FormGroup>
        <Label>Confirm Password</Label>
        <Input type={show ? 'text' : 'password'} onChange={e => set_confirm_password(e.target.value)} value={confirm_password}
               placeholder="Confirm Password" />
      </FormGroup>
      <Button className="btn-light" onClick={() => setShow(!show)}><i className={!show ? "mdi mdi-eye" : "mdi mdi-eye-off"} /> {show ? "Hide" : "Show"} Password</Button>

    </ModalBody>
    <ModalFooter>
      <Button type="button" className="btn btn-light" onClick={toggle}>Close </Button>
      <Button type="button" className="btn btn-light" onClick={generate}>Generate </Button>
      <Button type="button" className="btn btn-soft-primary" onClick={onSubmit}>Submit </Button>
    </ModalFooter>
  </Modal>
}
