import axios from "axios"

export const FEEDBACK_COMPLAINTS_GET_REQUEST = "FEEDBACK_COMPLAINTS_GET_REQUEST"
export const FEEDBACK_COMPLAINTS_GET_SUCCESS = "FEEDBACK_COMPLAINTS_GET_SUCCESS"
export const FEEDBACK_COMPLAINTS_GET_FAILURE = "FEEDBACK_COMPLAINTS_GET_FAILURE"

export const FEEDBACK_COMPLAINTS_REPORT_REQUEST = "FEEDBACK_COMPLAINTS_REPORT_REQUEST"
export const FEEDBACK_COMPLAINTS_REPORT_SUCCESS = "FEEDBACK_COMPLAINTS_REPORT_SUCCESS"
export const FEEDBACK_COMPLAINTS_REPORT_FAILURE = "FEEDBACK_COMPLAINTS_REPORT_FAILURE"

export const FEEDBACK_COMPLAINTS_DATA_REQUEST = "FEEDBACK_COMPLAINTS_DATA_REQUEST"
export const FEEDBACK_COMPLAINTS_DATA_SUCCESS = "FEEDBACK_COMPLAINTS_DATA_SUCCESS"
export const FEEDBACK_COMPLAINTS_DATA_FAILURE = "FEEDBACK_COMPLAINTS_DATA_FAILURE"

export const FEEDBACK_COMPLAINTS_FIND_REQUEST = "FEEDBACK_COMPLAINTS_FIND_REQUEST"
export const FEEDBACK_COMPLAINTS_FIND_SUCCESS = "FEEDBACK_COMPLAINTS_FIND_SUCCESS"
export const FEEDBACK_COMPLAINTS_FIND_FAILURE = "FEEDBACK_COMPLAINTS_FIND_FAILURE"

export const FEEDBACK_COMPLAINTS_ACTION_REQUEST = "FEEDBACK_COMPLAINTS_ACTION_REQUEST"
export const FEEDBACK_COMPLAINTS_ACTION_SUCCESS = "FEEDBACK_COMPLAINTS_ACTION_SUCCESS"
export const FEEDBACK_COMPLAINTS_ACTION_FAILURE = "FEEDBACK_COMPLAINTS_ACTION_FAILURE"

const init = {
  feedback_complaint: false,
  feedback_complaints: [],
  rating: false,
  loading: false,
  report: false,
  data: []
}

export const FeedbackComplaints = (state = init, action) => {

  switch (action.type) {
    case FEEDBACK_COMPLAINTS_GET_REQUEST : {
      return { ...state, loading: true }
    }
    case FEEDBACK_COMPLAINTS_GET_SUCCESS : {
      return { ...state, loading: false, feedback_complaints: action.payload }
    }
    case FEEDBACK_COMPLAINTS_GET_FAILURE : {
      return { ...state, loading: false }
    }

    case FEEDBACK_COMPLAINTS_REPORT_REQUEST : {
      return { ...state, loading: true }
    }
    case FEEDBACK_COMPLAINTS_REPORT_SUCCESS : {
      return { ...state, loading: false, report: action.payload }
    }
    case FEEDBACK_COMPLAINTS_REPORT_FAILURE : {
      return { ...state, loading: false }
    }
    case FEEDBACK_COMPLAINTS_DATA_REQUEST : {
      return { ...state, loading: true }
    }
    case FEEDBACK_COMPLAINTS_DATA_SUCCESS : {
      return { ...state, loading: false, data: action.payload }
    }
    case FEEDBACK_COMPLAINTS_DATA_FAILURE : {
      return { ...state, loading: false }
    }

    case FEEDBACK_COMPLAINTS_ACTION_REQUEST : {
      return { ...state, loading: true }
    }
    case FEEDBACK_COMPLAINTS_ACTION_SUCCESS : {
      return { ...state, loading: false }
    }
    case FEEDBACK_COMPLAINTS_ACTION_FAILURE : {
      return { ...state, loading: false }
    }

    case FEEDBACK_COMPLAINTS_FIND_REQUEST : {
      return { ...state, feedback_complaint: false, loading: true }
    }
    case FEEDBACK_COMPLAINTS_FIND_SUCCESS : {
      return { ...state, loading: false, feedback_complaint: action.payload }
    }
    case FEEDBACK_COMPLAINTS_FIND_FAILURE : {
      return { ...state, loading: false }
    }


    default : {
      return { ...state }
    }
  }
}


export const feedbackComplaintsGet = (filter) => dispatch => {
  dispatch({ type: FEEDBACK_COMPLAINTS_GET_REQUEST })
  axios.get("app/feedbacks/complaints", { params: filter })
    .then(({ data }) => dispatch({ type: FEEDBACK_COMPLAINTS_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: FEEDBACK_COMPLAINTS_GET_FAILURE, payload: message }))

}

export const feedbackComplaintsChart = () => dispatch => {
  dispatch({ type: FEEDBACK_COMPLAINTS_REPORT_REQUEST })
  axios.get("app/feedbacks/complaints/report")
    .then(({ data }) => dispatch({ type: FEEDBACK_COMPLAINTS_REPORT_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: FEEDBACK_COMPLAINTS_REPORT_FAILURE, payload: message }))
}

export const feedbackComplaintsData = () => dispatch => {
  dispatch({ type: FEEDBACK_COMPLAINTS_DATA_REQUEST })
  axios.get("app/feedbacks/complaints/data")
    .then(({ data }) => dispatch({ type: FEEDBACK_COMPLAINTS_DATA_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: FEEDBACK_COMPLAINTS_DATA_FAILURE, payload: message }))
}

export const feedbackComplaintsFind = (id) => dispatch => {
  dispatch({ type: FEEDBACK_COMPLAINTS_FIND_REQUEST })
  axios.get(`app/feedbacks/complaints/${id}`)
    .then(({ data }) => dispatch({ type: FEEDBACK_COMPLAINTS_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: FEEDBACK_COMPLAINTS_FIND_FAILURE, payload: message }))
}

