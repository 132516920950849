import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { feedbackGet } from "../../store/feedback/feedbackActions"
import { FeedbackList } from "./feedbackList"
import { Link } from "react-router-dom"

export const Feedbacks = props => {

  const { Feedback } = useSelector(state => state)

  const { loading, feedbacks } = Feedback

  const dispatch = useDispatch()

  const get = () => dispatch(feedbackGet())

  useEffect(() => get(), [])

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="Feedback"
        />
        <Card className="bg-transparent">
          <CardBody>

            <div className="btn-group float-end">

              <Link to={`/feedback/form/0`} className="btn btn-primary btn-soft-primary">
                <i className="mdi mdi-plus font-size-18"></i>
                Create
              </Link>
            </div>
            <CardTitle>Questions </CardTitle>

          </CardBody>


          <FeedbackList get={get} loading={loading} data={feedbacks.data} />
        </Card>
        {/*<Pre data={Feedback} />*/}

      </Container>
    </div>
  </React.Fragment>


}
