import {
  ESCALATION_GROUP_GET_REQUEST,
  ESCALATION_GROUP_GET_SUCCESS,
  ESCALATION_GROUP_GET_FAILURE,
  ESCALATION_GROUP_FIND_REQUEST,
  ESCALATION_GROUP_FIND_SUCCESS,
  ESCALATION_GROUP_FIND_FAILURE,
  ESCALATION_GROUP_ACTION_REQUEST,
  ESCALATION_GROUP_ACTION_SUCCESS,
  ESCALATION_GROUP_ACTION_FAILURE
} from "./escalationGroupTypes"


import axios from "axios"
import { handleError } from "../../helpers/handleError"


export const escalationGroupGet = () => dispatch => {
  dispatch({ type: ESCALATION_GROUP_GET_REQUEST })
  axios.get("/app/escalation/group/get")
    .then(({ data }) => dispatch({ type: ESCALATION_GROUP_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ESCALATION_GROUP_GET_FAILURE, payload: message }))
}

export const escalationGroupFind = (id) => dispatch => {
  dispatch({ type: ESCALATION_GROUP_FIND_REQUEST })
  axios.get(`/app/escalation/group/get/${id}`)
    .then(({ data }) => dispatch({ type: ESCALATION_GROUP_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: ESCALATION_GROUP_FIND_FAILURE, payload: message }))
}

export const escalationGroupDelete = (id, callBack) => dispatch => {
  dispatch({ type: ESCALATION_GROUP_ACTION_REQUEST })
  axios.post(`/app/escalation/group/delete`, { id })
    .then(({ data }) => {
      dispatch({ type: ESCALATION_GROUP_ACTION_SUCCESS, payload: data })
      dispatch(escalationGroupGet())

    })
    .catch(({ message }) => dispatch({ type: ESCALATION_GROUP_ACTION_FAILURE, payload: message }))
    .finally(callBack)
}

export const escalationGroupPost = (data, callBack) => dispatch => {
  dispatch({ type: ESCALATION_GROUP_ACTION_REQUEST })
  axios.post(`/app/escalation/group/submit`, data)
    .then(({ data }) => {
      dispatch({ type: ESCALATION_GROUP_ACTION_SUCCESS, payload: data })
      if (!callBack) {
        dispatch(escalationGroupGet())
        dispatch(escalationGroupFind(data.id))
      } else {
        callBack(data)
      }
    })
    .catch((e) => handleError(e, dispatch({ type: ESCALATION_GROUP_ACTION_FAILURE, payload: e.message })))
}
