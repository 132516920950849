    import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container, Form, FormGroup, Input,
  Label,
  Row,
  Table
} from "reactstrap"
import Select from "react-select"
import { feedbackForm, feedbackPost } from "../../store/feedback/feedbackActions"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useParams, useNavigate } from "react-router-dom"
import FeedbackFormFields from "./FeedbackFormFields"
import FeedbackFormQuestions from "./FeedbackFormQuestions"


export const FeedbackForm = props => {
  const { id } = useParams()
  const navigate = useNavigate();
  const [toggleSwitch, settoggleSwitch] = useState(true)

  const [companies, setCompanies] = useState([])
  const [branches, setBranches] = useState([])
  const [departments, setDepartments] = useState([])

  const { Feedback } = useSelector(state => state)

  const { loading, feedback, form } = Feedback

  const dispatch = useDispatch()

  // FIELDS

  const [companies_id, set_companies_id] = useState(false)
  const [branches_id, set_branches_id] = useState(false)
  const [departments_id, set_departments_id] = useState(false)
  const [escalation_groups_id, set_escalation_groups_id] = useState(false)
  const [form_name, set_form_name] = useState("")
  const [type, set_type] = useState("Single")
  const [feedback_form_fields, set_feedback_form_fields] = useState([])
  const [feedback_form_questions, set_feedback_form_questions] = useState([])

  // end fields


  useEffect(() => {
    form && set_companies_id(form.fields.companies_id)
    form && set_branches_id(form.fields.branches_id)
    form && set_departments_id(form.fields.departments_id)
    form && set_escalation_groups_id(form.fields.escalation_groups_id)
    form && set_form_name(form.fields.form_name)
    form && set_type(form.fields.type)
    form && set_feedback_form_fields(form.fields.feedback_form_fields)
    form && set_feedback_form_questions(form.fields.feedback_form_questions)

    // console.log(form.fields.feedback_form_questions)
  }, [form])


  const get = () => {
    dispatch(feedbackForm(id))
  }

  const handleSubmit = () => {
    const data = {
      id,
      companies_id,
      branches_id,
      departments_id,
      escalation_groups_id,
      form_name,
      type,
      feedback_form_fields,
      feedback_form_questions
    }

    dispatch(feedbackPost(data, (data) => {
      navigate(`/feedback/${data.id}`)
    }))

  }

  useEffect(() => get(), [])


  const PreviewAttributesComponent = () => {
    return <Form onSubmit={(e) => {

      e.preventDefault()
    }}>
      {feedback_form_fields.map(row => row.hidden === "no" && <FormGroup key={`feedback-form-field-${row.id}`}>
        <Label>{row.label} {row.mandatory === "yes" && "*"}</Label>
        <Input placeholder={row.placeholder} type={row.type} />
      </FormGroup>)}
    </Form>
  }

  const PreviewQuestionsComponent = () => {

    return <>


      {form.aspect.map(({ id, aspect_name }) => {
        const aspect_questions = form.questions.filter(({ aspect_id }) => aspect_id == id)

        let found_question_flag = false;
        aspect_questions.map(({id}, index) => {
          if(feedback_form_questions.includes(id)) found_question_flag = true;
        })

        if(!found_question_flag) return <></>


        return <>
          {aspect_questions.length > 0 && <CardBody key={`aspect-${id}`}>
            <CardTitle> {aspect_name} </CardTitle>
            <ul className="list-group">
              {aspect_questions.map((row, index) => {
                if (!feedback_form_questions.includes(row.id)) return <></>
                return <li className="list-group-item" key={`question-${row.id}`}>{index + 1}. {row.question}</li>
              })}
            </ul>

          </CardBody>}
        </>


      })}
    </>

  }

  return <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="Feedback"
        />

        {form && <>
          <Row>
            <Col sm={4}>
              <Card>
                <CardBody>
                  <CardTitle>Add Form Fields</CardTitle>

                  <FeedbackFormFields
                    feedback_form_fields={form.fields.feedback_form_fields}
                    onChange={data => set_feedback_form_fields(data)} />
                  <div>
                    <div className="mb-3">
                      <Select
                        value={companies_id}
                        placeholder="Select Companies"
                        onChange={(e) => {
                          set_companies_id(e)
                        }}
                        options={form.companies}
                        className="select2-selection"
                      />
                    </div>
                    <div className="mb-3">
                      <Select
                        value={branches_id}
                        placeholder="Select Branches"
                        onChange={(e) => {
                          set_branches_id(e)
                        }}
                        options={form.branches}
                        className="select2-selection"
                      />
                    </div>
                    <div className="mb-3">
                      <Select
                        value={departments_id}
                        placeholder="Select Departments"
                        onChange={(e) => {
                          set_departments_id(e)
                        }}
                        options={form.departments}
                        className="select2-selection"
                      />
                    </div>
                    <div className="mb-3">
                      <Select
                        value={escalation_groups_id}
                        placeholder="Select Escalation Group"
                        onChange={(e) => {
                          set_escalation_groups_id(e)
                        }}
                        options={form.escalation_groups}
                        className="select2-selection"
                      />
                    </div>


                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={8}>
              <Card>
                <CardHeader>
                  <ButtonGroup className="float-end">
                    <Button outline className="btn btn-soft-danger" onClick={get}>
                      <i className="mdi mdi-close-circle-multiple-outline" /> Cancel</Button>

                    <Button outline className="btn btn-soft-warning" onClick={get}>
                      <i className="mdi mdi-recycle" /> Reset</Button>

                    <Button outline className="btn btn-soft-success" onClick={handleSubmit}>
                      <i className="mdi mdi-content-save-all-outline" /> Save Changes</Button>
                  </ButtonGroup>
                  <CardTitle className="mt-2">Form Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label>Form Name</Label>
                    <Input placeholder="Questionnaire Title " value={form_name}
                           onChange={e => set_form_name(e.target.value)} />
                  </FormGroup>
                  <div className="">
                    <Label className="m-3">Form Type </Label>
                    {form.enums.type.map((row, index) => <div key={`form-type-${index}`}
                                                              className="form-check me-1 form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id={`form-type-${index}`}
                          onClick={e => set_type(row)}
                          value={row}
                          checked={row === type}
                          defaultChecked={row === type}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`form-type-${index}`}
                        >
                          {row}
                        </label>
                      </div>
                    )}
                  </div>


                </CardBody>
              </Card>

              <FeedbackFormQuestions
                updated={form.updated}
                questions={form.questions}
                feedback_form_questions={feedback_form_questions}
                filters={
                  { companies: form.companies, branches: form.branches, departments: form.departments }
                }
                onChange={set_feedback_form_questions}
              />


              {type === "Single" && <Card>
                <CardBody><CardTitle> Preview Single Form : <small>{form_name}</small> </CardTitle>
                  <PreviewAttributesComponent />

                </CardBody>
                <PreviewQuestionsComponent />


              </Card>}
              {type === "Wizard" && <Card>
                <CardBody>
                  <CardTitle> Preview Wizard Form : <small>{form_name}</small> </CardTitle>
                  <PreviewAttributesComponent />


                </CardBody>
                <PreviewQuestionsComponent />
              </Card>}

            </Col>
          </Row>

        </>}
      </Container>
    </div>
  </React.Fragment>
}
