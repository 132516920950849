import React, { useEffect, useState } from "react"
import { Button, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { Pre } from "../pre"


export const DataTableList = (props) => {

  const   {
    setShowing,
    hideHeader,
    loading,
    pagination,
    hidePagination,
    setCurrentPage,
    get,
    data,
    onRowClicked,
    columns,
    className,
    current_page = 0,
    from = 0,
    last_page = 0,
    per_page = 0,
    to = 0,
    total = 0
  } = props;

  const [blocks, setBlocks] = useState(pagination ? pagination : 20)
  const [max, setMax] = useState(pagination)


  const showMore = () => {
    setMax(max + blocks)
  }

  // useEffect(() => {setMax(pagination)}, [data])

  useEffect(() => setShowing && setShowing(`Showing ${max < data.length ? max : data.length} of ${data.length}`), [max, data])

  const handleNext = () => setCurrentPage ? setCurrentPage(current_page + 1) : get(current_page + 1)
  const handlePrev = () => setCurrentPage ? setCurrentPage(current_page - 1) : get(current_page - 1)
  const handleLast = () => setCurrentPage ? setCurrentPage(last_page) : get(last_page)
  const handleFirst = () => setCurrentPage ? setCurrentPage(1) : get(1)

  const renderPages = () => {
    let pages = []
    const start = current_page - 5 > 0 ? current_page - 5 : 1
    const last = current_page + 5 < last_page ? current_page + 5 : last_page

    for (let index = start; index <= last; index++) {
      pages.push(<PaginationItem active={index === current_page} key={`page-index-${index}`}>
        <PaginationLink onClick={() => setCurrentPage ? setCurrentPage(index) : get(index)}>{index}</PaginationLink>
      </PaginationItem>)
    }
    return pages
  }

  return <div className="">

    <Table className={className}>
      {!hideHeader && <thead>
      <tr>
        {columns.map((col, index) => <th hidden={col.omit} style={{ textAlign:col.center && "center", maxWidth: col.width, minWidth: col.width, ...col.style }}
                                         key={`table-heads-${index}`}>
          {<span>{col.name}</span>}

        </th>)}
      </tr>
      </thead>}
      <tbody>

      {data.map((record, index) => {
        if (hidePagination && index >= max) {
          return null
        }

        if (!record || record === null) return false
        return <tr key={`table-rows-${index}`} onClick={() => onRowClicked(record)} >
          {columns.map((col, i) => <td key={`table-columns-${index}-${i}`}  style={{ textAlign:col.center && "center", maxWidth: col.width, minWidth: col.width, ...col.style }} hidden={col.omit}>
            {typeof col.cell === "function" && <div>{col.cell(record)}</div>}
            {typeof col.cell === "undefined" && <>{record[col.selector]}</>}
          </td>)}
        </tr>
      })}
      </tbody>
    </Table>
    {!hidePagination && <div className="justify-content-center align-self-center text-center">
      <Row>
        <Col sm={10} className="justify-content-center align-self-center text-center">

          {data.length > 0 && <Pagination
            aria-label="Page navigation example"
          >
            <PaginationItem disabled={current_page === 1}>
              <PaginationLink onClick={handleFirst}>
                <i className="mdi mdi-chevron-double-left"></i>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={current_page === 1}>
              <PaginationLink onClick={handlePrev}>
                <i className="mdi mdi-chevron-left"></i>
              </PaginationLink>
            </PaginationItem>
            {renderPages()}
            <PaginationItem disabled={current_page >= last_page}>
              <PaginationLink onClick={handleNext}><i className="mdi mdi-chevron-right"></i></PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={current_page >= last_page}>
              <PaginationLink onClick={handleLast}><i className="mdi mdi-chevron-double-right"></i></PaginationLink>
            </PaginationItem>
          </Pagination>}
        </Col>
        <Col sm={2}>
          {true && <div className=" align-content-end justify-content-end pt-2 right">
            {`Page : ${current_page} / ${last_page}`}
          </div>}
        </Col>
      </Row>
    </div>}

    {hidePagination && <div className="text-center m-1 mb-5">
      {!loading && data.length > max && <>
        {!setShowing && <div><small className="text-muted"> Showing {max} of {data.length} </small></div>} <Link
        onClick={showMore} className="btn btn-link "> - Show More - </Link>
      </>}
      {!loading && data.length < max && <h4 className="text-light">Nothing More</h4>}
    </div>}

  </div>
}
