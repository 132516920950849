import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { DataTableList } from "../../components/tables/dataTable"
import React from "react"
import { feedbackDelete } from "../../store/feedback/feedbackActions"


export const FeedbackList = props => {

  // return <Pre data={props.data}/>
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onDelete = item => dispatch(feedbackDelete(item.id))

  const columns = [
    {
      name: "#",
      selector: "id"
    },
    {
      name: "Name",
      selector: "form_name",
      cell: (row) => <>
        <div>
          <Link  to={`/feedback/${row.id}`}><strong>{row.form_name}</strong></Link></div>

      </>
    },

    {
      name: "Company",
      selector: "company_name",
      cell: (row) => row.company_name
    },
    {
      name: "Branch",
      selector: "branch_name",
      cell: (row) => row.branch_name
    },
    {
      name: "Department",
      selector: "department",
      cell: (row) => row.department_name
    },

    {
      name: "Form Link",
      selector: "department",
      cell: (row) => <Link className="btn btn-success btn-sm" target="_blank" color="default" to={`/form/${row.id}`}>Form Link</Link>

    },

    {
      name: "",
      selector: "id",
      className: "flex-end",
      cell: (row) => <UncontrolledDropdown>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
        >
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            onClick={() => navigate(`/feedback/form/${row.id}`)}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => onDelete(row)}
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ]
  return <>
    {props.data && props.data.length > 0 && <>
      <DataTableList
        columns={columns}
        get={props.get}
        className={`project-list-table table-nowrap align-middle table-borderless`}
        current_page={1}
        last_page={2}
        data={props.data}
        callBack={props.get}
        loading={props.loading}
        setCurrentPage={() => {
        }}
      />
    </>

    }</>

}


