export const AUTHENTICATION_LOGIN_REQUEST  = 'AUTHENTICATION_LOGIN_REQUEST';
export const AUTHENTICATION_LOGIN_SUCCESS  = 'AUTHENTICATION_LOGIN_SUCCESS';
export const AUTHENTICATION_LOGIN_FAILURE  = 'AUTHENTICATION_LOGIN_FAILURE';


export const AUTHENTICATION_LOGOUT_REQUEST  = 'AUTHENTICATION_LOGOUT_REQUEST';
export const AUTHENTICATION_LOGOUT_SUCCESS  = 'AUTHENTICATION_LOGOUT_SUCCESS';
export const AUTHENTICATION_LOGOUT_FAILURE  = 'AUTHENTICATION_LOGOUT_FAILURE';



