export const ESCALATION_LEVEL_GET_REQUEST = "ESCALATION_LEVEL_GET_REQUEST";
export const ESCALATION_LEVEL_GET_SUCCESS = "ESCALATION_LEVEL_GET_SUCCESS";
export const ESCALATION_LEVEL_GET_FAILURE = "ESCALATION_LEVEL_GET_FAILURE";

export const ESCALATION_LEVEL_FIND_REQUEST = "ESCALATION_LEVEL_FIND_REQUEST";
export const ESCALATION_LEVEL_FIND_SUCCESS = "ESCALATION_LEVEL_FIND_SUCCESS";
export const ESCALATION_LEVEL_FIND_FAILURE = "ESCALATION_LEVEL_FIND_FAILURE";

export const ESCALATION_LEVEL_ACTION_REQUEST = "ESCALATION_LEVEL_ACTION_REQUEST";
export const ESCALATION_LEVEL_ACTION_SUCCESS = "ESCALATION_LEVEL_ACTION_SUCCESS";
export const ESCALATION_LEVEL_ACTION_FAILURE = "ESCALATION_LEVEL_ACTION_FAILURE";


