import axios from "axios"


export const CUSTOMER_FEEDBACK_GET_REQUEST = "CUSTOMER_FEEDBACK_GET_REQUEST"
export const CUSTOMER_FEEDBACK_GET_SUCCESS = "CUSTOMER_FEEDBACK_GET_SUCCESS"
export const CUSTOMER_FEEDBACK_GET_FAILURE = "CUSTOMER_FEEDBACK_GET_FAILURE"


export const CUSTOMER_FEEDBACK_FIND_REQUEST = "CUSTOMER_FEEDBACK_FIND_REQUEST"
export const CUSTOMER_FEEDBACK_FIND_SUCCESS = "CUSTOMER_FEEDBACK_FIND_SUCCESS"
export const CUSTOMER_FEEDBACK_FIND_FAILURE = "CUSTOMER_FEEDBACK_FIND_FAILURE"

const init = {
  customer_feedbacks: [],
  customer_feedback: false,
  loading : false,
  errors: false
}

const CustomerFeedback = (state = init, action) => {
  switch (action.type){

    case CUSTOMER_FEEDBACK_GET_REQUEST : {return {...state, loading: true, errors: false}}
    case CUSTOMER_FEEDBACK_GET_SUCCESS : {return {...state, loading: false, customer_feedbacks: action.payload}}
    case CUSTOMER_FEEDBACK_GET_FAILURE : {return {...state, loading: false, errors: action.payload}}

    case CUSTOMER_FEEDBACK_FIND_REQUEST : {return {...state, loading: true, errors: false}}
    case CUSTOMER_FEEDBACK_FIND_SUCCESS : {return {...state, loading: false, customer_feedback: action.payload}}
    case CUSTOMER_FEEDBACK_FIND_FAILURE : {return {...state, loading: false, errors: action.payload}}

    default : {
      return {...state}
    }
  }
}

export default CustomerFeedback;


export const customerFeedbackGet = () => dispatch => {
  dispatch({ type: CUSTOMER_FEEDBACK_GET_REQUEST })
  axios.get("/app/customer/feedback")
    .then(({ data }) => dispatch({ type: CUSTOMER_FEEDBACK_GET_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: CUSTOMER_FEEDBACK_GET_FAILURE, payload: message }))
}

export const customerFeedbackFind = (id) => dispatch => {
  dispatch({ type: CUSTOMER_FEEDBACK_FIND_REQUEST })
  axios.get(`/app/customer/feedback/${id}`)
    .then(({ data }) => dispatch({ type: CUSTOMER_FEEDBACK_FIND_SUCCESS, payload: data }))
    .catch(({ message }) => dispatch({ type: CUSTOMER_FEEDBACK_FIND_FAILURE, payload: message }))
}

