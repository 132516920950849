export const FILE_GET_REQUEST = 'FILE_GET_REQUEST';
export const FILE_GET_SUCCESS = 'FILE_GET_SUCCESS';
export const FILE_GET_FAILURE = 'FILE_GET_FAILURE';

export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';

export const FILE_DOWNLOAD_REQUEST = 'FILE_DOWNLOAD_REQUEST';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_FAILURE = 'FILE_DOWNLOAD_FAILURE';


