import React from "react"
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import Select from "react-select"
import { Pre } from "../../helpers/pre"
import Datatable from "react-data-table-component"
import { indexOf } from "leaflet/src/core/Util"

export default class FeedbackFormQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      questions: this.props.questions,
      filters: this.props.filters,
      feedback_form_questions: this.props.feedback_form_questions
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.updated !== this.props.updated){

      this.setState({feedback_form_questions: this.props.feedback_form_questions})
    }

  }


  render() {
    const { questions, filters, feedback_form_questions } = this.state

    const handleQuestionChange = () => {

    }



    const handleQuestionAction = item => {
      let {feedback_form_questions} = this.state;
      if(indexOf(feedback_form_questions, item.id) === -1){
        feedback_form_questions.push(item.id)
      }else{
        feedback_form_questions.splice(indexOf(feedback_form_questions, item.id), 1)
      }
      this.props.onChange(feedback_form_questions)

    }

    const QuestionButton = ({ item }) => {
      // console.log(item)
      return <Button outline size={"sm"} onClick={() => handleQuestionAction(item)}>
        {!feedback_form_questions.includes(item.id) ? "Add" : "Remove"}
      </Button>
    }

    const columns = [
      { name: "Question", wrap: true, minWidth: "30%", selector: row => row.question },
      { name: "Aspect", selector: row => row.aspect },
      { name: "Type", selector: row => row.question_type },
      { name: "Response", selector: row => row.response_type },
      { name: "", right: true, selector: row => <QuestionButton item={row} /> }
    ]


    return <>
      <Card>
        <CardBody>
          <CardTitle>Questions </CardTitle>
        </CardBody>

        <CardBody>
          <Datatable columns={columns} data={questions} />
        </CardBody>



      </Card>
    </>
  }


}
