
import axios from "axios"
import {
  FILE_DELETE_FAILURE,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_GET_FAILURE,
  FILE_GET_REQUEST,
  FILE_GET_SUCCESS
} from "./fileTypes"


export const fileGet = () => dispatch => {
  dispatch({type: FILE_GET_REQUEST});
  axios.get('app/files/all')
    .then(({data}) => dispatch({type: FILE_GET_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: FILE_GET_FAILURE, payload: message}))
}

export const fileDelete = (id) => dispatch => {
  dispatch({type: FILE_DELETE_REQUEST});
  axios.post('app/files/delete', {id})
    .then(({data}) => dispatch({type: FILE_DELETE_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: FILE_DELETE_FAILURE, payload: message}))
}

export const fileDownload = () => dispatch => {

}
