import {ESCALATION_LEVEL_GET_REQUEST,
  ESCALATION_LEVEL_GET_SUCCESS,
  ESCALATION_LEVEL_GET_FAILURE,
  ESCALATION_LEVEL_FIND_REQUEST,
  ESCALATION_LEVEL_FIND_SUCCESS,
  ESCALATION_LEVEL_FIND_FAILURE,
  ESCALATION_LEVEL_ACTION_REQUEST,
  ESCALATION_LEVEL_ACTION_SUCCESS,
  ESCALATION_LEVEL_ACTION_FAILURE,} from './escalationLevelTypes'



const init = {
  loading: false,
  escalation_levels : [],
  escalation_level: false,
  error : ""
}

const EscalationLevel = (state = init, action) => {
  switch(action.type) {
    case ESCALATION_LEVEL_GET_REQUEST : {return {...state, loading: true, error: ""}}
    case ESCALATION_LEVEL_GET_SUCCESS : {return {...state, loading: false, escalation_levels: action.payload, error: ""}}
    case ESCALATION_LEVEL_GET_FAILURE : {return {...state, loading: false, error: action.payload}}

    case ESCALATION_LEVEL_FIND_REQUEST : {return {...state, loading: true, error: "", escalation_level: false}}
    case ESCALATION_LEVEL_FIND_SUCCESS : {return {...state, loading: false, escalation_level: action.payload, error: ""}}
    case ESCALATION_LEVEL_FIND_FAILURE : {return {...state, loading: false, error: action.payload}}

    case ESCALATION_LEVEL_ACTION_REQUEST : {return {...state, loading: true, error: ""}}
    case ESCALATION_LEVEL_ACTION_SUCCESS : {return {...state, loading: false, error: ""}}
    case ESCALATION_LEVEL_ACTION_FAILURE : {return {...state, loading: false, error: action.payload}}



    default : {
      return { ...state }
    }
  }

}

export default EscalationLevel;
