import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Breadcrumb,
  Card,
  CardBody,
  CardTitle, Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, FormGroup, Input, InputGroup, Label, Row,
  UncontrolledDropdown
} from "reactstrap"

import moment from "moment"
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import { companyGet } from "../../store/companies/companyActions"
import { branchGet } from "../../store/branches/branchActions"
import { departmentGet } from "../../store/departments/depatmentActions"
import { complaintComment, complaintResolve, complaintsGet } from "../../store/complaints/complaintsReducer"
import { ComplainFormModal } from "./complainModal"
import { DataTableList } from "../../components/tables/dataTable"
import { StatusBadge } from "../../components/general"
import { ReviewFilters } from "../reports/filter"

export const ComplaintsManagement = props => {

  document.title = "Question Complaints | QSYS Feedback"


  const dispatch = useDispatch()

  const { Company, Branch, Department, CustomerRatings, Complaints } = useSelector(state => state)


  const [form, setForm] = useState(false)
  const [complaint, setComplaint] = useState(false)
  const [filter, setFilter]= useState({});
  const [tickets, setTickets] = useState("Open")
  const [page, setPage] = useState(1);

  const get = (page) => dispatch(complaintsGet({ page :page ? page : 1 , ...filter}))


  useEffect(() => {
    get()
  }, [filter, page])


  const { complaints, loading } = Complaints

  const columns = [
    { name: "ticket", cell: ({ ticket }) => ticket.toUpperCase() },
    { name: "Question", cell: ({ question }) => question  },
    {
      name: "Customer", selector: row => row.name, cell: row => <div>
        <div><strong>{row.customer_name}</strong></div>
        <div><small>{row.customer_email}</small></div>
        {/*<div><strong>{row.customer_phone}</strong></div>*/}
      </div>
    },
    {
      name: "Rating", selector: ({ rating, question_type }) => rating, cell: ({ rating,question_type }) => <span>
        Poor : {rating} / {question_type == 'CSAT' ? 5 : 10} <i className="mdi mdi-star text-danger" />
      </span>
    },
    { name: "Date", cell: ({ created_at }) => created_at && moment(created_at).format("Do MMM YYYY HH:mm") },
    { name: "Comments", cell: ({ comments }) => comments.length },
    {
      name: "Status", cell: ({ status }) =>
        <><StatusBadge status={status} {...props} /></>
    }


  ]

  return <React.Fragment>

    <div className={!props.hideFilter && "page-content"}>
      <Container fluid={!props.hideFilter}>
        {!props.hideFilter && <Breadcrumb
          title={false ? "Loading" : "Home"}
          breadcrumbItem="Question Complaints"
        />
        }
        {!props.hideFilter && <Card>
          <CardBody>

            <CardTitle>Questions Complaints</CardTitle>
            <hr className="m-3" />
            <ReviewFilters size={3} onChange={setFilter} complaint_status branch department date search  />

          </CardBody>
        </Card>}
      <Card className="bg-transparent">
        {props.hideFilter && <CardBody><CardTitle>Latest Reviews</CardTitle></CardBody>}
        {complaints.data && complaints.data.length > 0 && <DataTableList
          {...props}
          columns={columns}
          onRowClicked={(row) => {
            setComplaint(row.id)
            setForm(true)
          }}
          get={get}
          className={`project-list-table table-nowrap align-middle table-borderless table-hover `}
          {...complaints}
          data={complaints.data}
          callBack={get}
          loading={loading}
          setCurrentPage={(page) => setPage(page)}
        />}
      </Card>



      </Container>
    </div>

    <ComplainFormModal
      update={get}
      onComment={(id, comment, tagged) => {
        setForm(false)
        dispatch(complaintComment({ complaint: id, comment, users: tagged }))
      }}
      onSubmit={(id, comment, tagged) => {
        setForm(false)
        dispatch(complaintResolve({ complaint: id, comment, users: tagged }))
      }}
      isOpen={form} toggle={() => setForm(!form)} complaintID={complaint} />


  </React.Fragment>
}
