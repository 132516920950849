import React from "react"
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"


export default class RoleForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      fields: false
    }
  }

  componentDidMount = () => this.init()

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.isOpen !== this.props.isOpen) this.init()
  }

  init = () => {
    const { branch } = this.props
    const fields = { id: 0, name: "" }
    this.setState({ form: fields, fields })
  }

  handleChange = (label, value) => {
    let { fields } = this.state
    fields[label] = value
    this.setState({ fields })
  }

  render() {
    const { isOpen, toggle, saveChanges } = this.props
    const { loading, fields, form } = this.state
    return <Modal isOpen={isOpen} toggle={toggle}>
      {!loading && form && <Form method="post" onSubmit={e => {
        e.preventDefault()
        saveChanges(fields)
      }
      }>
        <ModalHeader>
          CREATE ROLE
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Label htmlFor="form-role-name"> Name</Label>
            <Input
              autoFocus={true}
              autoComplete
              value={fields.name}
              onChange={e => this.handleChange("name", e.target.value)}
              type="text"
              className="form-control"
              id="form-role-name"
              placeholder="Role Name"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline color="dark" onClick={toggle}>Close</Button>
          <Button outline color="success" onClick={() => saveChanges(fields)}>
            Save Changes</Button>
        </ModalFooter>
      </Form>}
    </Modal>
  }


}
