import {
  MESSAGES_ACTION_FAILURE, MESSAGES_ACTION_REQUEST, MESSAGES_ACTION_SUCCESS,
  MESSAGES_FIND_FAILURE, MESSAGES_FIND_REQUEST, MESSAGES_FIND_SUCCESS,
  MESSAGES_GET_FAILURE,  MESSAGES_GET_REQUEST,  MESSAGES_GET_SUCCESS
} from "./messagesTypes"

const init = {
  messages: false,
  message: false,
  loading: false,
  error: false,
  selectedmails: [],
}

const Messages = (state = init, action) => {
  switch (action.type){
    case  MESSAGES_GET_REQUEST : {return {...state, loading : true, error: false}}
    case  MESSAGES_GET_SUCCESS : {return {...state, loading : false, messages:  action.payload, error: false}}
    case  MESSAGES_GET_FAILURE : {return {...state, loading : false, error:  action.payload}}

    case  MESSAGES_FIND_REQUEST : {return {...state, loading : true, message: action.payload && action.payload, error: false}}
    case  MESSAGES_FIND_SUCCESS : {return {...state, loading : false, message:  action.payload, error: false}}
    case  MESSAGES_FIND_FAILURE : {return {...state, loading : false, error:  action.payload}}

    case  MESSAGES_ACTION_REQUEST : {return {...state, loading : true, message: action.payload && action.payload, error: false}}
    case  MESSAGES_ACTION_SUCCESS : {return {...state, loading : false, message:  action.payload, error: false}}
    case  MESSAGES_ACTION_FAILURE : {return {...state, loading : false, error:  action.payload}}

    default : {return {...state}}
  }
}

export default Messages;
